import { Injectable } from "@angular/core";
import { RecipeService } from "../../../recipe/services/recipe.service";
import { TemplateType } from "../../../api/models";
import { Table, Activity, Form, Module } from "../../../model/experiment.interface";
import { AugmentedForm, AugmentedModule, AugmentedTable } from "../../../recipe/model/recipe";
import { RecipeDeleteTemplateCommand } from "../../../api/cookbook/models";

@Injectable({
  providedIn: 'root'
})

export class TemplateDeleteService {

  confirmationMessage: { [key: string]: string } = {
    recipeItemDeleteConfirmation: $localize`:@@recipeItemDeleteConfirmation:Are you sure you want to delete the selected template?`,
    recipeParentItemDeleteConfirmation: $localize`:@@recipeParentItemDeleteConfirmation:Removing this template will remove all contexts associated with its source and any content added or modified under this template, are you sure want to continue?`,
    recipeItemDeleteConfirmationAsChild: $localize`:@@recipeItemDeleteConfirmationAsChild:Selected template/recipe is part of <<actual recipe number>>, deleting the selected template/recipe will delete <<actual recipe number>> and contexts associated with it from the current recipe`
  }
  deleteCommand: RecipeDeleteTemplateCommand | undefined;
  constructor(private readonly recipeService: RecipeService) {
  }

  private getModuleId(itemId: string): { itemId: string | undefined, type: TemplateType , module: Module|undefined} {
    if(this.recipeService.currentRecipe.activities.length > 0){
      const activity =  this.recipeService.currentRecipe.activities.find(x => x.dataModules.find(x => x.items.find(x => (x as Form).formId === itemId))) 
       || this.recipeService.currentRecipe.activities.find(x => x.dataModules.find(x => x.items.find(x => (x as Table).tableId === itemId)));
       const module = activity?.dataModules.find(x => x.items.some(x => (x as Form).formId === itemId)) || activity?.dataModules.find(x => x.items.some(x => (x as Table).tableId === itemId));
       return module ? { itemId: module.moduleId, type: TemplateType.Module , module: module } : { itemId: "", type: TemplateType.Invalid, module: undefined };
    }
    else{
    const module = this.recipeService.currentRecipe.modules.find(x => x.items.some(x => (x as Form).formId === itemId)) || this.recipeService.currentRecipe.modules.find(x => x.items.some(x => (x as Table).tableId === itemId));
    return module ? { itemId: module.moduleId, type: TemplateType.Module, module: module } : { itemId: "", type: TemplateType.Invalid, module: undefined };
    }
  }

  private getActivityId(moduleId: string): { itemId: string | undefined, type: TemplateType } {
    const activity = this.recipeService.currentRecipe.activities.find(x => x.dataModules.some(x => x.moduleId === moduleId));
    return activity ? { itemId: activity.activityId, type: TemplateType.Activity } : { itemId: "", type: TemplateType.Invalid };
  }

  private readonly deleteFormCallback = () => {
    if (this.deleteCommand)
      this.recipeService.deleteTemplate(this.deleteCommand);
  };

  deleteRecipeItem(recipeItem: Form | Table | Module | Activity, type: TemplateType) {
    //determine template to be deleted
    const itemToBeDeleted = this.getItemIdToBeDeleted(recipeItem, type);
    switch (itemToBeDeleted.type) {
      case TemplateType.Form:
        const form = (recipeItem as AugmentedForm);
        this.deleteOperation(form, TemplateType.Form, itemToBeDeleted);
        break;
      case TemplateType.Table:
        const table = (recipeItem as AugmentedTable);
        this.deleteOperation(table, TemplateType.Table, itemToBeDeleted);
        break;
      case TemplateType.Module:
        const module = (recipeItem as AugmentedModule);
        this.deleteOperation(module, TemplateType.Module, itemToBeDeleted);
        break;
      case TemplateType.Activity:
        const activity = (recipeItem as AugmentedModule);
        this.deleteOperation(activity, TemplateType.Activity, itemToBeDeleted);
        break;
    }
  }

  deleteOperation(recipeItem: AugmentedForm | AugmentedTable | AugmentedModule, type: TemplateType, itemToBeDeleted: any) {
    const confirmationMessage = 
      type === TemplateType.Module && (itemToBeDeleted.type === type) ? this.confirmationMessage['recipeParentItemDeleteConfirmation'] :
        (itemToBeDeleted.type === type) ? this.confirmationMessage['recipeItemDeleteConfirmation']
            : this.confirmationMessage['recipeItemDeleteConfirmationAsChild'];
    this.deleteCommand = {
      isRecipe: recipeItem.isRecipeNode,
      itemId: itemToBeDeleted.itemId,
      recipeId: this.recipeService.currentRecipe.recipeId,
      recipeNumber: this.recipeService.currentRecipe.number,
      sourceTemplateType: type,
      recipeType: this.recipeService.assessRecipeType(undefined, itemToBeDeleted.type),
      templateReferenceId: recipeItem.sourceTemplateId
    } as RecipeDeleteTemplateCommand
    this.recipeService.confirmationDialog(confirmationMessage, this.deleteFormCallback);
  }

  private getItemIdToBeDeleted(recipeItem: Form | Table | Activity | Module, type: TemplateType): { itemId: string | undefined, type: TemplateType } {
    switch (type) {
      case TemplateType.Activity:
        return { itemId: (recipeItem as Activity).activityId, type: TemplateType.Activity };
      case TemplateType.Module:
        return this.getModuleItem(recipeItem, type);
      case TemplateType.Form:
        return this.getFormItem(recipeItem, type);
      case TemplateType.Table:
        return this.getTableItem(recipeItem, type);
      default:
        return { itemId: undefined, type: TemplateType.Invalid };
    }
  }

  getModuleItem(recipeItem: Form | Table | Activity | Module, type: TemplateType): { itemId: string | undefined, type: TemplateType } {
    return this.recipeService.currentRecipe.childOrder.some(x => x === (recipeItem as Module).moduleId) ? {
      itemId:
        (recipeItem as Module).moduleId, type: TemplateType.Module
    } :
      this.getActivityId((recipeItem as Module).moduleId);
  }

  getFormItem(recipeItem: Form | Table | Activity | Module, type: TemplateType): { itemId: string | undefined, type: TemplateType } {
    const formId = (recipeItem as Form).formId;
    return this.recipeService.currentRecipe.childOrder.some(x => x === formId) ? { itemId: formId, type: TemplateType.Form } :
     this.checkSources(recipeItem) ? { itemId: formId, type: TemplateType.Form }:
      this.getModuleId(formId) ? this.getModuleId(formId) : this.getActivityId(this.getModuleId(formId).itemId!);
  }

  getTableItem(recipeItem: Form | Table | Activity | Module, type: TemplateType): { itemId: string | undefined, type: TemplateType } {
    const tableId = (recipeItem as Table).tableId;
    return this.recipeService.currentRecipe.childOrder.some(x => x === tableId) ? { itemId: tableId, type: TemplateType.Table } :
    this.checkSources(recipeItem) ? { itemId: tableId, type: TemplateType.Table } :
      this.getModuleId(tableId) && this.checkSources(this.getModuleId(tableId).module) ? this.getModuleId(tableId) : this.getActivityId(this.getModuleId(tableId).itemId!);
  }


  checkSources(recipeItem: Form | Table | Module | Activity|undefined): boolean {
    if(recipeItem)
    return this.recipeService.RecipeSources.some(x =>x.referenceId === recipeItem.sourceTemplateId);
  else
  return false;
  }
}
