import { Injectable } from "@angular/core";
import { ConditionType, DataType, RecipeRecord, SearchCriteria, StringMatchType, TemplateSummary } from "../../api/search/models";
import { RecipeService as RecipeSearchService} from "../../api/search/services";
import { RecipeService } from "./recipe.service";
import { RecipeSourcesModel } from "../recipe-about/recipe-sources-slider/recipe-sources-slider.component";

@Injectable()
export class RecipeSourcesService {
  data: RecipeSourcesModel[] = [];
  recipeRecords: RecipeSourcesModel[] = [];
  
  constructor(
    private readonly recipeService: RecipeService,
    private readonly recipeSearchService: RecipeSearchService
  ) {}

  getSources(callback : (data:RecipeSourcesModel[], recipes: RecipeSourcesModel[]) => void) {
    this.data = [];
    this.recipeSearchService.recipeRecipeTemplateDetailsPost$Json({
      body: {
        searchDetails: this.recipeService.RecipeSources
      }
    }).subscribe({
      next: (searchResponse) => {
        this.recipeSearchService.recipeSearchRecipeIndexPost$Json({ body: this.getSearchCriteria() })
          .subscribe({
            next: (result) => {
              const sourceTimeMap = new Map<string, Date>();
              if (result.records.length > 0) {
                result.records[0].sources?.forEach(source => {
                  sourceTimeMap.set(source.sourceTitle, new Date(source.addedTime));
                });
              }
              searchResponse.recipes.forEach(recipe => {
                this.addToData(recipe, sourceTimeMap);
              });
              this.recipeRecords = JSON.parse(JSON.stringify(this.data));
              searchResponse.templates.forEach(template => {
                this.addToData(template, sourceTimeMap);
              });
              this.data.sort((a, b) => (b.addedTime?.getTime() || 0) - (a.addedTime?.getTime() || 0));
              callback(this.data, this.recipeRecords);
            }
          }
        );
      }
    });
  }

  private addToData(item: RecipeRecord | TemplateSummary, sourceTimeMap: Map<string, Date>) {
    const sourceTitle = `${item.number} - ${item.name}`;
    const addedTime = sourceTimeMap.get(sourceTitle) || new Date(0);
  
    const type = 'type' in item ? item.type : item.templateType;
    const sourceType = 'recipeId' in item ? 'Recipe' : 'Template';
    const workflowState = 'state' in item 
      ? item.state 
      : (item as TemplateSummary).templateManagement.state;
  
    this.data.push({
      referenceNumber: item.number,
      name: item.name,
      version: 'V' + item.version,
      workflowState: workflowState ?? '',
      id: item.id,
      type: type,
      sourceType: sourceType,
      addedTime: addedTime
    });
  }

  getSearchCriteria(): SearchCriteria {
    return {
      bypassSecurity: false,
      pagination: {
        pageNumber: 1,
        pageSize: 10
      },
      sort: [],
      filterConditions: [
        {
          conditionType: ConditionType.And,
          filters: [
            {
              dataType: DataType.String,
              columnName: 'number',
              matchType: StringMatchType.Word,
              text: this.recipeService.currentRecipe?.number,
              isSecurityFlag: true
            }
          ]
        }
      ]
    };
  }
}
  