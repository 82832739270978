import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { I18nConfigService } from 'services/i18n-config.service';
import '../../i18n-config/locale-imports';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routes';
import { AuthInterceptor } from './interceptors/auth-interceptor/auth-interceptor';
import { ExceptionInterceptor } from './interceptors/exception-interceptor/exception-interceptor';
import { HttpSlowInterceptor } from './interceptors/http-slow-interceptor/http-slow-interceptor';
import { SignalRConnectionInterceptor } from './interceptors/signalr-interceptor/signalr-connection-interceptor';
import { LayoutModule } from './layout/layout.module';
import { TemplateDesignerComponent } from './template-designer/template-designer.component';
import { ElnProgressSpinnerModule } from './eln-progress-spinner-module/eln-progress-spinner-module.module';
import { ExperimentStatusInterceptor } from './interceptors/experiment-status-interceptor/experiment-status-interceptor';
import { ToastModule } from 'primeng/toast';
import { BptProgressSpinnerModule } from 'bpt-ui-library/bpt-progress-spinner';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ExperimentPreviewHeaderInterceptor } from './interceptors/experiment-preview-header-interceptor/experiment-preview-header-interceptor';
import { TitleCasePipe } from '@angular/common';
import { CollaboratorWarningInterceptor } from './interceptors/collaborator-warning/collaborator-warning.interceptor';
import { applicationInitializerDefinition } from './app-initializer';
import { ExperimentService } from './experiment/services/experiment.service';
import { experimentTableDataServiceProvider } from './experiment/experiment.module';
import { UserRoleInterceptor } from './interceptors/user-role-interceptor/user-role-interceptor';

@NgModule({
  declarations: [AppComponent, TemplateDesignerComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    LayoutModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: false }),
    HttpClientModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    BptProgressSpinnerModule,
    ElnProgressSpinnerModule,
    ConfirmDialogModule
  ],
  providers: [
    applicationInitializerDefinition(),
    ConfirmationService,
    experimentTableDataServiceProvider,
    ExperimentService,
    MessageService,
    Title,
    TitleCasePipe,
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [I18nConfigService],
      useFactory: (i18nConfigService: I18nConfigService) => () => i18nConfigService.initialize()
    },
    {
      provide: LOCALE_ID,
      useFactory: () => I18nConfigService.userLocale
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CollaboratorWarningInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExceptionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExperimentPreviewHeaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExperimentStatusInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpSlowInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SignalRConnectionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserRoleInterceptor, multi: true },
    { provide: 'Window', useValue: window }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
