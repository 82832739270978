<bpt-progress-spinner
  i18n-message="@@loadingHistory"
  message="History Loading..."
  [useOverlay]="true"
  *ngIf="auditLoading">
</bpt-progress-spinner>

<p-tabView
  #tabview
  *ngIf="canShowTabView"
  class="experimentPage"
  orientation="left"
  [scrollable]="false"
  (onChange)="tabChange($event)">
  <p-tabPanel>
    <ng-template pTemplate="header">
      <span
      pTooltip="{{ this.attachedFiles }}"
      i18n-pTooltip="@@attachedFiles"
      tooltipPosition="top"
      class="eln-module-tab-header"
      (contextmenu)="$event.stopPropagation()"
      [innerText]="this.attachedFiles">
      </span>
      <span class="eln-module-tab-header-separator"></span>
    </ng-template>
    <div class="eln-file-upload" *ngIf="canUploadFiles">
      <div class="file-upload">
        <p-button
          i18n-label="@@uploadFiles"
          id="btn-UploadFiles"
          icon="icon-s icon-upload icon-file"
          pTooltip="{{this.uploadTooltip}}"
          tooltipPosition="bottom"
          label="Upload files"
          (onClick)="uploadFiles()"></p-button>
      </div>
    </div>
    <app-files-tab></app-files-tab>
  </p-tabPanel>
  <p-tabPanel *ngIf="isSampleAliquotAdded && impactAssessmentEnabled && !isResultMappingHidden">
    <ng-template pTemplate="header">
      <span
        class="eln-module-tab-header"
        (contextmenu)="$event.stopPropagation()"
        [innerText]="resultMapping">
      </span>
      <span *ngIf="(fixedTabs.length > 0) || isInstrumentAdded" class="eln-module-tab-header-separator"></span>
    </ng-template>
  </p-tabPanel>

  <ng-container *ngFor="let resultSet of fixedTabs; let i = index">
    <p-tabPanel [attr.resultset-id]="resultSet.resultSetId">
      <ng-template pTemplate="header">
        <span
          class="eln-module-tab-header"
          (contextmenu)="$event.stopPropagation()"
          [innerText]="empowerResultSet + resultSet.resultSetId">
        </span>
        <span *ngIf="i !== fixedTabs.length -1 || (isInstrumentAdded && impactAssessmentEnabled)"
          class="eln-module-tab-header-separator"></span>
      </ng-template>
      <app-outputs-imported-resultset
        *ngIf="tabview && fixedTabs[activeIndex - getLeadingTabsCount()] && fixedTabs[activeIndex - getLeadingTabsCount()].chromatographyDataId === resultSet.chromatographyDataId"
        [resultSetSummary]="resultSet"
        (SendResultSetEvent)="SendResultSetEvent.emit($event)"
        (RefreshResultSetButtonId)="RefreshResultSetButtonId.emit($event)">
      </app-outputs-imported-resultset>
    </p-tabPanel>
  </ng-container>

  <p-tabPanel *ngIf="isInstrumentAdded && impactAssessmentEnabled" data-testId="instrument-tab-panel">
    <ng-template pTemplate="header">
      <span
        class="eln-module-tab-header"
        (contextmenu)="$event.stopPropagation()"
        [innerText]="instrumentEvent">
      </span>
    </ng-template>
    <div class="eln-impact-assessment-tab-panel">
      <div class="flex flex-row gap-3 mb-5">
        <div class="font-bold flex flex-row eln-instrument-event-header">
          {{ impactAssessment }}
        </div>
        <div
          class="flex flex-row {{completionPercent === 100 ? 'formFilled' : completionPercent === 0 ? 'zeroFilledForm' : 'unFilledForm'}}">
          <p-progressBar class="eln-FormProgressBar" [showValue]="false" [value]="completionPercent"></p-progressBar>
          <span class="font">{{completionPercent}}%</span>
        </div>
        <span class="ml-auto">
          <a class="audit-Icon" id="history" (click)="loadImpactAssessmentHistoryDialog()">
            <span i18n-pTooltip="@@viewHistory" class="fa fa-history">
              <span class="ml-1 audit" i18n="@@History">History</span>
            </span>
          </a>
        </span>
      </div>
      <div class="mb-4">
        <bpt-text-input
          id="instrument-id"
          label="Instrument ID"
          i18n-label="@@instrumentID"
          [readOnly]="true"
          [(ngModel)]="instrumentId">
        </bpt-text-input>
      </div>
      <div class="mb-4">
        <bpt-text-input
          id="instrument-name"
          label="Name"
          i18n-label="@@name"
          [readOnly]="true"
          [(ngModel)]="instrumentName">
        </bpt-text-input>
      </div>
      <div class="mb-4">
        <bpt-text-input
          id="instrument-event"
          label="Instrument Event"
          i18n-label="@@instrumentEvent"
          [readOnly]="true"
          [(ngModel)]="instrumentEventValue">
        </bpt-text-input>
      </div>
      <div class="mb-4" #container>
        <bpt-dropdown
          #nonRoutineDropdown
          id="non-routine{{ instrumentId }}"
          label="Non-Routine Issue Encountered"
          i18n-label="@@nonRoutineIssueEncountered"
          [options]="nonRoutineOptions"
          [allowNA]="true"
          [editable]="false"
          [(ngModel)]="nonRoutine"
          [disabled]="outputEmpowerService.disableEmpowerOperations"
          (dropdownChange)="nonRoutineEvent($event)"
          [controlCustomDefinitionValidator]="controlCustomDefinitionValidatorForNonRoutine"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [suppressContextMenu]="false"
          [contextMenu]="getContextMenu('Non-Routine Issue Encountered')"
          (dropdownShow)="lockDropdown(activityOutputCollaboratorService.nonRoutineIdentifier)"
          (dropdownHide)="unlockDropdown(activityOutputCollaboratorService.nonRoutineIdentifier)">
        </bpt-dropdown>
      </div>
      <div class="mb-4">
        <bpt-dropdown
          #returnedToServiceDropdown
          id="returned-to-service{{ instrumentId }}"
          label="Returned to Service"
          i18n-label="@@returnedToService"
          [options]="returnedToServiceOptions"
          [allowNA]="true"
          [editable]="false"
          [(ngModel)]="returnToService"
          [disabled]="outputEmpowerService.disableEmpowerOperations"
          (dropdownChange)="returnToServiceEvent($event)"
          [controlCustomDefinitionValidator]="controlCustomDefinitionValidatorForReturnToService"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [suppressContextMenu]="false"
          [contextMenu]="getContextMenu('Returned to Service')"
          (dropdownShow)="lockDropdown(activityOutputCollaboratorService.returnToServiceIdentifier)"
          (dropdownHide)="unlockDropdown(activityOutputCollaboratorService.returnToServiceIdentifier);">
        </bpt-dropdown>
      </div>
    </div>
  </p-tabPanel>


  <ng-container *ngIf="moreTabs.length > 0" (click)="$event.stopPropagation()">
    <p-tabPanel>
      <ng-template pTemplate="header">
        <span id="eln-moreModulesHeader" (click)="$event.stopPropagation()">
          <bpt-menu-button
            class="eln-module-tab-header"
            [model]="moreTabDropdownOptions"
            [label]="moreTabsHeader">
          </bpt-menu-button>
        </span>
      </ng-template>
      <app-outputs-imported-resultset
        *ngIf="selectedTab"
        [resultSetSummary]="selectedTab"
        (SendResultSetEvent)="SendResultSetEvent.emit($event)"
        (RefreshResultSetButtonId)="RefreshResultSetButtonId.emit($event)">
      </app-outputs-imported-resultset>
    </p-tabPanel>
  </ng-container>

</p-tabView>