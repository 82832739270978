<div
  class="panelView bpt-form-layout standard-padding mt-3 pt-6"
  [ngClass]="{ 'lims-hosted': isLimsHosted }"
  *ngIf="recipeLoaded">
  <div class="eln-main-content">
    <div class="fields">
      <div class="flex flex-v-center mb-2">
        <div class="flex-grow-1">
          <h4
            id="eln-generalInfoHeader"
            class="bpt-font-family font-size-13 font-semibold eln-mt-10 eln-mb-10">
            {{ generalInformation | uppercase }}
          </h4>
        </div>
        <div class="justify-content-end sources-button">
          <p-button
            id="eln-recipe-about-sources-icon"
            i18n-label="@@sources"
            label="Source(s)"
            styleClass="p-button-text bpt-button-compact font-semibold"
            i18n-pTooltip="@@viewSources"
            pTooltip="View Sources"
            tooltipPosition="left"
            (onClick)="loadSourcesSlider()"
            [disabled]="disableSources">
            <img src="assets/eln-assets/sources.svg" class="sources-icon" alt="back-icon"
            [ngClass]="{'disable-sources': disableSources}"/>
          </p-button>
        </div>
      </div>
      <div
        class="flex flex-wrap justify-content-start pl-5 pb-5 readonly-fields-container eln-space-evenly mb-5">
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-Reference"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeReference">Reference</label>
            <div class="flex align-items-baseline gap-2">
              <span id="eln-recipeReferenceTitle" [innerText]="recipe.number"></span>
              <em id="{{copyRecipeReferenceId}}" class="bpt-eln-link icon-copy-line width-auto"
                [pTooltip]="copyRecipeCurrentTooltip"
                tooltipPosition="bottom"
                (click)="copyReferenceToClipboard()"
                (mouseout)="copyRecipeCurrentTooltip = copyRecipeTooltip"></em>
            </div>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-recipeVersion-label"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeVersion">Version</label>
            <span id="eln-recipeVersion-info" [innerText]="'V ' + recipe.version"></span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-recipeType-label"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"

              i18n="@@recipeType">Type</label>
            <span id="eln-recipeType-info">{{ recipeType }}</span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-recipeCreatedBy-label"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeCreatedBy">Created by</label>
            <span id="eln-recipeCreatedBy-user" [innerText]="recipe.tracking.createdBy"></span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-createdOn"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeCreatedOn">Created on</label>
            <span id="eln-recipeCreatedOn" [innerText]="createdOnValue"></span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-owningLabsite"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeOwningLabsite">Owning lab site</label>
            <span
              id="eln-recipeOwningLabSite"
              [innerText]="recipe.organization.owningLabsite"></span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-state"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeState">State</label>
            <span id="eln-recipeState">{{ recipeState }}</span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-lastEditedBy"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeLastEditedBy">Last edited by</label>
            <span id="eln-recipeLastEditedBy" [innerText]="recipe.tracking.lastEditedBy"></span>
          </div>
        </div>
        <div class="info-cell">
          <div class="flex flex-column align-items-start justify-content-start eln-readonly-field">
            <label
              id="eln-lastEditedOn"
              class="eln-label text-sm mb-1 font-normal eln-mt-20 sm-no-mt"
              i18n="@@recipeLastEditedOn">Last edited on</label>
            <span id="eln-recipeLastEditedOn" [innerText]="lastEditedOnValue"></span>
          </div>
        </div>
      </div>
      <div class="eln-flex-main-container" *ngIf="recipe">
        <div class="flex eln-flex-container">
          <div class="flex eln-flex flex-two-column-layout">
            <div class="eln-flex-item">
              <bpt-text-input
                inputId="eln-txtRecipeName"
                i18n-label="@@recipeName"
                i18n-placeholder="@@recipeNamePlaceHolder"
                label="Name"
                placeholder="Enter Name"
                [ngModel]="recipe.name"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="initiateTitleEditing($event)"
                [required]="true"
                [errorMessage]="recipeNameRequiredValidationMessage"
                [errorFlag]="recipeTitleErrorFlag"
                [readOnly]="isRecipeAboutPageReadOnly"
                [disabled]="!enableBasicAndAdditionalFields"></bpt-text-input>
            </div>
            <div class="eln-flex-item">
              <app-user-select
                id="eln-drpEditors"
                i18n-label="@@Designers"
                i18n-placeholder="@@SelectDesignersPlaceHolder"
                label="Designer(s)"
                placeholder="Select Designer(s)"
                [value]="recipe.tracking.assignedEditors"
                [readOnly]="isRecipeAboutPageReadOnly"
                [roles]="['ELN Recipe Designer']"
                [ngModelOptions]="{ updateOn: 'blur' }"
                [required]="true"
                [errorMessage]="recipeEditorsRequiredValidationMessage"
                [errorFlag]="recipeEditorErrorFlag"
                [multiSelect]="true"
                (valueChange)="recipeEditorChanged($event)"
                [usersToBeExcludedFromDisplay]="usersExcludedFromEditors"
                [disabled]="!enableBasicAndAdditionalFields"></app-user-select>
            </div>
          </div>
          <div class="flex eln-flex flex-two-column-layout">
            <div class="eln-flex-item">
              <bpt-dropdown
                inputId="drp-about-consumingLabsites"
                [disabled]="!enableBasicAndAdditionalFields"
                [options]="consumingLabsites"
                [showFilter]="true"
                [ngModelOptions]="{ updateOn: 'blur' }"
                [multiSelect]="true"
                [ngModel]="recipe.organization.consumingLabsites"
                i18n-label="@@ConsumingLabSites"
                label="Consuming lab site(s)"
                i18n-placeholder="@@consumingLabsitePlaceHolder"
                [(ngModel)]="consumingLabsiteForSubUnits"
                placeholder="Select Consuming Lab Site(s)"
                [required]="true"
                [errorMessage]="recipeConsumingLabsitesRequiredValidationMessage"
                [errorFlag]="recipeConsumingLabsitesErrorFlag"
                (ngModelChange)="consumingLabsiteChanged($event)">
              </bpt-dropdown>
            </div>
            <div class="eln-flex-item">
              <app-sub-business-unit-select
                id="eln-drpSubBusinessUnits"
                i18n-label="@@subBusinessUnit"
                i18n-placeholder="@@subBusinessUnitPlaceHolder"
                placeholder="Select Sub Business Unit(s)"
                label="Sub Business Unit(s)"
                [value]="recipe.organization.subBusinessUnits"
                [labsiteCodes]="consumingLabsiteForSubUnits"
                [ngModelOptions]="{ updateOn: 'blur' }"
                [required]="true"
                [errorMessage]="recipeEubBusinessUnitRequiredValidationMessage"
                [errorFlag]="recipeSubBusinessUnitErrorFlag"
                [readonly]="isRecipeAboutPageReadOnly"
                [setPrimarySubBusinessUnit]="true"
                (valueChange)="subBusinessUnitChange($event)"
                [disabled]="!enableBasicAndAdditionalFields"></app-sub-business-unit-select>
            </div>
          </div>
        </div>
        <div class="sub-heading-spacer-additional-fields">
          <h5 id="eln-AdditionalFields" class="d-contents sub-heading">
            {{ additionalFields | uppercase }}
          </h5>
        </div>
        <div class="flex eln-flex-container">
          <div class="flex eln-flex flex-two-column-layout">
            <div class="eln-flex-item">
              <app-user-select
                id="eln-drpVerifers"
                i18n-label="@@Verifiers"
                i18n-placeholder="@@VerifiersPlaceHolder"
                label="Verifier(s)"
                placeholder="Select Verifier(s)"
                [value]="recipe.tracking.assignedVerifiers"
                [readOnly]="isRecipeAboutPageReadOnly"
                [roles]="['ELN Recipe Verifier']"
                [ngModelOptions]="{ updateOn: 'blur' }"
                [multiSelect]="true"
                (valueChange)="recipeVerifierChanged($event)"
                [usersToBeExcludedFromDisplay]="usersExcludedFromVerifiers"
                [disabled]="!enableBasicAndAdditionalFields"></app-user-select>
            </div>
            <div class="eln-flex-item">
              <app-user-select
                id="eln-drpApprovers"
                i18n-label="@@Approvers"
                i18n-placeholder="@@ApproversPlaceHolder"
                label="Approver(s)"
                placeholder="Select Approver(s)"
                [value]="recipe.tracking.assignedApprovers"
                [readOnly]="isRecipeAboutPageReadOnly"
                [roles]="['ELN Recipe Approver']"
                [ngModelOptions]="{ updateOn: 'blur' }"
                [multiSelect]="true"
                (valueChange)="recipeApproverChanged($event)"
                [usersToBeExcludedFromDisplay]="usersExcludedFromApprovers"
                [disabled]="!enableBasicAndAdditionalFields"></app-user-select>
            </div>
            <div class="eln-flex-item">
              <bpt-dropdown
                i18n-label="@@Clients"
                [multiSelect]="true"
                i18n-placeholder="@@ClientsPlaceHolder"
                placeholder="Select Client(s)"
                label="Client(s)"
                id="eln-drpClients"
                [options]="clientOptions"
                [showClear]="true"
                [showFilter]="true"
                [multiSelect]="true"
                [showToggleAll]="true"
                disabledField="inactive"
                [wrap]="true"
                [disabled]="!enableBasicAndAdditionalFields"
                (ngModelChange)="clientsChanged($event)"
                [(ngModel)]="this.recipe.clients"></bpt-dropdown>
            </div>
            <div class="eln-flex-item">
              <bpt-dropdown
                i18n-label="@@Projects"
                [multiSelect]="true"
                i18n-placeholder="@@ProjectsPlaceHolder"
                placeholder="Select Project(s)"
                label="Project(s)"
                id="eln-drpProjects"
                [options]="projectOptionsBasedOnClientsSelected"
                [showClear]="true"
                [showFilter]="true"
                [multiSelect]="true"
                [showToggleAll]="true"
                disabledField="inactive"
                [wrap]="true"
                (ngModelChange)="projectsChanged($event)"
                [(ngModel)]="this.recipe.projects"
                [disabled]="!enableBasicAndAdditionalFields || !isProjectsEditable"></bpt-dropdown>
            </div>
            <div class="eln-flex-item">
              <bpt-text-input
                inputId="eln-txtMethod"
                i18n-label="@@Method"
                i18n-placeholder="@@MethodPlaceHolder"
                label="Method"
                placeholder="Enter Method"
                [ngModel]="recipe.method"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="initiateMethodEditing($event)"
                [required]="false"
                [readOnly]="isRecipeAboutPageReadOnly"
                [disabled]="!enableBasicAndAdditionalFields"></bpt-text-input>
            </div>
            <div class="eln-flex-item">
              <bpt-dropdown
                i18n-label="@@Compendia"
                [multiSelect]="true"
                i18n-placeholder="@@CompendiaPlaceHolder"
                placeholder="Select Compendia"
                label="Compendia"
                id="eln-drpCompendia"
                [options]="compendiaValues"
                [showClear]="true"
                [showFilter]="true"
                [multiSelect]="true"
                [showToggleAll]="true"
                [ngModel]="recipe.compendia"
                (ngModelChange)="compendiaChanged($event)"
                disabledField="inactive"
                [wrap]="true"
                [disabled]="!enableBasicAndAdditionalFields"></bpt-dropdown>
            </div>
            <div class="eln-flex-item">
              <bpt-text-input
                inputId="eln-txtAnalysisTechnique"
                i18n-label="@@AnalysisTechnique"
                i18n-placeholder="@@AnalysisTechniquePlaceHolder"
                label="Analysis/Technique"
                placeholder="Enter Analysis/Technique"
                [ngModel]="recipe.analysisTechnique"
                [ngModelOptions]="{ updateOn: 'blur' }"
                (ngModelChange)="initiateAnalysisTechniqueEditing($event)"
                [required]="false"
                [readOnly]="isRecipeAboutPageReadOnly"
                [disabled]="!enableBasicAndAdditionalFields"></bpt-text-input>
            </div>
            <div class="eln-flex-item">
              <bpt-tags
                i18n-label="@@tags"
                [useCommaSeparator]="true"
                label="Tags"
                [ngModel]="recipe.tags"
                (ngModelChange)="tagChanged($event)"
                [readOnly]="isRecipeAboutPageReadOnly"
                placeholder="Enter the Tag(s)"
                i18n-placeholder="@@tagsPlaceHolder"
                id="eln-drpTags"
                [required]="false"
                [disabled]="!enableBasicAndAdditionalFields"></bpt-tags>
            </div>
            <div *ngIf="recipe.tracking.state === 'published'" class="eln-flex-item">
              <p-checkbox
                id="eln-chkbxIsRecipeAvailable"
                class="eln-checkBox"
                [binary]="true"
                label="Available"
                i18n-label="@@RecipeAvailable"
                [(ngModel)]="recipeAvailable"
                (ngModelChange)="changeAvailability()">

              </p-checkbox>
            </div>
          </div>
        </div>
        <div class="sub-heading-spacer">
          <h5 id="eln-VerificationInformation" class="d-contents sub-heading">
            {{ verificationInformation | uppercase }}
          </h5>
        </div>
        <div class="flex eln-flex-container">
          <div class="flex eln-flex">
            <p-checkbox
              id="eln-chkbxApprovalRequired"
              class="eln-checkBox"
              [binary]="true"
              label="Approval Required"
              i18n-label="@@ApprovalRequired"
              [ngModel]="recipe.verificationDetails.isApprovalRequired"
              (ngModelChange)="changeApprovalRequired($event)"
              [disabled]="!isUserEditorVerifierApproverOfRecipe() || !enableApprovalRequiredAndPurpose">

            </p-checkbox>
          </div>
          <div class="col-6 input-area">
            <bpt-text-input
              inputId="eln-txtpurpose"
              [label]="recipePurposeLabel"
              [multiLine]="true"
              [rows]="3"
              [autoResize]="true"
              [readOnly]="false"
              [placeholder]="recipePurpose"
              [suppressContextMenu]="false"
              [ngModel]="recipe.verificationDetails.purpose"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="changePurposeValue($event)"
              [disabled]="!isUserEditorVerifierApproverOfRecipe() || !enableApprovalRequiredAndPurpose || recipe.verificationDetails.isVerified"></bpt-text-input>

          </div>
          <p-checkbox
            id="eln-chkbxRecipeStaisfiesPurpose"
            class="eln-checkBox"
            [binary]="true"
            [(ngModel)]="recipe.verificationDetails.isRecipeSatisfiesItsPurpose"
            label="This recipe satisfies its purpose"
            (ngModelChange)="isRecipeSatisfiesPurposeChanged($event)"
            i18n-label="@@SatisfiesPurpose"
            [disabled]="!isUserVerifierOfRecipe() || 
            !enableAcceptanceAndVerified || recipe.verificationDetails.purpose.trim() === '' || recipe.verificationDetails.isVerified">

          </p-checkbox>
          <div class="recipeReviewed">
            <p-checkbox
              id="eln-chkbxRecipeReviewed"
              class="eln-checkBox"
              [binary]="true"
              [(ngModel)]="recipe.verificationDetails.isConsideredReviewed"
              label="All contained recipe elements have been reviewed against the intended documentation. When loaded into an experiment, unchanged recipe contents may be considered reviewed."
              (ngModelChange)="isRecipeReviewedChanged($event)"
              i18n-label="@@RecipeReviewed"
              name="RecipeReviewed"
              [disabled]="!isUserVerifierOfRecipe() ||
               !enableAcceptanceAndVerified || recipe.verificationDetails.purpose.trim() === '' || recipe.verificationDetails.isVerified">

            </p-checkbox>
          </div>
          <div class="col-6 input-area">
            <bpt-text-input
              inputId="eln-additionalNotes"
              i18n-label="@@additionalNotes"
              [multiLine]="true"
              [rows]="3"
              [autoResize]="true"
              [label]="recipeAdditionalNotesLabel"
              [suppressContextMenu]="false"
              [placeholder]="recipePurpose"
              [ngModel]="recipe.verificationDetails.additionalNotes"
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="changeAdditionalNotesValue($event)"
              [disabled]="!isUserEditorVerifierApproverOfRecipe() || !enableAdditionalNotes"></bpt-text-input>
          </div>
          <div class="">
            <p-checkbox
              class="eln-checkBox"
              [binary]="true"
              [disabled]="false"
              [(ngModel)]="recipe.verificationDetails.isVerified"
              label="Verified"
              (click)="isVerifiedChanged()"
              inputId="eln-recipeVerified"
              i18n-label="@@verified"
              id="eln-recipeVerified"
              [readonly]="true"

              [disabled]="
                !isUserVerifierOfRecipe() ||
                !enableAcceptanceAndVerified ||
                recipe.verificationDetails.purpose.trim() === '' ||
                !recipe.verificationDetails.isRecipeSatisfiesItsPurpose ||
                !recipe.verificationDetails.isConsideredReviewed
              ">
            </p-checkbox>
          </div>
          <div class="verified-by-info">

            <div
              *ngIf="
                recipe.verificationDetails.isVerified &&
                recipe.verificationDetails.verifiedOn &&
                recipe.verificationDetails.verifiedBy
              "
              i18n="@@verifiedBy">
              {{
              'Verified by ' +
              (recipe.verificationDetails.verifiedBy
              ? getFullName(recipe.verificationDetails.verifiedBy)
              : recipe.verificationDetails.verifiedBy) +
              ': ' +
              formatOptionalInstant(recipe.verificationDetails.verifiedOn)
              }}
            </div>

          </div>
          <div>
            <p-checkbox
              class="eln-checkBox"
              [binary]="true"
              [disabled]="false"
              [(ngModel)]="recipe.verificationDetails.isApproved"
              label="Approved"
              id="eln-recipeApproved"
              i18n-label="@@approved"
              *ngIf="recipe.verificationDetails.isApprovalRequired"
              (click)="isApprovalChanged()"
              [readonly]="true"
              [disabled]="!checkRecipeState(['pendingApproval']) || !isUserApproverOfTheRecipe()">
            </p-checkbox>
          </div>
          <div class="approved-by-info">

            <div
              *ngIf="
                recipe.verificationDetails.isApproved &&
                recipe.verificationDetails.approvedOn &&
                recipe.verificationDetails.approvedBy
              "
              i18n="@@approvedBy">
              {{
              'Approved by ' +
              (recipe.verificationDetails.approvedBy
              ? getFullName(recipe.verificationDetails.approvedBy)
              : recipe.verificationDetails.approvedBy) +
              ': ' +
              formatOptionalInstant(recipe.verificationDetails.approvedOn)
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="eln-slide-content" id="nexus-slider">
  <app-recipe-sources-slider
    [sliderVisible]="sourcesSliderVisible"
    (closePanel)="hideSourcesSlider()"></app-recipe-sources-slider>
</div>