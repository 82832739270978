import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { BptGridComponent, BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { RecipeSourcesService } from '../../services/recipe-sources.service';
import { navigateRecipeToAboutPage } from '../../recipe.routes';

export interface RecipeSourcesModel {
  referenceNumber: string;
  name: string;
  version: string;
  workflowState: string;
  id: string;
  type: any;
  addedTime?: Date;
  sourceType: string;
}

@Component({
  selector: 'app-recipe-sources-slider',
  templateUrl: './recipe-sources-slider.component.html',
  styleUrls: ['./recipe-sources-slider.component.scss']
})
export class RecipeSourcesSliderComponent {
  @Output() closePanel: EventEmitter<string> = new EventEmitter<string>();
  @Input() sliderVisible = false;

  @ViewChild('grid') grid!: BptGridComponent;

  gridMode = BptGridMode.dataView;
  data: RecipeSourcesModel[] = [];
  dataLoaded = false;
  sourcesHeader = $localize`:@@recipeSourcesHeader:Sources`
  sourcesSubHeading = $localize`:@@recipeSourcesSubHeading:Source(s) lists references of other recipes and/or templates used in this recipe`
  columnDefinitions: ColumnDefinition[] = [
    {
      label: $localize`:@@recipeSourcesNumberColumn:Reference Number`,
      field: 'referenceNumber',
      width: 230,
      columnType: 'link',
      cellClass: 'cell-Link',
      linkData: (_: MouseEvent, data: RecipeSourcesModel) => {
        if (data) {
          this.clickedReferencedRecipeOrTemplateNumber(data);
        }
      }
    },
    {
      label: $localize`:@@recipeSourcesNameColumn:Name`,
      field: 'name',
      columnType: 'string',
      width: 265
    },
    {
      label: $localize`:@@recipeSourcesVersionColumn:Version`,
      field: 'version',
      columnType: 'string',
      width: 130
    },
    {
      label: $localize`:@@recipeSourcesWorkflowStateColumn:Workflow State`,
      field: 'workflowState',
      columnType: 'string',
      width: 212
    }
  ];

  constructor(
    private readonly recipeSourcesService: RecipeSourcesService
  ) {}

  loadSources() {
    this.data = [];
    this.recipeSourcesService.getSources(this.setSources);
  }

  setSources = (data: RecipeSourcesModel[], _recipeRecords: RecipeSourcesModel[])  => {
    this.data = data;
    this.dataLoaded = true;
    this.grid.gridApi.setGridOption('rowData', this.data);
  };

  clickedReferencedRecipeOrTemplateNumber(data: RecipeSourcesModel) {
    if (data.sourceType === 'Template') window.open(`/template-designer/${data.id}/${data.type.toLowerCase()}-designer`, '_blank');
    else navigateRecipeToAboutPage(data.referenceNumber, data.version.substring(1));
  }

  closeSlider() {
    this.closePanel.emit('closePanel');
  }
}
