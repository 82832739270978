import { Injectable } from '@angular/core';
import { RecentSearch } from 'bpt-ui-library/bpt-search';
import { BptIconDictionary, BptSizes } from 'bpt-ui-library/shared';
import { BehaviorSubject, forkJoin, map, Observable, of, Subject, Subscription, tap } from 'rxjs';
import { UserPreferenceService } from '../.../../../api/services';
import {
  RecipeRecord,
  SearchCriteria,
  TemplateType as searchTemplateType,
  TemplateSummary,
  TemplateSummaryForLoad
} from '../.../../../api/search/models';
import {
  TemplateType as coreServicesTemplateType,
  UserPreference} from '../.../../../api/models';
import { RecipeService, TemplateService } from '../.../../../api/search/services';
import { TemplateListPresentationHelper } from '../models/template-list-presentation-helper';
import { TemplateSearchCommand } from '../models/template-search-command.interface';

import { UserService } from '../.../../../services/user.service';
import * as uuid from 'uuid';
import { isEmpty, camelCase, pick } from 'lodash-es';
import { DateAndInstantFormat, formatInstant } from '../../shared/date-time-helpers';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { SearchTemplateForLoadCommand } from '../models/search-template-for-load-command.model';

@Injectable()
export class TemplateLoaderService {
  private leastTemplateTypes: string[] = [];
  private favouriteTemplatePreferences: UserPreference[] = [];
  public static readonly favoriteTemplateUserPreferenceKey: string = 'eln-favorite-templates';
  public templateSearchResultSource: Subject<RecentSearch[]> = new Subject<RecentSearch[]>();
  private readonly templateSearchResultStore: BehaviorSubject<RecentSearch[]> = new BehaviorSubject<
    RecentSearch[]
  >([]);
  private recentTemplateSummaryList?: TemplateSummaryForLoad[];
  subscriptions: Subscription[] = [];
  constructor(
    private readonly templateSearchService: TemplateService,
    private readonly userPreferenceApiService: UserPreferenceService,
    private readonly userService: UserService,
    private readonly searchService: RecipeService
  ) {
    this.subscribeToTemplateSearchResults();
  }

  flushSubscribers(): void {
    this.templateSearchResultSource = new Subject<RecentSearch[]>();
    this.subscribeToTemplateSearchResults();
  }

  private subscribeToTemplateSearchResults(): void {
    this.templateSearchResultSource.subscribe((result) => {
      this.templateSearchResultStore.next(result);
    });
  }

  public restoreSearchResults() {
    let suggestions: RecentSearch[] = [];
    const subscription = this.templateSearchResultStore.subscribe((recentResults) => {
      suggestions = recentResults;
    });
    subscription.unsubscribe();
    this.templateSearchResultSource.next(suggestions);
  }

  leastTemplateTypesToFilter(templateTypes: string[]): void {
    this.leastTemplateTypes = templateTypes;
  }

  markTemplateAsFavorite(id: string): Observable<boolean> {
    if (this.isFavoriteTemplate(id)) {
      return of(true);
    }
    return this.addPreferenceToFavoriteTemplates(id);
  }

  removeFavoriteTemplate(id: string): Observable<boolean> {
    const userPreferenceIdIndex = this.favouriteTemplatePreferences.findIndex(
      (preference) => preference.userPreferenceValue === id
    );
    if (userPreferenceIdIndex >= 0) {
      const preferenceId =
        this.favouriteTemplatePreferences[userPreferenceIdIndex].userPreferenceId;
      if (!this.favouriteTemplatePreferences) {
        return of(false);
      }
      return this.deletePreference(preferenceId);
    } else {
      return of(false);
    }
  }

  fetchTemplatesOnly(templateSearchCriteria: TemplateSearchCommand) {
    const searchCommand : SearchTemplateForLoadCommand = TemplateLoaderService.asTemplateLoadSearchCommand(templateSearchCriteria);
    if(!this.validateSearchCriteria(templateSearchCriteria)) {
      this.templateSearchResultSource.next([]);
      return;
    }

    if(this.subscriptions) {
      UnsubscribeAll(this.subscriptions);
    }
    this.subscriptions.push(this.templateSearchService
      .templatesSearchForLoadGet$Json(searchCommand)
      .pipe(
        tap(response => this.recentTemplateSummaryList = response),
        map((response) => this.mapTemplateSearchResultsToRecentSearch(response)),
        tap((finalizedTemplateList) => this.templateSearchResultSource.next(finalizedTemplateList))
      )
      .subscribe({
        error: () => {
          this.templateSearchResultSource.next([]);
          UnsubscribeAll(this.subscriptions);
        }
      }));
  }

  fetchRecipesOnly(recipeCriteria: SearchCriteria) {
    if(this.subscriptions) {
      UnsubscribeAll(this.subscriptions);
    }
    this.subscriptions.push(this.searchService
      .recipeSearchRecipeIndexPost$Json({body:recipeCriteria})
      .pipe(
        map((response) => this.mapRecipeResultsToRecentSearch(response.records)),
        tap((finalizedTemplateList) => this.templateSearchResultSource.next(this.orderBothTemplateAndRecipes(finalizedTemplateList)))
      )
      .subscribe({
        error: () => {
          this.templateSearchResultSource.next([]);
          UnsubscribeAll(this.subscriptions);
        }
      }));
  }

  fetchRecipesAndTemplatesOnly(templateSearchCriteria: TemplateSearchCommand, recipeSearchCriteria: SearchCriteria) {
    if (this.subscriptions) {
      UnsubscribeAll(this.subscriptions);
    }
    const subscriptions: any = {};
    /** Triggering TemplatesSearchForLoad with empty type returns all templates.*/
    if (templateSearchCriteria.templateTypes !== '') {
      subscriptions['templates'] =
        this.templateSearchService.templatesSearchForLoadGet$Json(TemplateLoaderService.asTemplateLoadSearchCommand(templateSearchCriteria));
    }
    subscriptions['recipes'] = this.searchService.recipeSearchRecipeIndexPost$Json({
      body: recipeSearchCriteria
    });
    this.subscriptions.push(forkJoin(subscriptions).subscribe({
      next: (value: any) => {
        const d1 = this.mapTemplateSearchResultsToRecentSearch(value.templates);
        const d2 = this.mapRecipeResultsToRecentSearch(value.recipes.records);
        const d3 = d1.concat(d2);
        this.templateSearchResultSource.next(this.orderBothTemplateAndRecipes(d3));
      },
      error: () => {
        this.templateSearchResultSource.next([]);
        UnsubscribeAll(this.subscriptions);
      }
    }))
  }

  cancelPendingCalls() {
    UnsubscribeAll(this.subscriptions);
  }

  public fetchFavoriteTemplates(): void {
    this.userPreferenceApiService
      .userPreferencesUserPreferenceKeyGet$Json({
        userPreferenceKey: TemplateLoaderService.favoriteTemplateUserPreferenceKey
      })
      .subscribe({
        next: (data) => {
          this.favouriteTemplatePreferences = data.userPreferences;
          this.syncFavoritesToTemplateList();
        }
      });
  }

  private syncFavoritesToTemplateList(): void {
    if(this.recentTemplateSummaryList){
      this.templateSearchResultSource.next(this.mapTemplateSearchResultsToRecentSearch(this.recentTemplateSummaryList));
    }
  }

  private validateSearchCriteria(searchCommand: TemplateSearchCommand): boolean {
    return this.validateSearchCriteriaToTemplateTypes(searchCommand.templateTypes as string);
  }

  private validateSearchCriteriaToTemplateTypes(templateTypesCriteria: string): boolean {
    if (this.leastTemplateTypes.length === 0) {
      return true;
    }
    if (!templateTypesCriteria || templateTypesCriteria.length === 0) {
      return false;
    }
    const searchCriterialTemplateTypes = templateTypesCriteria.split(',');
    return this.leastTemplateTypes.some((templateType) =>
      searchCriterialTemplateTypes.includes(templateType)
    );
  }

  private deletePreference(preferenceId: string): Observable<boolean> {
    const acknowledgement = new Subject<boolean>();
    this.userPreferenceApiService
      .userPreferencesUserPreferenceIdDelete$Json({
        userPreferenceId: preferenceId
      })
      .subscribe(() => {
        acknowledgement.next(true);
        this.favouriteTemplatePreferences = this.favouriteTemplatePreferences.filter(
          (preference) => preference.userPreferenceId !== preferenceId
        );
      });
    return acknowledgement.asObservable();
  }

  private addPreferenceToFavoriteTemplates(id: string): Observable<boolean> {
    const acknowledgement = new Subject<boolean>();
    this.userPreferenceApiService
      .userPreferencesSaveUserPreferencePost$Json({
        body: this.getDefaultUserPreferenceOfFavoriteTemplate(id)
      })
      .subscribe((result) => {
        if(result.userPreference){
          this.favouriteTemplatePreferences.push(result.userPreference);
        }
        acknowledgement.next(true);
      });
    return acknowledgement.asObservable();
  }

  private isFavoriteTemplate(id: string): boolean {
    if (isEmpty(this.favouriteTemplatePreferences)) {
      return false;
    } else {
      return this.favouriteTemplatePreferences.some(
        (preference) => preference.userPreferenceValue === id
      );
    }
  }

  private getDefaultUserPreferenceOfFavoriteTemplate(id: string): UserPreference {
    return {
      userPuid: this.userService.currentUser.puid,
      userPreferenceId: uuid.v4(),
      userPreferenceKey: TemplateLoaderService.favoriteTemplateUserPreferenceKey,
      userPreferenceName: $localize`:@@favoriteTemplate:ELN user favorite templates`,
      userPreferenceValue: id,
      isDefault: false
    };
  }

  private orderBothTemplateAndRecipes(templatesSummary: RecentSearch[]) {
    const favorites: RecentSearch[] = [];
    const orderedTemplates: RecentSearch[] = [];
    for (let index = 0; index < templatesSummary.length; index++) {
      const template = templatesSummary.slice(index, index + 1);
      if (this.isFavoriteTemplate(template[0].groupingFieldsData!.id)) {
        favorites.push(template[0]);
      } else {
        orderedTemplates.push(template[0]);
      }
    }
    orderedTemplates.unshift(...favorites);
    return orderedTemplates;
  }

  private orderTheTemplates(templatesSummary: TemplateSummaryForLoad[] | RecipeRecord[]) {
    const favorites: any = [];
    const orderedTemplates: any = [];
    for (let index = 0; index < templatesSummary.length; index++) {
      const template = templatesSummary.slice(index, index + 1);
      if (this.isFavoriteTemplate(template[0].id)) {
        favorites.unshift(template[0]);
      } else {
        orderedTemplates.unshift(template[0]);
      }
    }
    this.sortTemplates(orderedTemplates);
    this.sortTemplates(favorites);
    orderedTemplates.unshift(...favorites);
    return orderedTemplates;
  }

  private sortTemplates(templatesSummary: TemplateSummary[] | RecipeRecord[]): void {
    templatesSummary.sort((lt, rt) => lt.name.localeCompare(rt.name));
  }

  private mapRecipeResultsToRecentSearch(recipeRecord: RecipeRecord[]): RecentSearch[] {
    if(!recipeRecord) return [];
    recipeRecord = this.orderTheTemplates(recipeRecord);
    const versionText = $localize`:@@version:Version: V`;
    const modifiedOn = $localize`:@@modifiedOn:Modified on:`;
    const publishedOn = $localize`:@@publishedOn:Published on:`;
    return recipeRecord.map((summary) => {
      return {
        id: 0,
        text: summary.name,
        groupingFieldsData: {
          isCore: false,
          id: summary.recipeId,
          templateNumber: summary.number,
          templateTechniquesAndAnalysis: summary.analysisTechnique,
          templateType: camelCase(summary.type),
          isRecipe: true
        },
        subText: `${summary.number} ${versionText}${summary.version}`+ (summary.publishedOn ? `${publishedOn} ${formatInstant(summary.publishedOn, DateAndInstantFormat.date)}` : `${modifiedOn} ${formatInstant(summary.createdOn, DateAndInstantFormat.date)}`),
        selectedValue: summary.name + $localize`:@@version:(Version ${summary.version})`,
        leadingIcon: TemplateListPresentationHelper.getRecipeTypeDisplayIconDefinition(
          summary.type
        ),
        tailIcons: [
          {
            ...(this.isFavoriteTemplate(summary.recipeId)
              ? BptIconDictionary.StarFill
              : BptIconDictionary.Star),
            size: { iconSize: BptSizes.Small },
            styleClass: 'primary-action-color',
            id: summary.recipeId
          }
        ]
      };
    });
  }

  private mapTemplateSearchResultsToRecentSearch(
    templatesSummary: TemplateSummaryForLoad[]
  ): RecentSearch[] {
    if(!templatesSummary) return [];
    templatesSummary = this.orderTheTemplates(templatesSummary);
    const versionText = $localize`:@@version:Version: V`;
    const editedOn = $localize`:@@editedOn:Edited on:`;
    const publishedOn = $localize`:@@publishedOn:Published on:`;
    return templatesSummary.map((summary) => {
      return {
        id: 0,
        text: summary.name,
        groupingFieldsData: {
          isCore: summary.isCore,
          id: summary.id,
          templateNumber: summary.number,
          templateType: TemplateLoaderService.convertTemplateType(summary.templateType)
        },
        subText: `${summary.number} ${versionText}${summary.version} `+ (summary.templateManagement.publishedOn ? `${publishedOn} ${formatInstant(summary.templateManagement.publishedOn, DateAndInstantFormat.date)}` :`${editedOn} ${formatInstant(summary.templateManagement.lastEditedOn, DateAndInstantFormat.date)}`),
        selectedValue: summary.name + $localize`:@@version:(Version ${summary.version})`,
        leadingIcon: TemplateListPresentationHelper.getTemplateTypeDisplayIconDefinition(
          summary.templateType
        ),
        tailIcons: [
          {
            ...(this.isFavoriteTemplate(summary.id)
              ? BptIconDictionary.StarFill
              : BptIconDictionary.Star),
            size: { iconSize: BptSizes.Small },
            styleClass: 'primary-action-color',
            id: summary.id
          }
        ]
      };
    });
  }

  private static convertTemplateType(templateType: searchTemplateType): coreServicesTemplateType {
    switch (templateType) {
      case searchTemplateType.Activity:
        return coreServicesTemplateType.Activity;
      case searchTemplateType.Module:
        return coreServicesTemplateType.Module;
      case searchTemplateType.Table:
        return coreServicesTemplateType.Table;
      case searchTemplateType.Form:
        return coreServicesTemplateType.Form;
    }
    return coreServicesTemplateType.Invalid;
  }

  private static asTemplateLoadSearchCommand(templateSearchCriteria: TemplateSearchCommand): SearchTemplateForLoadCommand {
    return {
      ConsumingLabsiteCodes : templateSearchCriteria.consumingLabsiteCodes,
      GetLatestVersion : templateSearchCriteria.getLatestVersion,
      SubBusinessUnitCodes: templateSearchCriteria.subBusinessUnitCodes,
      TemplateTypes: templateSearchCriteria.templateTypes
    };
  }
}
