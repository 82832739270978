<ng-template #slideContent
  id="eln-recipe-about-sources-slider">
  <div class="slider-content">
    <div class="slider-heading-section">
      <h1 class="slider-header">{{sourcesHeader}}</h1>
      <p class="mt-2">{{sourcesSubHeading}}</p>
    </div>

    <div class="slider-table-section">
      <bpt-grid *ngIf="dataLoaded"
        #grid
        id="eln-recipe-sources-slider-grid"
        class="eln-grid hide-readonly-icons"
        [columnDefinitions]="columnDefinitions"
        [readOnly]="true"
        [includeRowNumberColumn]="false"
        [gridMode]="gridMode"
        [dataSource]="data"
        [showAutoSizeButton]="false"
        [showFilterToggleButton]="false"
        [showGridOptionsButton]="false">
      </bpt-grid>
    </div>
  </div>
</ng-template>

<app-slide-panel
  [showSlidePanel]="sliderVisible"
  [slidePanelContent]="slideContent"
  (closePanel)="closeSlider()"
  id="eln-recipe-sources-slide-panel">
</app-slide-panel>