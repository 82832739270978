import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RecipeService, SpecificationEditorContext, SpecificationPreloadScalingOptions, recipeStateLabel } from './services/recipe.service';
import { ClientValidationDetails } from '../model/client-validation-details';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CopyRecipeDialogComponent } from './copy-recipe-dialog/copy-recipe-dialog.component';
import { ClientStateService } from '../services/client-state.service';
import { BaseComponent } from '../base/base.component';
import {
  AugmentedActivity,
  RecipeModel,
  RecipePublishChange,
  RecipeStartNewVersionChange,
  RecipeTypeChange,
  RefrencedRecipeDraftVersion as RefrencedRecipeDraftVersion
} from './model/recipe';
import {
  NodeType,
  RecipeAddTemplateCommand,
  RecipeReferenceResponse,
  RecipeState,
  RecipeTemplateAddedResponse,
  RecipeType,
  TemplateType,
  VariablesNode
} from '../api/cookbook/models';
import { ClientState } from '../app.states';
import { TemplateLoaderResult } from '../recipe-template-loader/models/template-loader-result';
import { SelectedTemplate } from '../recipe-template-loader/models/selected-template';
import { UserService } from '../services/user.service';
import { TemplateLoaderType } from '../recipe-template-loader/models/template-loader-type';
import { SearchControl } from 'bpt-ui-library/bpt-search';
import { User as UserInterface } from '../model/user.interface';
import { TemplateEventService } from '../recipe-template-loader/experiment-template-load/services/template-event.service';
import { ELNFeatureFlags } from '../shared/eln-feature-flags';
import { Activity, SpecificationValue } from '../model/experiment.interface';
import { MainMenuItem } from 'bpt-ui-library/bpt-layout';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription, filter, of, take } from 'rxjs';
import { elnEncodeSpecialChars } from '../shared/url-path-serializer';
import { replace } from 'lodash-es';
import { MenuItem, Message, MessageService } from 'primeng/api';
import { UnsubscribeAll } from '../shared/rx-js-helpers';
import { RecipeNodeRetitleService } from './services/recipe-node-re-title.service';
import { RuleActionHandlerHostService, RuleActionHandlerHostItemType } from '../rule-engine/handler/rule-action-handler-host.service';
import { RecipeNodeReOrderService } from './services/recipe-node-re-order.service';
import { RuleActionNotificationService } from '../rule-engine/action-notification/rule-action-notification.service';
import { SetVariableCommand } from '../api/data-entry/models';
import { LoaderSearchCriteria } from '../recipe-template-loader/experiment-template-load/models/finalised-search-criteria';
import { SelectedTemplateCommand } from '../recipe-template-loader/models/template-loader-information';
import { TemplateLocationOptions } from '../recipe-template-loader/experiment-template-load/models/insert-location.interface';
import { TemplateLoaderFilterChangedEvent } from '../recipe-template-loader/models/template-loader-filter-changed-event.interface';
import { RecipeNotificationService } from './services/recipe-notification.service';
import { RecipeSourcesService } from './services/recipe-sources.service';
import { RecipeSourcesModel } from './recipe-about/recipe-sources-slider/recipe-sources-slider.component';
import { DialogConfig, ModalButton } from './recipe-prompts/custom-dialog/custom-dialog.component';
import { BptGridMode, ColumnDefinition } from 'bpt-ui-library/bpt-grid';
import { ColumnType } from '../api/models';
import { ElnProgressSpinnerService } from '../eln-progress-spinner-module/eln-progress-spinner.service';

type StateFilterMapping = {
  [key in RecipeState]?: string[];
};

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class RecipeComponent extends BaseComponent implements OnInit, OnDestroy {
  recipe!: RecipeModel;
  workflowActions: MenuItem[] = [];
  showTemplateLoader = false;
  recipeLoaded = false;
  sbuLoaded = false;
  copyRecipeDialogRef!: DynamicDialogRef;
  enableNewRecipeSave = false;
  enableNewVersion = false;
  enableWorkflowAction = true;
  featureFlags: string[] = [];
  allReferencedRecipesWithMajorVersionAvailable = '';
  messages: Message[] = [];
  designerAccessibility = '';
  templateLoaderType: TemplateLoaderType = TemplateLoaderType.TemplateToRecipe;
  loaderSearchCriteria: LoaderSearchCriteria = {};
  searchControls: SearchControl[] = [];
  currentUser: UserInterface;
  WorkflowActionsLabel = '';
  saveNewRecipeBtnTooltip = $localize`:@@newRecipeBtnTooltip:This will create a new recipe, copying contents from the opened recipe, will have its own reference and versioning`;
  startNewVersionBtnTooltip = $localize`:@@newVersionBtnTooltip:Starting new draft version will replace the current published version of this recipe once published`;
  loadingMessage = $localize`:@@loadingRecipe:Loading Recipe...`;
  recipeVersionDisplayMessageDefault = $localize`:@@recipeVersion:Version` + ' ';
  toolTip = $localize`:@@RecipeOrTemplateSearchTooltip:Search by Template/Recipe Name or Number`;
  recipeVersionDisplayMessage = '';
  loadAboutPage = false;
  allowSaveNewVersion = false;
  subscriptionCount = 0;

  /**
   * Error related to typeChange of referenced Recipes.
   */
  hasTypeChangeErrors = false;

  /**
   * Error related to Major version availability of referenced Recipes.
   */
  hasMajorVersionErrorOfReferencedRecipes = false;

  hasWarnings = false;
  count = 0;
  isReferenceComplete = true;
  showWarningGridForMinorDraftVersions = false;
  collabratorShowWarningGridForMinorDraftVersions = false;
  gridMode = BptGridMode.dataView;
  refrencedRecipeRecords: RecipeSourcesModel[] = [];
  public otherVersionsOfReferencedRecipesDictionary: { [key: string]: string[] } = {};
  public otherMajorVersionsOfReferencedRecipesDictionary: { [key: string]: string[] } = {};
  definitions: ColumnDefinition[] = [
    {
      field: 'recipeNumber',
      columnType: ColumnType.String,
      label: $localize`:@@referenceTitle:Reference`,
      editable: false,
      width: 213,
      sortable: false,
      suppressMovable: true,
      suppressMenu: true,
      showInColumnChooser: true
    },
    {
      field: 'recipeVersion',
      columnType: ColumnType.String,
      label: $localize`:@@recipeVersion:Version`,
      editable: false,
      width: 221,
      sortable: false,
      suppressMovable: true,
      suppressMenu: true,
      showInColumnChooser: true
    }
  ];
  refrencedRecipeNewDraftVersionGridData: RefrencedRecipeDraftVersion[] = [];
  referencedRecipeNewPublishedVersionData: RefrencedRecipeDraftVersion[] = [];
  refrencedRecipeDictionary: { [key: string]: number[] } = {};
  disabledMessage = '';

  /**
   * Error related to either of Major version availability of referenced Recipes or typeChange of referenced Recipes.
   */
  recipeHasErrors = false;

  specificationEditorContext?: SpecificationEditorContext;
  sourcesData: RecipeSourcesModel[] = [];
  specificationEditorContextId = (_index: number, item: SpecificationEditorContext | undefined) =>
    item?.id ?? '00000000-0000-0000-0000-000000000000'; // for trackBy, default cannot be undefined; so return a constant to mean no context

  confirmationMessage: { [key: string]: string } = {
    referencedRecipeGenericErrorMessage:
      $localize`:@@referencedRecipeGenericErrorMessage:This recipe cannot be submitted for verification because one or more referenced recipes are draft / cancelled/ retired`,
    draftReferencedRecipeErrorMessage:
      $localize`:@@draftReferencedRecipeErrorMessage:This recipe cannot be submitted for verification because one or more referenced recipes are draft.`,
    retiredReferencedRecipeErrorMessage:
      $localize`:@@retiredReferencedRecipeErrorMessage:This recipe cannot be submitted for verification because one or more referenced recipes are retired.`,
    cancelledReferencedRecipeErrorMessage:
      $localize`:@@cancelledReferencedRecipeErrorMessage:This recipe cannot be submitted for verification because one or more referenced recipes are cancelled.`,
    referencedRecipeGenericErrorMessageForApproval:
      $localize`:@@referencedRecipeGenericErrorMessageForApproval:This recipe cannot be submitted for approval because one or more referenced recipes are yet to be verified.`,
    draftReferencedRecipeErrorMessageForApproval:
      $localize`:@@draftReferencedRecipeErrorMessageForApproval:This recipe cannot be submitted for approval because one or more referenced recipes are draft, return back this recipe to draft in order to fetch the latest data from referenced recipes.`,
    retiredReferencedRecipeErrorMessageForApproval:
      $localize`:@@retiredReferencedRecipeErrorMessageForApproval:This recipe cannot be submitted for approval  because one or more referenced recipes are retired.`,
    cancelledReferencedRecipeErrorMessageForApproval:
      $localize`:@@cancelledReferencedRecipeErrorMessageForApproval:This recipe cannot be submitted for approval because one or more referenced recipes are Cancelled.`,
    pendingVerificationReferencedRecipeErrorMessageForApproval:
      $localize`:@@pendingVerificationReferencedRecipeErrorMessageForApproval:This recipe cannot be submitted for approval because one or more referenced recipes are yet to be verified.`,
    recipeCancelConfirmationNoRestore: $localize`:@@recipeCancelConfirmationNoRestore:Cancelling the draft version cannot be undone.
 The pending draft changes will be discarded. A new draft version can be restarted from the latest published version of this recipe.`,
    recipeCancelConfirmationCanRestore: $localize`:@@recipeCancelConfirmationCanRestore:This recipe could still be restored after cancelling.
 Any other recipes referencing this recipe cannot be verified as long as this recipe is in cancelled, are you sure you want to continue?`,
    recipeRestoreConfirmation: $localize`:@@recipeRestoreConfirmation:Are you sure you wish to restore this recipe to draft?`,
    recipeSubmitForVerificationConfirmation: $localize`:@@recipeSubmitForVerificationConfirmation:Are you sure you wish to submit the recipe for verification?`,
    recipeRetireConfirmation: $localize`:@@recipeRetireConfirmation:Retire the recipe so that it is no longer available. This action cannot be undone. To 'restore'
 the recipe, a new version would need to be created. Are you sure you want to continue?`,
    recipeReturnToDraftConfirmation: $localize`:@@recipeReturnToDraftConfirmation:The pending verification will be rejected and require recompletion.
 Are you sure you want to continue?`,
    recipeReturnToDraftApproverConfirmation: $localize`:@@recipeReturnToDraftApproverConfirmation:The pending approval & verification will be rejected and
 require recompletion. Are you sure you want to continue?`,
    recipeSubmitForApprovalConfirmation: $localize`:@@recipeSubmitForApprovalConfirmation:Are you sure you wish to submit the recipe for approval?`,
    recipeSubmitForApprovalWhenUnVerifiedConfirmation: 'Submitting unverified recipe for approval will make it verified. Are you sure you want to continue?',
    publishRecipeMinorDraftDefaultConfirmation: $localize`:
    @@publishRecipeMinorDraftDefaultConfirmation:This will make the recipe available for usage in experiments. Once published, the recipe will only be editable by starting a new draft version`,
    publishRecipeMinorDraftApprovalRequiredConfirmation: $localize`:@@publishRecipeMinorDraftApprovalRequiredConfirmation:This will make the recipe approved,
 once published, the recipe will only be editable by starting a new draft version`,
    publishRecipeMajorDraftDefaultConfirmation: $localize`:
    @@publishRecipeMajorDraftDefaultConfirmation:This will retire the previous version, and any references to this recipe
 will be prompted to use the new version. Once published, the recipe can be updated by starting a new draft version.`,
    publishRecipeMajorDraftApprovalRequiredConfirmation: $localize`:
    @@publishRecipeMajorDraftApprovalRequiredConfirmation:This will make the recipe approved,
 retire the previous version, and any references to this recipe will be prompted to use the new version. Once published, the recipe can be updated by starting a new draft version.`,
    publishRecipeMinorDraftVerificationRequiredConfirmation: $localize`:
    @@publishRecipeMinorDraftVerificationRequiredConfirmation:This will make the recipe verified,
 once published, the recipe will only be editable by starting a new draft version`,
    publishRecipeMajorDraftVerificationRequiredConfirmation: $localize`:
    @@publishRecipeMajorDraftVerificationRequiredConfirmation:This will make the recipe verified, retire the previous version,
 and any references to this recipe will be prompted to use the new version. Once published, the recipe can be updated by starting a new draft version.`,
    publishRecipeAvailableConfirmation: $localize`:
    @@publishRecipeAvailableConfirmation:This will make the recipe available for usage in experiments. Once published, the recipe will only be editable by starting a new draft version`,
    recipeAddActivityTemplateConfirmation: $localize`:@@recipeAddActivityTemplateConfirmation:By loading this activity,
    existing table/form/module will be moved to the newly established activity.`,
    recipeAddModuleTemplateConfirmation: $localize`:@@recipeAddModuleTemplateConfirmation:By loading this module,
     existing table/form will be moved to the newly established module.`,
    referencedRecipeCancelledErrorMessageForPublish:
      $localize`:@@referencedRecipeCancelledErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are cancelled.`,
    referencedRecipeRetiredErrorMessageForPublish:
      $localize`:@@referencedRecipeRetiredErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are retired.`,
    referencedRecipeDraftErrorMessageForPublish:
      $localize`:@@referencedRecipeDraftErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are drafts. Return back this recipe to draft in order to fetch the latest data from referenced recipes.`,
    referencedRecipePendingVerificationErrorMessageForPublish:
      $localize`:@@referencedRecipePendingVerificationErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are not verified. Return back this recipe to draft in order to fetch the latest data from referenced recipes.`,
    referencedRecipePendingApprovalErrorMessageForPublish:
      $localize`:@@referencedRecipePendingApprovalErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are not approved. Return back this recipe to draft in order to fetch the latest data from referenced recipes.`,
    referencedRecipeRetiredAndCancelledErrorMessageForPublish:
      $localize`:@@referencedRecipeRetiredAndCancelledErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are cancelled or retired.`,
    referencedRecipeAnyCombinationErrorMessageForPublish:
      $localize`:@@referencedRecipeAnyCombinationErrorMessageForPublish:This recipe cannot be published because one or more referenced recipes are not approved. Return back this recipe to draft in order to fetch the latest data from referenced recipes.`,
    incompleteReferenceErrorMessage: $localize`:@@incompleteReferenceErrorMessage:Cannot be submitted for verification as all fields must be filled in references.`,
    recipeCannotBeEmpty: $localize`:@@recipeCannotBeEmpty:Recipe cannot be empty.`
  };

  header = $localize`:@@recipeUpdate:Recipe Updated`;
  subHeader = $localize`:@@newDraftVersionAvailableSubHeader:Following referenced recipe has a new draft created. You can reload the new version by deleting the published version`;

  dialogConfig: DialogConfig = {
    headerAlignment: 'center',
    iconColor: 'red'
  };

  modalButtons: ModalButton[] = [
    {
      label: $localize`:@@ok:OK`,
      styleClass: 'bpt-button-compact',
      command: () => this.onOk()
    }
  ];

  iconHtml = 'icon-draft';

  validation!: ClientValidationDetails;

  isLoading = false;

  private readonly WorkflowStatusNames = {
    restored: $localize`:@@restoredState:Restored`
  };

  private readonly subscriptions: Subscription[] = [];
  menuItems: MainMenuItem[] = [];

  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: ''
  };

  private readonly designerPageSubItemsLabel = {
    prompts: $localize`:@@PromptsTableTitle:Prompts`,
    preparations: $localize`:@@preparations:Preparations`,
    parameters: $localize`:@@parameters:Parameters`,
    references: $localize`:@@references:References`
  }

  private readonly allowedStatesForSaveAsNew: RecipeState[] = [
    RecipeState.Published,
    RecipeState.Retired
  ]

  public readonly notAllowedWorkflowStatesForShowingBanner: RecipeState[] = [
    RecipeState.Cancelled,
    RecipeState.Retired
  ];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly recipeService: RecipeService,
    public readonly templateEventService: TemplateEventService,
    clientStateService: ClientStateService,
    private readonly dialogService: DialogService,
    private readonly userService: UserService,
    private readonly browserTitle: Title,
    private readonly ngZone: NgZone,
    private readonly recipeNodeReTitleService: RecipeNodeRetitleService,
    public readonly recipeNodeReOrderService: RecipeNodeReOrderService,
    private readonly ruleActionNotificationService: RuleActionNotificationService,
    private readonly notificationService: RecipeNotificationService,
    private readonly recipeSourcesService: RecipeSourcesService,
    private readonly elnProgressSpinnerService: ElnProgressSpinnerService,
    private readonly messageService: MessageService,
  ) {
    super(clientStateService, route);
    this.currentUser = { ...this.userService.currentUser };
  }

  ngOnInit(): void {
    this.handleRoutes();
    this.watchRecipeUsersChanged();
    this.watchRecipeLoaded();
    this.watchForWarnings();
    this.watchForRecipeTypeChange();
    this.watchPurposeValuesChanged();
    this.watchRecipeApprovalRequiredChanged();
    this.watchForMandatoryRecipeFields();
    this.watchForRulesNotification();
    this.watchForRecipeNewVersionAvailability();
    this.watchForRecipePublishedStateAvailability();
    this.loaderSearchCriteria.recipeSearchCriteria = this.recipeService.recipeSearchCriteria;
    this.isReferenceComplete = !sessionStorage.getItem('referenceCompletion') || sessionStorage.getItem('referenceCompletion') === 'true';
    this.subscriptions.push(
      this.recipeService.isSBULoaded.subscribe({
        next: () => {
          this.sbuLoaded = true;
        }
      })
    );
    this.watchTemplateAppliedNotification();
    this.watchTemplateDeletedNotification();
    this.subscriptions.push(
      this.recipeService.recipeWorkFlowState.subscribe(() => {
        this.loadMenu();
      })
    );
    this.subscriptions.push(
      this.recipeNodeReTitleService.ActivityTitleChanged.subscribe({
        next: (title) => {
          this.loadMenu();
          const titleUrl = this.generatePrefixedRouteLink(elnEncodeSpecialChars(title), 'Modules');
          this.router.navigateByUrl(`/recipe/${this.recipe.number}_V${this.recipe.version}/${titleUrl}`);
        }
      })
    );

    this.subscriptions.push(this.recipeNodeReOrderService.nodeOrderChanged.subscribe({ next: () => this.loadMenu() }));

    this.subscriptions.push(
      this.recipeService.isRecipeUsersChanged.subscribe({
        next: () => {
          this.allowSaveNewVersion = this.recipeService.currentRecipe.tracking.assignedEditors.some(e => e === this.currentUser.puid) &&
            this.allowedStatesForSaveAsNew.includes(this.recipeService.currentRecipe.tracking.state);
          this.loadMenu();
        }
      })
    );

    this.subscriptions.push(
      this.recipeService.recipeLoaded.subscribe({
        next: () => {
          this.allowSaveNewVersion = this.recipeService.currentRecipe.tracking.assignedEditors.some(e => e === this.currentUser.puid) &&
            this.allowedStatesForSaveAsNew.includes(this.recipeService.currentRecipe.tracking.state);
        }
      })
    );

    this.subscriptions.push(
      this.recipeService.recipeWorkFlowState.subscribe({
        next: () => {
          this.allowSaveNewVersion = this.allowedStatesForSaveAsNew.includes(this.recipeService.currentRecipe.tracking.state) &&
            this.recipeService.currentRecipe.tracking.assignedEditors.some(e => e === this.currentUser.puid);
        }
      })
    );

    this.subscriptions.push(
      this.recipeService.isRecipeNewMinorVersionPresent.subscribe((data) => {
        this.notificationService.PublishRecipeNewMinorVersionAvailability(data);
      })
    );

    this.recipeService.handleRecipeError.subscribe(value => {
      if (value) {
        this.recipeHasErrors = true;
        this.loadMenu();
      }
    })

    this.subscriptions.push(
      this.recipeService.completionStatus$.subscribe(
        (isComplete: boolean) => {
          sessionStorage.removeItem('referenceCompletion');
          this.isReferenceComplete = isComplete;
        }
      )
    );

    this.subscriptions.push(
      this.recipeService.allVersionsOfReferencedRecipesAvailability.subscribe(() => {
        this.setErrorDataForMajorVersionAvailabilityOfReferencedRecipes(this.recipeService.allVersionsOfReferencedRecipes);
        this.setWarningDataForMinorVersionAvailabilityOfReferencedRecipes(this.recipeService.allVersionsOfReferencedRecipes);
      })
    );
  }

  getSources = (_data: RecipeSourcesModel[], recipeRecords: RecipeSourcesModel[]) => {
    this.refrencedRecipeNewDraftVersionGridData = [];
    this.referencedRecipeNewPublishedVersionData = [];
    this.refrencedRecipeDictionary = {};
    this.refrencedRecipeRecords = recipeRecords;
    const referencedRecipes = JSON.parse(JSON.stringify(recipeRecords));
    recipeRecords.forEach((value: RecipeSourcesModel) => {
      if (this.refrencedRecipeDictionary.hasOwnProperty(value.referenceNumber)) {
        this.refrencedRecipeDictionary[value.referenceNumber].push(parseFloat(value.version.substring(1)))
      } else {
        this.refrencedRecipeDictionary[value.referenceNumber] = [parseFloat(value.version.substring(1))];
      }
    });
    const referencedStrings = referencedRecipes.map((obj: RecipeSourcesModel) => obj.referenceNumber);
    this.recipeService.getAllVersions(referencedStrings);
  };

  setWarningDataForMinorVersionAvailabilityOfReferencedRecipes(data: RecipeReferenceResponse[]) {
    data.forEach(value => {
      if (this.otherVersionsOfReferencedRecipesDictionary.hasOwnProperty(value.recipeNumber) && (parseFloat(value.version) % 1 !== 0) && (parseFloat(value.version) > 1)) {
        this.otherVersionsOfReferencedRecipesDictionary[value.recipeNumber].push(value.version)
      } else if ((parseFloat(value.version) % 1 !== 0) && (parseFloat(value.version) > 1)) {
        this.otherVersionsOfReferencedRecipesDictionary[value.recipeNumber] = [value.version];
      }
    });
    this.setWarningDataForMinorVersionAvailability(this.otherVersionsOfReferencedRecipesDictionary);
  }

  setErrorDataForMajorVersionAvailabilityOfReferencedRecipes(data: RecipeReferenceResponse[]) {
    if ((!this.notAllowedWorkflowStatesForShowingBanner.includes(this.recipeService.currentRecipe.tracking.state))) {
      data.forEach(value => {
        if (this.otherMajorVersionsOfReferencedRecipesDictionary.hasOwnProperty(value.recipeNumber) && (parseFloat(value.version) % 1 === 0) && (parseFloat(value.version) > 1)) {
          this.otherMajorVersionsOfReferencedRecipesDictionary[value.recipeNumber].push(value.version);
        } else if ((parseFloat(value.version) % 1 === 0) && (parseFloat(value.version) > 1)) {
          this.otherMajorVersionsOfReferencedRecipesDictionary[value.recipeNumber] = [value.version];
        }
      });
      this.setErrorDataForMajorVersionAvailability(this.otherMajorVersionsOfReferencedRecipesDictionary);
    }
  }

  setWarningDataForMinorVersionAvailability = (dictionary: { [key: string]: string[] }) => {
    Object.keys(dictionary).forEach(key => {
      let checkForDuplicate = true;
      if (key in this.refrencedRecipeDictionary) {
        this.refrencedRecipeDictionary[key].forEach(value => {
          if (value > 0.1 && (value % 1 === 0) && checkForDuplicate) {
            this.refrencedRecipeNewDraftVersionGridData.push({ recipeNumber: key, recipeVersion: `Version - V.${dictionary[key]}` });
            checkForDuplicate = false;
          }
        })
      }
    })

    if (this.refrencedRecipeNewDraftVersionGridData.length > 1 && this.messages.filter(m => m.severity === 'error').length <= 0) {
      this.messages = this.messages.filter(m => m.severity !== 'warn');
      this.showWarningGridForMinorDraftVersions = true;
    } else if (this.refrencedRecipeNewDraftVersionGridData.length === 1) {
      this.setWarningMessage(this.refrencedRecipeNewDraftVersionGridData);
    }
  }

  setErrorDataForMajorVersionAvailability = (dictionary: { [key: string]: string[] }) => {
    let flagToCheck = false;
    Object.keys(this.refrencedRecipeDictionary).forEach((key) => {
      flagToCheck = false;
      if (key in dictionary) {
        this.refrencedRecipeDictionary[key].forEach((referencedValue) => {
          dictionary[key].forEach((value) => {
            if (parseFloat(value) > referencedValue && parseFloat(value) % 1 === 0 && !flagToCheck) {
              this.referencedRecipeNewPublishedVersionData.push({
                recipeNumber: key,
                recipeVersion: `Version - V.${value}`
              });
              flagToCheck = true;
            }
          });
        });
      }
    });

    if (this.messages.filter((m) => m.severity === 'error' && m.id === 'typeChangeError').length === 0) {
      this.referencedRecipeNewPublishedVersionData.forEach((value) => {
        this.hasMajorVersionErrorOfReferencedRecipes = true;
        this.allReferencedRecipesWithMajorVersionAvailable += ` ${value.recipeNumber},`;
      });
      if (this.referencedRecipeNewPublishedVersionData.length > 0) {
        this.recipeService.recipeHasErrors = true;
        this.messages = this.messages.filter((m) => m.severity === 'error');
        this.setMajorVersionAvailableMessage(this.allReferencedRecipesWithMajorVersionAvailable.slice(0, -1));
      }
    }
  };

  private watchForRecipeNewVersionAvailability() {
    this.notificationService.recipeStartNewVersionChange.subscribe((data: RecipeStartNewVersionChange) => {
      this.showWarningGridForMinorDraftVersions = false;
      if (this.recipeService.RecipeSources.some(s => s.referenceId === data.recipeId)) {
        this.hasWarnings = true;
        this.refrencedRecipeNewDraftVersionGridData.push({ recipeNumber: data.number, recipeVersion: `Version - V.${data.version}` });
        if (this.refrencedRecipeNewDraftVersionGridData.length > 1 && this.messages.filter(m => m.severity === 'error').length <= 0) {
          this.messages = this.messages.filter(m => m.severity !== 'warn');
          this.collabratorShowWarningGridForMinorDraftVersions = true;
        } else if (this.refrencedRecipeNewDraftVersionGridData.length === 1) {
          this.setWarningMessage(this.refrencedRecipeNewDraftVersionGridData);
        }
      }
    });
  }

  private watchForRecipePublishedStateAvailability() {
    this.notificationService.recipeMajorVersionAvailabilityError.subscribe(
      (data: RecipePublishChange) => {
        if (
          (!this.notAllowedWorkflowStatesForShowingBanner.includes(this.recipeService.currentRecipe.tracking.state)) &&
          this.recipeService.RecipeSources.some(s => s.referenceNumber && s.referenceNumber === data.number && s.referenceId !== data.recipeId)
        ) {
          this.referencedRecipeNewPublishedVersionData.push({
            recipeNumber: data.number,
            recipeVersion: `Version - V.${data.version}`
          });
          if (this.referencedRecipeNewPublishedVersionData.length > 0 &&
            this.messages.filter(m => m.severity === 'error' && m.id === 'typeChangeError').length === 0 &&
            (!this.allReferencedRecipesWithMajorVersionAvailable.includes(data.number))
          ) {
            this.hasMajorVersionErrorOfReferencedRecipes = true;
            this.allReferencedRecipesWithMajorVersionAvailable += ` ${data.number},`;
            this.setMajorVersionAvailableMessage(this.allReferencedRecipesWithMajorVersionAvailable.slice(0, -1));
            if (this.messages.filter(m => m.severity === 'error').length > 0) {
              this.messages = this.messages.filter(m => m.severity === 'error');
            }
          }
        }
      }
    );
  }

  setMajorVersionAvailableMessage(data: string) {
    this.messages = [];
    this.showWarningGridForMinorDraftVersions = false;
    this.collabratorShowWarningGridForMinorDraftVersions = false;
    this.messages.push({
      severity: 'error',
      id: 'majorVersionAvailableError',
      summary: $localize`:@@errorForMajorVersionAvailability:ERROR :`,
      detail:
        $localize`:@@errorForReferencedMajorVesrionAvailability:Referenced recipe ${data} has new published version. You can remove the current reference and reload latest version.`
    });
    this.setTypeChangeRestrictions();
  }

  setWarningMessage(data: RefrencedRecipeDraftVersion[]) {
    this.messages.push({
      severity: 'warn',
      summary: $localize`:@@informationTitle:Info`,
      detail:
        $localize`:@@ReferencedRecipesNewDraftVersionAvailabityWarning:Referenced recipe ${data[0].recipeNumber} has a new draft ${data[0].recipeVersion} created. You can reload the new version by deleting the published version reference.`
    });
    if (this.messages.filter(m => m.severity === 'error').length > 0) {
      this.messages = this.messages.filter(m => m.severity === 'error');
    }
  }

  onOk(): void {
    this.showWarningGridForMinorDraftVersions = false;
    this.collabratorShowWarningGridForMinorDraftVersions = false;
  }

  nodesToMakeButtonSecondary = ['Prompts', 'References', 'Preparations'];

  get isWorkflowButtonSecondary(): boolean {
    return this.nodesToMakeButtonSecondary.some(nodeName => this.router.url.endsWith(`/${nodeName}`));
  }

  watchForRulesNotification() {
    this.subscriptions.push(
      this.ruleActionNotificationService.SetVariableActionResponse
        .subscribe((data: SetVariableCommand) => this.recipeService.setVariableUsingCommand(data)))
  }

  private watchForMandatoryRecipeFields(): void {
    this.watchForRecipeNameChanged();
    this.watchForRecipeNameNotChanged();
    this.watchForRecipeNameEmpty();
    this.watchRecipeEditorsNotSelected();
    this.watchRecipeEditorsNotChanged();
    this.watchRecipeConsumingLabSitesNotSelected();
    this.watchRecipeConsumingLabSitesNotChanged();
    this.watchRecipeSubBusinessUnitsNotSelected();
    this.watchRecipeSubBusinessUnitsNotChanged();
    this.watchRecipeSubBusinessUnitsChanged();
    this.watchRecipeSubBusinessUnitsNotChanged();
    this.subscriptions.push(this.recipeService.beginEditSpecification
      .subscribe({
        next: (context) => {
          this.specificationEditorContext = context;
        }
      }));
  }

  private watchForRecipeNameEmpty(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeNameEmpty.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = false;
          }
        }
      }))
  }

  private watchForRecipeNameChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeNameChanged.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = true;
          }
        }
      }))
  }

  private watchForRecipeNameNotChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeNameNotChanged.subscribe({
        next: response => {
          if (response) this.enableWorkflowAction = true;
        }
      }));
  }

  private watchRecipeEditorsNotSelected(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeEditorNotSelected.subscribe({
        next: response => {
          if (response) this.enableWorkflowAction = false;
        }
      }));
  }

  private watchForWarnings() {
    this.subscriptions.push(
      this.recipeService.warningReceived.subscribe({
        next: (messages) => {
          this.hasTypeChangeErrors = true;
          this.disabledMessage = $localize`:@@disableMessage:Resolve the error(s) to add new templates/recipes and change workflow`;
          this.messages = [];
          messages.forEach(m => {
            const msgArray = m.split(':');
            this.messages.push({
              severity: 'error',
              id: 'typeChangeError',
              summary: $localize`:@@error:Error`,
              detail:
                $localize`:@@invalidRecipe:Referenced Recipe ${msgArray[0]} type changed from ${msgArray[1]} to ${msgArray[2]}. Kindly delete the recipe reference and reload to continue designing.`
            });
          });
          this.setTypeChangeRestrictions();
        }
      })
    )
  }

  private watchForRecipeTypeChange() {
    this.notificationService.recipeTypeChangeReceiver.subscribe((data: RecipeTypeChange) => {
      if (this.recipeService.RecipeSources.some(s => s.referenceId === data.recipeId)) {
        this.setTypeChangeRestrictions();
        this.messages = this.messages.filter(m => m.severity === 'error' && m.id === 'typeChangeError');
        this.hasTypeChangeErrors = true;
        this.messages.push({
          severity: 'error',
          id: 'typeChangeError',
          summary: $localize`:@@error:Error`,
          detail:
            $localize`:@@invalidRecipe:Referenced Recipe ${data.number} type changed from ${data.oldType} to ${data.newType}. Kindly delete the recipe reference and reload to continue designing.`
        });
      }
    });
  }

  setTypeChangeRestrictions() {
    this.recipeService.recipeHasError(true);
  }

  private watchRecipeEditorsNotChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeEditorNotChanged.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = true;
          }
        }
      }))
  }

  private watchRecipeConsumingLabSitesNotSelected(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeLabSiteNotSelected.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = false;
          }
        }
      }))
  }

  private watchRecipeConsumingLabSitesNotChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeLabSiteNotChanged.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = true;
          }
        }
      }))
  }

  private watchRecipeSubBusinessUnitsNotSelected(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeSubBusinessUnitNotSelected.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = false;
          }
        }
      }))
  }

  private watchRecipeSubBusinessUnitsNotChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeSubBusinessUnitNotChanged.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = true;
          }
        }
      }))
  }

  private watchRecipeSubBusinessUnitsChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeSubBusinessUnitChanged.subscribe({
        next: (response) => {
          if (response) {
            this.enableWorkflowAction = true;
          }
        }
      }))
  }

  private handleRoutes() {
    this.subscriptions.push(
      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationEnd))
        .subscribe(() => {
          this.browserTitle.setTitle(this.recipe.number);
        }))
    this.subscriptions.push(
      this.route.paramMap.subscribe((params) => {
        const recipeLookUp = params.get('recipeNumber')?.split('_');
        const recipeNumber = recipeLookUp !== undefined ? recipeLookUp[0] : '';
        const recipeVersion = recipeLookUp !== undefined ? recipeLookUp[1].substring(1) : '';
        this.browserTitle.setTitle(recipeNumber);
        this.loadRecipe(recipeNumber, recipeVersion);
      }))
  }

  private displayMenuItemsForActivityLevel(activity: Activity, activityMenuItem: MainMenuItem, isRecipeNode: boolean): MenuItem[] {
    const isUserEditor = this.recipeService.currentRecipe.tracking.assignedEditors.includes(
      this.userService.currentUser.puid) && (!this.recipeHasErrors);
    return this.recipeNodeReTitleService.bindRetitleActivityMenuItemContext(activity, activityMenuItem, isRecipeNode, isUserEditor);
  }

  private watchTemplateAppliedNotification() {
    this.templateEventService.TemplateApplied.subscribe((response) => {
      this.recipeService.isRecipeLoaded = false;
      const url = window.location.href;
      if ((([TemplateType.Activity, TemplateType.ActivityGroup].includes(response.command.sourceTemplateType) ||
        response.response.activityReference?.isSynthetic === true)
        && url.indexOf('designer/Prompts') > -1) || (this.recipeService.currentRecipe.activities.length === 0 && response.command.sourceTemplateType === TemplateType.Activity)) {
        this.loadAboutPage = true;
      }
      this.checkRecipeTypeChange(response.command);
      this.reloadRecipe(response, false);
    });
  }

  private watchTemplateDeletedNotification() {
    this.templateEventService.TemplateDeleted.subscribe((response) => {
      this.reloadRecipe(undefined, true);
    });
  }

  private checkRecipeTypeChange(command: RecipeAddTemplateCommand) {
    let typeChanged = false;
    if ([RecipeType.ReferencePromptPreparation].includes(this.recipe.type)) {
      if (command.recipeType !== RecipeType.ReferencePromptPreparation && command.recipeType !== RecipeType.None) {
        typeChanged = true;
      }
    }
    if ([RecipeType.Form, RecipeType.Table, RecipeType.TableForm].includes(this.recipe.type)) {
      if (command.recipeType === RecipeType.Module || command.recipeType === RecipeType.Activity ||
        command.recipeType === RecipeType.ActivityGroup) {
        typeChanged = true;
      }
    }
    if (this.recipe.type === RecipeType.Module && (command.recipeType === RecipeType.Activity ||
      command.recipeType === RecipeType.ActivityGroup)) {
      typeChanged = true;
    }

    if (typeChanged) {
      const recipeTypeChange: RecipeTypeChange = {
        recipeId: this.recipe.recipeId,
        number: this.recipe.number,
        newType: command.recipeType,
        oldType: this.recipe.type
      };
      this.notificationService.publishRecipeTypeChange(recipeTypeChange);
    }
  }

  private reloadRecipe(response: {
    command: RecipeAddTemplateCommand;
    response: RecipeTemplateAddedResponse;
    number: string;
  } | undefined, templateDeletedEvent: boolean): void {
    this.loadRecipe(
      this.recipeService.currentRecipe?.number,
      this.recipeService.currentRecipe.version,
      true,
      templateDeletedEvent,
      response
    );
  }

  specificationValueChanged(newValue: SpecificationValue): void {
    this.specificationEditorContext?.onChange.next(newValue);
  }

  specificationPreLoadScalingOptionChanged(value: SpecificationPreloadScalingOptions) {
    this.specificationEditorContext?.onPreloadScalingOptionsChanged?.next(value);
  }

  closeSpecificationInputSlider(): void {
    this.specificationEditorContext?.onChange.complete();
    this.specificationEditorContext?.onPreloadScalingOptionsChanged?.complete();
    this.specificationEditorContext?.onClose?.next(undefined as never);
    this.specificationEditorContext?.onClose?.complete();
    this.specificationEditorContext = undefined; // destroys slider; ready to create a new one when needed.
  }

  public loadRecipe(
    recipeNumber: string,
    recipeVersion: string,
    notifyActiveTemplate = false,
    deletedTemplate = false,
    appliedTemplate:
      | { command: RecipeAddTemplateCommand, response: RecipeTemplateAddedResponse, number: string }
      | undefined = undefined
  ) {
    this.recipeService
      .loadRecipe(recipeNumber, recipeVersion)
      .pipe(take(1))
      .subscribe(recipe => {
        this.recipe = recipe;
        this.recipeService.isRecipeLoaded = true;
        this.recipeSourcesService.getSources(this.getSources);
        this.setRecipeInitialData();
        if (notifyActiveTemplate && appliedTemplate) {

          this.templateEventService.RecipeHasRefreshed(this.recipe);
          this.showTemplateAppliedSuccessMessage(appliedTemplate);
        }
        if (deletedTemplate) {
          this.templateEventService.RecipeHasRefreshed(this.recipe);
        }
        if (this.loadAboutPage) {
          this.loadAboutPage = false;
          this.router.navigateByUrl(`/recipe/${this.recipe.number}_V${this.recipe.version}/about`);
        }
      });
  }

  public isConfirmationRequired = (insertOption: TemplateLocationOptions): Observable<boolean> => {
    if (insertOption === TemplateLocationOptions.AddAsNewActivity) {
      return of(this.recipe.modules.length > 0);
    }
    if (insertOption === TemplateLocationOptions.AddAsNewModule) {
      return of(this.recipe.tables.length > 0 || this.recipe.forms.length > 0);
    }
    return of(false);
  }

  confirmationFunction(confirmationMessage: string, loadEvent: any, closeEvent: any) {
    this.recipeService.confirmationDialog(confirmationMessage, loadEvent, closeEvent);
  }

  private setRecipeInitialData() {
    this.recipeService.recipe = this.recipe;
    this.recipeVersionDisplayMessage =
      this.recipeVersionDisplayMessageDefault + this.recipe.version;
    this.featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
    this.designerAccessibility = this.clientStateService.getClientStateVisibility(
      ClientState.RECIPE_DESIGNER
    );
    this.populateWorkflow();
    this.showActionLinks();
    this.loadMenu();
    this.recipeService.constructSlider();
    this.searchControls = this.recipeService.searchControls;
    this.loaderSearchCriteria.recipeSearchCriteria = this.recipeService.recipeSearchCriteria;
    this.loaderSearchCriteria.templateSearchCriteria = this.recipeService.templateSearchCriteria;
    this.recipeService.isLoading.next(false);
    this.recipeLoaded = true;

    this.recipeService.isDesignerDataLoaded = true;
    this.recipeService.recipeLoaded.next(true);
  }

  private loadMenu() {
    // Cover is always available, and is shown first
    const aboutItem: MainMenuItem = {
      label: $localize`:@@aboutPageTitle:About`,
      id: 'eln-recipe-menu-about',
      tooltipOptions: {
        tooltipLabel: $localize`:@@aboutPageTitle:About`
      },
      icon: 'pi pi-info-circle',
      routerLink: 'about'
    };
    if (this.recipe) {
      this.menuItems = [aboutItem];
    }
    if (this.recipe.activities && this.recipe.activities.length > 0) {
      const activityItems = this.recipe.activities.map((activity) => {
        const id = `eln-menu-activity-${replace(activity.itemTitle, / /g, '')}`;
        const activityTitleUrl = elnEncodeSpecialChars(activity.itemTitle);
        const activityMenuItem: MainMenuItem = {
          label: activity?.itemTitle ?? '',
          icon: 'far fa-file-alt',
          routerLink: this.generatePrefixedRouteLink(activityTitleUrl, 'Modules'),
          id,
          items: this.prepareSubNodes(activityTitleUrl, id),
          tooltipOptions: {
            tooltipLabel: activity?.itemTitle ?? ''
          }
        } as MainMenuItem;
        activityMenuItem.contextMenuItems = this.displayMenuItemsForActivityLevel(activity, activityMenuItem, (activity as AugmentedActivity).isRecipeNode);
        return activityMenuItem;
      });
      this.menuItems.push(...activityItems);
    } else {
      const id = 'eln-menu-designer';
      const designerPage: MainMenuItem = {
        label: $localize`:@@designerMenu:Designer`,
        id,
        tooltipOptions: {
          tooltipLabel: $localize`:@@designerMenu:Designer`
        },
        icon: 'icon-designer',
        routerLink: 'designer',
      };
      this.menuItems.push(designerPage);
      const menuItemIndex = this.menuItems.findIndex((m) => m.label === designerPage.label);
      this.menuItems[menuItemIndex].items = this.prepareSubNodes('designer', id);
    }
  }

  generatePrefixedRouteLink(prefix: string, key: string): string {
    return prefix ? `${prefix}/${key}` : `${key}`;
  }

  prepareSubNodes(prefix: string, parentId: string): MainMenuItem[] {
    return [
      {
        label: this.designerPageSubItemsLabel.prompts,
        tooltipOptions: {
          tooltipLabel: this.designerPageSubItemsLabel.prompts
        },
        id: `${parentId}-prompts`,
        routerLink: this.generatePrefixedRouteLink(prefix, 'Prompts'),
      },
      {
        label: this.designerPageSubItemsLabel.preparations,
        tooltipOptions: {
          tooltipLabel: this.designerPageSubItemsLabel.preparations
        },
        id: `${parentId}-preparations`,
        routerLink: this.generatePrefixedRouteLink(prefix, 'Preparations'),
      },
      {
        label: this.designerPageSubItemsLabel.parameters,
        tooltipOptions: {
          tooltipLabel: this.designerPageSubItemsLabel.parameters
        },
        id: `${parentId}-parameters`,
        visible: false
      },
      {
        label: this.designerPageSubItemsLabel.references,
        tooltipOptions: {
          tooltipLabel: this.designerPageSubItemsLabel.references
        },
        id: `${parentId}-references`,
        routerLink: this.generatePrefixedRouteLink(prefix, 'References')
      }
    ];
  }

  private showTemplateAppliedSuccessMessage(template: {
    command: RecipeAddTemplateCommand;
    response: RecipeTemplateAddedResponse;
    number: string;
  }) {
    const templateType = this.getKeyByValue(template.command.sourceTemplateType, TemplateType);
    const templateTitle = template.command.templateTitle;
    const templateNumber = template.number;
    this.messageService.add({
      key: 'notification',
      severity: 'success',
      summary: $localize`:@@success:Success`,
      detail: $localize`:@@templateApplySuccess: ${templateType} \: ${templateNumber} \: ${templateTitle} loaded successfully in to the recipe`
    });
  }

  getKeyByValue(value: string, myEnum: any): string | undefined {
    for (const key in myEnum) {
      if (myEnum[key] === value) {
        return key;
      }
    }
    return undefined; // Return undefined if the value is not found
  }

  private watchRecipeUsersChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipeUsersChanged.subscribe({
        next: () => {
          this.enableWorkflowAction = true;
          this.recipe = this.recipeService.currentRecipe;
          if (this.isUserEditorOfRecipe()) {
            this.enableNewVersion = true;
          } else {
            this.enableNewVersion = false;
          }
          this.checkSubmitForApproval();
          this.checkSubmitForVerification();
          this.populateWorkflow();
        }
      }))
  }

  private watchRecipeApprovalRequiredChanged(): void {
    this.subscriptions.push(
      this.recipeService.isApprovalRequiredChanged.subscribe({
        next: (response: boolean) => {
          this.recipe.verificationDetails.isApprovalRequired = response;
          this.populateWorkflow();
        }
      }))
  }

  private watchRecipeLoaded(): void {
    this.subscriptions.push(
      this.recipeService.recipeLoaded.subscribe({
        next: () => {
          this.recipeLoaded = true;
          this.recipe = this.recipeService.currentRecipe;
          RuleActionHandlerHostService.CurrentItemId = this.recipe.recipeId;
          RuleActionHandlerHostService.ItemVariablesNode = (this.recipe
            .variablesNode as VariablesNode) ?? {
            id: '',
            itemType: NodeType.VariablesNode,
            variables: {}
          };
          RuleActionHandlerHostService.ItemType = RuleActionHandlerHostItemType.Recipe;
          this.recipeVersionDisplayMessage =
            this.recipeVersionDisplayMessageDefault + this.recipe.version;
          this.featureFlags = this.clientStateService.getFeatureFlags(this.clientState);
          this.designerAccessibility = this.clientStateService.getClientStateVisibility(
            ClientState.RECIPE_DESIGNER
          );
          this.populateWorkflow();
          this.showActionLinks();
        }
      }),
      this.recipeService.isLoading.subscribe({ next: (flag) => { this.isLoading = flag; } })
    )
  }

  private watchPurposeValuesChanged(): void {
    this.subscriptions.push(
      this.recipeService.isRecipePurposeValuesChanged.subscribe({
        next: () => {
          this.checkSubmitForApproval();
          this.checkForPublish('CanPublish');
          this.populateWorkflow();
        }
      }))
  }

  setVisibility = () => {
    this.recipeService
      .getOtherVersionsOfRecipe(this.recipe.number, this.recipe.version, [])
      .subscribe({
        next: (response) => {
          if (
            this.isFeatureEnabled('StartNewVersion') &&
            [RecipeState.Published, RecipeState.Retired].includes(this.recipe.tracking.state) &&
            (!response ||
              response?.length === 0 ||
              !response.some(
                (rec) =>
                  rec.state &&
                  ['Published', 'Retired'].includes(rec.state) &&
                  Number(rec.version) > Number(this.recipe.version)
              )) && this.isUserEditorOfRecipe()
          ) {
            this.enableNewVersion = true;
          }
        }
      });
  };

  private showActionLinks() {
    this.enableNewRecipeSave = this.isFeatureEnabled('SaveAsNewRecipe');
    this.setVisibility();
  }

  addTemplateEvent() {
    if (this.checkRecipeState()) return;
    this.showTemplateLoader = true;
    this.messages = [];
  }

  copyRecipe() {
    this.copyRecipeDialogRef = this.dialogService.open(CopyRecipeDialogComponent, {
      showHeader: false,
      width: '40rem',
      height: '16rem',
      closeOnEscape: true,
      autoZIndex: true,
      data: {
        name: this.recipe.name
      }
    });
    this.copyRecipeDialogRef.onClose.subscribe((data: any) => {
      if (!data?.recipeName) {
        return;
      }
      this.recipeService.copyRecipe(data.recipeName);
    });
  }

  activitiesReOrdered(event: any) {
    const activity = (this.recipeService.currentRecipe?.activities.find((a) => a.itemTitle === event.item[0].label));
    if (activity) {
      this.recipeNodeReOrderService.changeNodeReOrder(
        NodeType.Activity,
        activity.activityId,
        this.recipeService.currentRecipe.recipeId,
        NodeType.Recipe,
        this.recipeService.currentRecipe?.activities.length - event.newPosition,
        activity.itemTitle
      );
    }
  }

  saveAsNewRecipe() {
    this.recipeService.startNewVersion(this.recipe.number, this.recipe.version);
  }

  private cancelRecipe() {
    const confirmationMessage =
      this.recipe.version === '0.1'
        ? this.confirmationMessage['recipeCancelConfirmationCanRestore']
        : this.confirmationMessage['recipeCancelConfirmationNoRestore'];
    this.recipeService.confirmationDialog(confirmationMessage, this.cancelRecipeCallback);
  }

  isRecipeEmpty = (): boolean => {
    this.recipe = this.recipeService.currentRecipe;
    return this.recipe.type === RecipeType.None &&
      this.recipe.orphan.preparations.length === 0 &&
      this.recipe.orphan.prompts.length === 0 &&
      this.recipe.orphan.references.length === 0;
  };

  private async submitRecipeForVerification() {
    const isRecipeEmpty = this.isRecipeEmpty();
    if (!this.isReferenceComplete || isRecipeEmpty) {
      this.recipeService.createNotificationMessage(
        'Error',
        this.confirmationMessage[isRecipeEmpty ? 'recipeCannotBeEmpty' : 'incompleteReferenceErrorMessage'],
        'error',
        true
      );
      return
    }

    if (await this.validReferencedRecipes(RecipeState.PendingVerification)) {
      this.elnProgressSpinnerService.Hide();
      const confirmationMessage = this.confirmationMessage['recipeSubmitForVerificationConfirmation'];
      this.recipeService.confirmationDialog(
        confirmationMessage,
        this.pendingVerificationRecipeCallback
      );
    } else {
      this.elnProgressSpinnerService.Hide();
    }
  }

  private returnRecipeToDraft() {
    const confirmationMessage = this.isUserApproverOfRecipe()
      ? this.confirmationMessage['recipeReturnToDraftApproverConfirmation']
      : this.confirmationMessage['recipeReturnToDraftConfirmation'];
    this.recipeService.confirmationDialog(confirmationMessage, this.returnRecipeToDraftCallback);
  }

  private async publishRecipe() {
    const isValid = await this.validReferencedRecipes(RecipeState.Published);
    if (!isValid) {
      this.elnProgressSpinnerService.Hide();
      return;
    }
    this.elnProgressSpinnerService.Hide();
    if (this.recipeService.isVerifierAndApproverSameASCurrentUser(this.currentUser.puid)) return;
    this.recipeService.confirmationDialog(this.confirmationMessage['publishRecipeAvailableConfirmation'], () =>
      this.recipeService.reauthenticate(
        this.publishRecipeCallback.bind(this),
        ELNFeatureFlags.EnableRecipeWorkflowReAuth,
        $localize`:@@RecipeStatusTransitionInvalidCredentials:Credentials are incorrect and the status update was not successful`
      )
    );
  }

  private readonly publishRecipeCallback = () => {
    this.recipeService
      .publishRecipe({
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: (response) => {
          this.ngZone.run(async () => {
            this.recipe = this.recipeService.currentRecipe;
            await this.notificationService.PublishRecipeNewMajorVersionAvailability(
              { recipeId: this.recipeService.currentRecipeId, number: response.recipeNumber, state: RecipeState.Published, version: response.version }
            );
            this.populateWorkflow();
            this.recipeService.reloadWithRecipeVersion(response.recipeNumber, response.version);
            this.recipeService.recipeWorkFlowState.next();
          });
        }
      });
  };

  private async submitRecipeForApproval() {
    if (await this.validReferencedRecipes(RecipeState.PendingApproval)) {
      this.elnProgressSpinnerService.Hide();
      const confirmationMessage = this.confirmationMessage['recipeSubmitForApprovalConfirmation'];
      this.recipeService.confirmationDialog(confirmationMessage, this.pendingApprovalRecipeCallback);
      if (!this.recipe.verificationDetails.isVerified) {
        const confirmationMessage =
          this.confirmationMessage['recipeSubmitForApprovalWhenUnVerifiedConfirmation'];

        this.recipeService.confirmationDialog(confirmationMessage, () =>
          this.recipeService.reauthenticate(
            this.pendingVerificationRecipeCallbackWhenIsVerifiedIsFalse.bind(this),
            ELNFeatureFlags.EnableRecipeWorkflowReAuth,
            $localize`:@@RecipeVerificationInvalidCredentials:Credentials are incorrect and the verification was not successful`
          )
        );
      }
    } else {
      this.elnProgressSpinnerService.Hide();
    }

  }

  private retireRecipe() {
    const confirmationMessage = this.confirmationMessage['recipeRetireConfirmation'];
    this.recipeService.confirmationDialog(confirmationMessage, this.retireRecipeCallback);
  }

  private readonly pendingApprovalRecipeCallback = () => {
    if (this.recipe.verificationDetails.isVerified) {
      this.recipeService
        .pendingApprovalRecipe({
          recipeId: this.recipeService.currentRecipeId
        })
        .subscribe({
          next: () => {
            this.ngZone.run(() => {
              this.recipe = this.recipeService.currentRecipe;
              this.populateWorkflow();
              this.recipeService.recipeWorkFlowState.next();
            });
          }
        });
    }
  };

  private readonly pendingVerificationRecipeCallbackWhenIsVerifiedIsFalse = () => {
    this.recipeService
      .changeRecipeVerified({
        isVerified: true,
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: () => {
          this.recipe = this.recipeService.currentRecipe;
          this.populateWorkflow();
          this.pendingApprovalRecipeCallback();
          this.recipeService.recipeWorkFlowState.next();
        }
      });
  };

  private readonly pendingVerificationRecipeCallback = () => {
    const stepColumnFields = Object.assign({}, ...this.recipeService.getAllTables()
      .filter(t => t.columnDefinitions.some(c => c.columnType === ColumnType.StepNumber))
      .map(t => ({ [t.tableId]: t.columnDefinitions.find(c => c.columnType === ColumnType.StepNumber)?.field }))
    );
    this.recipeService
      .pendingVerificationRecipe({
        recipeId: this.recipeService.currentRecipeId,
        stepColumnFields,
      })
      .subscribe({
        next: () => {
          this.recipe = this.recipeService.currentRecipe;
          this.populateWorkflow();
          this.recipeService.recipeWorkFlowState.next();
        }
      });
  };

  private readonly cancelRecipeCallback = () => {
    this.recipeService
      .cancelRecipe({
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: () => {
          this.recipe = this.recipeService.currentRecipe;
          this.populateWorkflow();
          this.recipeService.recipeWorkFlowState.next();
        }
      });
  };

  private readonly retireRecipeCallback = () => {
    this.recipeService
      .retireRecipe({
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: () => {
          this.recipe = this.recipeService.currentRecipe;
          this.populateWorkflow();
          this.recipeService.recipeWorkFlowState.next();
        }
      });
  };

  private readonly returnRecipeToDraftCallback = () => {
    this.recipeService
      .returnRecipeToInDraft({
        recipeId: this.recipeService.currentRecipeId
      })
      .subscribe({
        next: () => {
          this.recipe = this.recipeService.currentRecipe;
          this.populateWorkflow();
          this.recipeService.recipeWorkFlowState.next();
        }
      });
  };

  private restoreRecipe(): void {
    this.recipeService.confirmationDialog(
      this.confirmationMessage['recipeRestoreConfirmation'],
      this.restoreRecipeCallback
    );
  }

  private readonly restoreRecipeCallback = () => {
    this.recipeService
      .restoreRecipe({
        recipeId: this.recipeService.currentRecipeId,
        isRestored: false
      })
      .subscribe({
        next: () => {
          this.recipe = this.recipeService.currentRecipe;
          this.populateWorkflow();
          this.recipeService.recipeWorkFlowState.next();
        }
      });
  };

  checkRecipeState(): boolean {
    return !this.isRecipeInDraft()
      || this.designerAccessibility !== 'ReadWrite'
      || !this.isUserEditorOfRecipe()
      || this.hasTypeChangeErrors
      || this.hasMajorVersionErrorOfReferencedRecipes;
  }

  //Role and Permission Authorization
  private populateWorkflow() {
    this.WorkflowActionsLabel = this.populateWorkflowDisplayLabel();
    this.workflowActions = [];
    switch (this.recipe.tracking.state) {
      case RecipeState.Draft as RecipeState:
        this.checkDraftState();
        this.checkSubmitForVerification();
        break;
      case RecipeState.Cancelled:
        this.checkRestoreFromCancelled();
        break;
      case RecipeState.PendingApproval:
        this.checkReturnToDraft('CanReturnToDraftFromPendingApproval');
        this.checkForPublish('CanPublish');
        break;
      case RecipeState.PendingVerification:
        this.checkReturnToDraft('CanReturnToDraft');
        if (this.recipe.verificationDetails.isApprovalRequired) this.checkSubmitForApproval();
        else this.checkForPublish('CanPublishFromPendingVerification');
        break;
      case RecipeState.Published:
        this.checkForRetire();
        break;
      case RecipeState.Retired:
        break;
      default:
        break;
    }
  }

  private populateWorkflowDisplayLabel(): string {
    return RecipeState.Draft === this.recipe.tracking.state
      ? recipeStateLabel.inDraftLabel
      : recipeStateLabel[this.recipe.tracking.state];
  }

  private isFeatureEnabled(featureName: string) {
    return this.featureFlags.some(data => JSON.parse(data)[featureName] === true);
  }

  /**
   * Checks before draft to cancelled state transition.
   */
  private checkDraftState(): any {
    const cancelDraftLabel = $localize`:@@cancelDraft:Cancel draft`;
    const cancelVersionLabel = $localize`:@@cancelVersion:Cancel version`;
    const cancelLabel = this.recipe.version === '0.1' ? cancelDraftLabel : cancelVersionLabel;

    const canCancelDraft = this.isFeatureEnabled('CanCancelDraft') && this.isUserEditorOfRecipe();
    if (canCancelDraft) {
      this.constructRecipeWorkflow('cancelDraft', cancelLabel, 'icon-edit_off', () =>
        this.cancelRecipe()
      );
    }
  }

  /**
   * Checks before draft to PendingVerification state transition.
   */
  private checkSubmitForVerification(): any {
    const label = $localize`:@@submitForVerification:Submit for verification`;
    const canSubmitForVerification =
      this.isFeatureEnabled('CanSubmitForVerification') &&
      this.isUserEditorOfRecipe() &&

      this.recipe.tracking.state !== RecipeState.PendingVerification &&
      this.recipe.tracking.state !== RecipeState.PendingApproval;
    if (canSubmitForVerification) {
      if (!this.workflowActions.some(value => value.label === label)) {
        this.constructRecipeWorkflow(
          'submitForVerification',
          label,
          'icon-submit_verification',
          () => this.submitRecipeForVerification()
        );
      }
    } else {
      this.workflowActions = this.workflowActions.filter(m => m.label !== label);
    }
  }

  stateCounts: Record<string, number> = {};

  validReferencedRecipes(targetState: RecipeState): Promise<boolean> {
    this.elnProgressSpinnerService.Show({
      message: $localize`:@@validating:Validating...`,
      i18nMessage: '@@validating'
    })
    return new Promise<boolean>((resolve) => {
      this.recipeSourcesService.getSources((data, _recipeRecords) => {
        this.stateCounts = {};
        this.countRecipeStates(data, targetState);
        const isValid = this.evaluateStatesAndShowMessage(targetState);
        resolve(isValid);
      });
    });
  }

  countRecipeStates(data: RecipeSourcesModel[], targetState: RecipeState) {
    const filterStates = this.getFilterStatesForTargetState(targetState);
    data.forEach(recipe => {
      if (filterStates.includes(recipe.workflowState)) {
        this.stateCounts[recipe.workflowState] = (this.stateCounts[recipe.workflowState] || 0) + 1;
      }
    });
  }

  private getFilterStatesForTargetState(targetState: RecipeState): string[] {
    const stateFilterMapping: StateFilterMapping = {
      [RecipeState.PendingVerification]: ['Retired', 'Draft', 'Cancelled', 'PendingVerification'],
      [RecipeState.PendingApproval]: ['Retired', 'Draft', 'Cancelled', 'PendingVerification'],
      [RecipeState.Published]: ['Retired', 'Draft', 'Cancelled', 'PendingVerification', 'PendingApproval'],
    };
    return stateFilterMapping[targetState] || [];
  }

  private evaluateStatesAndShowMessage(targetState: RecipeState): boolean {
    const totalCount = Object.values(this.stateCounts).reduce((acc, val) => acc + val, 0);
    if (totalCount > 0) {
      const errorMessageKey = this.getErrorMessageKeyForState(targetState);
      if (errorMessageKey) {
        this.recipeService.createNotificationMessage(
          'Error',
          this.confirmationMessage[errorMessageKey],
          'error',
          true
        );
        return false;
      }
    }
    return true;
  }

  private getErrorMessageKeyForState(targetState: RecipeState): string | null {
    const uniqueStates = new Set(
      Object.keys(this.stateCounts)
        .map(state => RecipeState[state as keyof typeof RecipeState])
        .filter(state => state !== undefined)
    );

    const stateToErrorMessageKey: { [key: string]: any } = {
      [RecipeState.Draft]: {
        [RecipeState.PendingVerification]: 'draftReferencedRecipeErrorMessage',
        [RecipeState.PendingApproval]: 'draftReferencedRecipeErrorMessageForApproval',
        [RecipeState.Published]: 'referencedRecipeDraftErrorMessageForPublish',
      },
      [RecipeState.Cancelled]: {
        [RecipeState.PendingVerification]: 'cancelledReferencedRecipeErrorMessage',
        [RecipeState.PendingApproval]: 'cancelledReferencedRecipeErrorMessageForApproval',
        [RecipeState.Published]: 'referencedRecipeCancelledErrorMessageForPublish',
      },
      [RecipeState.Retired]: {
        [RecipeState.PendingVerification]: 'retiredReferencedRecipeErrorMessage',
        [RecipeState.PendingApproval]: 'retiredReferencedRecipeErrorMessageForApproval',
        [RecipeState.Published]: 'referencedRecipeRetiredErrorMessageForPublish',
      },
      [RecipeState.PendingVerification]: {
        [RecipeState.PendingApproval]: 'pendingVerificationReferencedRecipeErrorMessageForApproval',
        [RecipeState.Published]: 'referencedRecipePendingVerificationErrorMessageForPublish',
      },
      [RecipeState.PendingApproval]: {
        [RecipeState.Published]: 'referencedRecipePendingApprovalErrorMessageForPublish',
      },
    };

    if (uniqueStates.size === 1) {
      const uniqueState = uniqueStates.values().next().value;
      return stateToErrorMessageKey[uniqueState]?.[targetState] || null;
    }

    if (targetState === RecipeState.Published) {
      if (uniqueStates.has(RecipeState.Retired) && uniqueStates.has(RecipeState.Cancelled) && uniqueStates.size === 2) {
        return 'referencedRecipeRetiredAndCancelledErrorMessageForPublish';
      }
      return 'referencedRecipeAnyCombinationErrorMessageForPublish';
    }

    switch (targetState) {
      case RecipeState.PendingVerification:
        return 'referencedRecipeGenericErrorMessage';
      case RecipeState.PendingApproval:
        return 'referencedRecipeGenericErrorMessageForApproval';
    }

    return null;
  }

  /**
   * Checks before cancelled to draft state transition
   */
  private checkRestoreFromCancelled() {
    const canRestoreFromCancelled =
      this.isFeatureEnabled('CanRestoreFromCancelled') && this.recipe.version === '0.1' && this.isUserEditorOfRecipe();
    if (canRestoreFromCancelled) {
      const label = $localize`:@@RestoreFromCancelled:Restore from cancelled`;
      this.constructRecipeWorkflow('restoredFromCancelled', label, 'far icon-restore_page', () =>
        this.restoreRecipe()
      );
    }
  }

  isUserVerifierOfRecipe(): boolean {
    return this.recipe.tracking.assignedVerifiers.includes(this.currentUser.puid);
  }

  isUserEditorOfRecipe(): boolean {
    return this.recipe.tracking.assignedEditors.includes(this.currentUser.puid);
  }

  isUserApproverOfRecipe(): boolean {
    return this.recipe.tracking.assignedApprovers.includes(this.currentUser.puid);
  }

  isRecipeInDraft(): boolean {
    return (this.recipe && this.recipe.tracking.state === RecipeState.Draft);
  }

  /**
   * Checks before PendingVerification/PendingApproval to Draft state transition.
   * @param key Feature flag
   */
  private checkReturnToDraft(key: string) {
    const canReturnToDraft =
      this.isFeatureEnabled(key) &&
      (this.isUserVerifierOfRecipe() ||
        (this.recipe.tracking.state === RecipeState.PendingVerification && this.isUserEditorOfRecipe()) ||
        (this.recipe.tracking.state === RecipeState.PendingApproval && this.isUserApproverOfRecipe()));
    if (canReturnToDraft) {
      const label = $localize`:@@backToInDraft:Return to in draft`;
      this.constructRecipeWorkflow('backToInDraft', label, 'icon-draft', () =>
        this.returnRecipeToDraft()
      );
    }
  }

  /**
   * Checks before PendingVerification/pendingApproval to Publish state transition
   * @param key Feature flag
   */
  private checkForPublish(key: string) {
    const canPublish = this.isFeatureEnabled(key) &&
      ((this.recipe.tracking.state === RecipeState.PendingVerification && this.isUserVerifierOfRecipe()) ||
        (this.recipe.tracking.state === RecipeState.PendingApproval && this.isUserApproverOfRecipe()))
      &&
      this.recipe.verificationDetails.purpose.trim() !== '' &&
      this.recipe.verificationDetails.isRecipeSatisfiesItsPurpose &&
      this.recipe.verificationDetails.isConsideredReviewed;
    if (canPublish) {
      const label = $localize`:@@publish:Publish`;
      this.constructRecipeWorkflow('publish', label, 'icon-publish', () => this.publishRecipe());
    }
  }

  /**
   * Checks before PendingVerification to pendingApproval state transition.
   */
  private checkSubmitForApproval() {
    const label = $localize`:@@submitForApproval:Submit for approval`;
    const canSubmitForApproval =
      this.isFeatureEnabled('CanSubmitForApproval') &&
      this.isUserVerifierOfRecipe() &&
      this.recipe.verificationDetails.isApprovalRequired &&
      this.recipe.tracking.state !== RecipeState.PendingApproval &&
      this.recipe.verificationDetails.purpose.trim() !== '' &&
      this.recipe.verificationDetails.isRecipeSatisfiesItsPurpose &&
      this.recipe.verificationDetails.isConsideredReviewed;
    if (canSubmitForApproval) {
      if (!this.workflowActions.some((value) => value.label === label)) {
        this.constructRecipeWorkflow('submitForApproval', label, 'icon-task-check', () =>
          this.submitRecipeForApproval()
        );
      }
    } else {
      this.workflowActions = this.workflowActions.filter((m) => m.label !== label);
    }
  }

  /**
   * Checks before Published to Retired state transition.
   * Note: This can also happen automatically from backend when new version is published
   */
  private checkForRetire() {
    const canRetire = this.isFeatureEnabled('CanRetire') && this.isUserApproverOfRecipe();
    if (canRetire) {
      const label = $localize`:@@Retire:Retire`;
      this.constructRecipeWorkflow('retire', label, 'icon-done_all', () => this.retireRecipe());
    }
  }

  private constructRecipeWorkflow(
    id: string,
    label: string,
    iconClass: string,
    callback: () => void
  ) {
    this.workflowActions.push({
      id: `eln-recipe-${id}`,
      label,
      icon: iconClass,
      disabled: false,
      command: callback
    });
  }

  updateTemplateSelection(_templateLoaderResult: TemplateLoaderResult<SelectedTemplate>): void {
    this.showTemplateLoader = false;
  }

  templateSelectionChanged(_templateLoaderResult: SelectedTemplate): void {
    this.recipeService.selectionChanged(_templateLoaderResult);
  }

  applySelectedTemplate(selectedTemplateInformation: SelectedTemplateCommand) {
    this.recipeService.applySelectedTemplate(selectedTemplateInformation);
  }

  templateLoaderInsertOptionChanged(insertOption: string) {
    this.recipeService.refreshInsertLocationOptionsAndSetDefaultLocation(insertOption as TemplateLocationOptions);
    this.searchControls = this.recipeService.constructFilters(insertOption);
    this.recipeService.resetRecipeAndTemplateCommand(insertOption);
    this.loaderSearchCriteria.templateSearchCriteria = this.recipeService.templateSearchCriteria;
    this.loaderSearchCriteria.recipeSearchCriteria = this.recipeService.recipeSearchCriteria;
  }

  templateLoaderFilterChanged(filterData: TemplateLoaderFilterChangedEvent) {
    this.searchControls = this.recipeService.alterFilters(
      filterData._filters,
      filterData.selectedInsertOption,
      filterData.fieldName
    );
  }

  templateLoaderFilterCleared(insertLocationOptions: string) {
    this.searchControls = this.recipeService.constructFilters(insertLocationOptions);
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }
}
