import { Component, OnDestroy, OnInit } from '@angular/core';
import { User as UserInterface } from 'model/user.interface';
import { RecipeService } from '../services/recipe.service';
import { UserService } from '../../services/user.service';
import { Subject, Subscription } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { ClientStateService } from '../../services/client-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PromptCommand, PromptModel, RecipeModel } from '../model/recipe';
import {
  ChangeRecipeTypeCommand,
  RecipeAddColumnPromptCommand,
  RecipeAddConsumableSupplyPromptCommand,
  RecipeAddInstrumentPromptCommand,
  RecipeAddMaterialPromptCommand,
  RecipeAddPreparationPromptCommand,
  RecipeColumnPromptAddedResponse,
  RecipeConsumableSupplyPromptAddedResponse,
  RecipeEditPromptCommand,
  RecipeInstrumentPromptAddedResponse,
  RecipeMaterialPromptAddedResponse,
  RecipePreparationPromptAddedResponse,
  RecipePromptEditResponse,
  RecipePromptRemovedResponse,
  RecipePromptRestoredResponse,
  RecipeRemovePromptCommand,
  RecipeRestorePromptCommand,
  RecipeState,
  RecipeType
} from '../../api/cookbook/models';
import { PromptData, PromptItem, PromptItemColumn, PromptItemConsumableOrSupply, PromptItemInstrument, PromptItemMaterial, PromptItemPreparation, PromptItemValueType, PromptType } from '../../prompt/models/prompt.model';
import { RecipePromptService } from '../services/recipe-prompt.service';
import { elnDecodeSpecialChars } from '../../shared/url-path-serializer';
import { DialogConfig, ModalButton } from './custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-recipe-prompts',
  templateUrl: './recipe-prompts.component.html',
})
export class RecipePromptsComponent extends BaseComponent implements OnInit, OnDestroy {
  currentUser: UserInterface;
  private readonly subscriptions: Subscription[] = [];
  recipe!: RecipeModel
  promptData: Array<PromptData> = [];
  isPromptLoaded = false;
  isUserAllowedToEdit = false;
  savePromptNotifier: Subject<boolean> = new Subject<boolean>();
  editPromptNotifier: Subject<boolean> = new Subject<boolean>();
  recipeTypeChanged = true;
  promptsReadOnly = false;

  displayModal = false;
  modalButtons: ModalButton[] = [
    {
      label: 'Ignore',
      styleClass: 'p-button-outlined bpt-button-compact',
      command: () => this.onIgnore()
    },
    {
      label: 'Load Prompts',
      styleClass: 'bpt-button-compact',
      command: () => this.onLoadPrompts()
    }
  ];

  header = $localize`:@@duplicatePromptsHeader:Duplicate Prompts`;
  subHeader =
    $localize`:@@duplicatePromptsSubHeader:The recipe you are loading contains the following prompts that already exist in current recipe. Select any prompts to duplicate anyway`;

  /**
  * Contains additional hardcoded items to test the scrollbar behavior in the UI.
  * These are placeholder values and will be replaced with dynamic content
  * as specified in PBI 3240181.
  *
  * @type {{ label: string, value: boolean }[]}
  */
  checkboxItems: { label: string, value: boolean }[] = [
    { label: 'Duplicate Prompt 1', value: false },
    { label: 'Duplicate Prompt 2', value: false },
    { label: 'Duplicate Prompt 3', value: false },
    { label: 'Duplicate Prompt 4', value: false },
    { label: 'Duplicate Prompt 5', value: false },
    { label: 'Duplicate Prompt 6', value: false },
    { label: 'Duplicate Prompt 7', value: false },
  ];

  dialogConfig: DialogConfig = {
    headerAlignment: 'center',
    iconColor: '#f5822b'
  };

  constructor(
    private readonly userService: UserService,
    private readonly recipeService: RecipeService,
    clientStateService: ClientStateService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly recipePromptService: RecipePromptService
  ) {
    super(clientStateService, route);
    this.currentUser = { ...this.userService.currentUser };
  }

  ngOnInit(): void {
    this.watchForActivityTitleDeterminationThroughRoute(this.route);
    this.addSubscriptions();
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  get routeIsPromptsComponent(): boolean {
    return this.router.url.endsWith('/Prompts');
  }

  get currentActivity() {
    return this.recipe.activities.find(a => a.activityId === this.recipeService.currentActivityId)
  }

  onIgnore() {
    this.displayModal = false;
  }

  onLoadPrompts() {
    const selectedItems = this.checkboxItems.filter(item => item.value);
  }

  private addSubscriptions() {
    this.subscriptions.splice(0, 0,...[
      this.recipeService.isRecipeUsersChanged.subscribe({
        next: (flag: boolean) => {
          if (flag) {
            this.loadRecipeData();
          }
        }
      }),
      this.recipeService.recipeLoaded.subscribe({
        next: (flag: boolean) => {
          if (flag) {
            this.loadRecipeData();
          }
        }
      }),
      this.recipeService.handleRecipeError.subscribe(value => {
        [this.recipeTypeChanged, this.promptsReadOnly] = [!value, value];
      })
    ]
    );
  }

  /*
   * This Method will be triggered when there is change in route, basically written to
   * handle when two different activities contain preparations and preparations should change when route changes.
   */
  private watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    this.subscriptions.push(route.params.subscribe((params) => {
      if (params['itemTitle']) {
        const currentActivityTitle = elnDecodeSpecialChars(params['itemTitle']);
        const activity = this.recipeService.currentRecipe.activities.find(
          (activity) => activity.itemTitle === currentActivityTitle
        );
        if (activity) {
          this.recipeService.currentActivityId = activity.activityId;
          this.recipeService.currentActivityTitle = activity.itemTitle;
        }
      }
      this.loadRecipeData();
    }));
  }

  private loadRecipeData(): void {
    this.recipe = this.recipeService.currentRecipe;
    this.buildPromptData();
    this.isPromptLoaded = true;
  }

  private buildPromptData() {
    this.promptData = [];
    this.processPromptData(this.recipe.activities.length > 0 ?
      (this.currentActivity?.prompts ?? [])
      : (this.recipe.orphan?.prompts ?? []));
  }

  private processPromptData(promptModels: PromptModel[]) {
    if (promptModels.length === 0) return;
    promptModels.forEach((item) => {
      const variable = this.promptData.find(prompt => prompt.type === item.promptType);
      if (variable) {
        variable.data.push(...item.promptItems);
      } else {
        this.promptData.push({
          type: item.promptType,
          data: item.promptItems
        });
      }
    });
  }

  isUserEditorOfTheRecipe(): boolean {
    if (this.recipe?.tracking.assignedEditors.includes(this.currentUser.puid)) {
      return true;
    }
    return false;
  }

  isRecipeInDraft(): boolean {
    return (this.recipe && this.recipe.tracking.state === RecipeState.Draft);
  }

  submitPromptData (data: PromptItem<PromptType, PromptItemValueType>) {
    switch (data.type) {
      case PromptType.Columns: {
        const columnCommand = this.recipePromptService.constructColumnCommand(data as PromptItem<PromptType, PromptItemColumn>, this.recipe.recipeId, this.recipeService.currentActivityId,
          this.recipeService.currentActivityTitle);
        this.publishColumnPromptCommand(columnCommand, PromptType.Columns);
        break;
      }
      case PromptType.ConsumablesAndSupplies:
        {
          const consumableSupplyCommand = this.recipePromptService.constructConsumableSupplyCommand(data as PromptItem<PromptType, PromptItemConsumableOrSupply>, this.recipe.recipeId, this.recipeService.currentActivityId,
            this.recipeService.currentActivityTitle);
          this.publishConsumableSupplyPromptCommand(consumableSupplyCommand, PromptType.ConsumablesAndSupplies);
          break;
        }
      case PromptType.Materials:
        {
          const materialCommand = this.recipePromptService.constructMaterialCommand(data as PromptItem<PromptType, PromptItemMaterial>, this.recipe.recipeId, this.recipeService.currentActivityId,
            this.recipeService.currentActivityTitle);
          this.publishMaterialPromptCommand(materialCommand, PromptType.Materials);
          break;
        }
      case PromptType.Instruments:
        {
          const instrumentCommand = this.recipePromptService.constructInstrumentCommand(data as PromptItem<PromptType, PromptItemInstrument>, this.recipe.recipeId, this.recipeService.currentActivityId,
            this.recipeService.currentActivityTitle);
          this.publishInstrumentPromptCommand(instrumentCommand, PromptType.Instruments);
          break;
        }
      case PromptType.Preparations:
        {
          const preparationCommand = this.recipePromptService.constructPreparationCommand(data as PromptItem<PromptType, PromptItemPreparation>, this.recipe.recipeId, this.recipeService.currentActivityId,
            this.recipeService.currentActivityTitle);
          this.publishPreparationPromptCommand(preparationCommand, PromptType.Preparations);
          break;
        }
      default:
        break;
    }
  }

  editPromptCellChangedData(data: { type: PromptType, promptId: string, editedFields: {[key: string]: string}}) {
      const promptEditCommand = this.recipePromptService.editPromptCommand(data.type, data.promptId, data.editedFields, this.recipe.recipeId, this.recipeService.currentActivityId);
      this.publishEditPromptCommand(promptEditCommand);
  }

  removePromptData(data: { type: PromptType, promptId: string}) {
    const promptRemoveCommand = this.recipePromptService.removePromptCommand(data.type, data.promptId, this.recipe.recipeId, this.recipeService.currentActivityId);
    this.publishRemovePromptCommand(promptRemoveCommand);
  }

  restorePromptData(data: { type: PromptType, promptId: string }) {
    const command = this.recipePromptService.restorePromptCommand(data.type, data.promptId, this.recipe.recipeId, this.recipeService.currentActivityId);
    this.publishRestorePromptCommand(command);
  }

  publishEditPromptCommand(command: RecipeEditPromptCommand) {
    this.recipePromptService.publishEditPromptCommand(command).subscribe({
      next: (response: RecipePromptEditResponse) => {
        this.updatePrompt(command, response.promptId, command.type);
        this.editPromptNotifier.next(true);
        this.recipePromptService.gridCellUpdate.next();
      },
      error: () => {
        this.editPromptNotifier.next(false);
      }
    });
  }

  publishRemovePromptCommand(command: RecipeRemovePromptCommand) {
    this.recipePromptService.publishRemovePromptCommand(command).subscribe({
      next: (_response: RecipePromptRemovedResponse) => {
        this.updatePromptRemovedStatus(true, command.type, command.promptId);
      },
      error: () => {
        this.updatePromptRemovedStatus(false, command.type, command.promptId);
      }
    });
  }

  publishRestorePromptCommand(command: RecipeRestorePromptCommand) {
    this.recipePromptService.publishRestorePromptCommand(command).subscribe({
      next: (_response: RecipePromptRestoredResponse) => {
        this.updatePromptRemovedStatus(false, command.type, command.promptId);
      },
      error: () => {
        this.updatePromptRemovedStatus(true, command.type, command.promptId);
      }
    });
  }

  updatePromptRemovedStatus(isPromptRemoved: boolean, type : PromptType, promptId: string) {
    const promptRow = this.activityOrOrphanPrompts()
    .find(prompt => prompt.promptType === type)?.promptItems
    .find(values => values.promptId === promptId)
    if (!promptRow) return;
    promptRow.isPromptRemoved = isPromptRemoved;
  }

  publishMaterialPromptCommand(command: RecipeAddMaterialPromptCommand, type: PromptType) {
    this.recipePromptService.publishMaterialPromptCommand(command).subscribe({
      next: (response: RecipeMaterialPromptAddedResponse) => {
        this.addNewPrompt(command, response.promptId, type);
      },
      error: () => {
        this.savePromptNotifier.next(false);
      }
    });
  }

  publishColumnPromptCommand(command: RecipeAddColumnPromptCommand, type: PromptType) {
    this.recipePromptService.publishColumnPromptCommand(command).subscribe({
      next: (response: RecipeColumnPromptAddedResponse) => {
        this.addNewPrompt(command, response.promptId, type);
      },
      error: () => {
        this.savePromptNotifier.next(false);
      }
    });
  }

  publishConsumableSupplyPromptCommand(command: RecipeAddConsumableSupplyPromptCommand, type: PromptType) {
    this.recipePromptService.publishConsumableSupplyPromptCommand(command).subscribe({
      next: (response: RecipeConsumableSupplyPromptAddedResponse) => {
        this.addNewPrompt(command, response.promptId, type);
      },
      error: () => {
        this.savePromptNotifier.next(false);
      }
    });
  }

  publishPreparationPromptCommand(command: RecipeAddPreparationPromptCommand, type: PromptType) {
    this.recipePromptService.publishPreparationPromptCommand(command).subscribe({
      next: (response: RecipePreparationPromptAddedResponse) => {
        this.addNewPrompt(command, response.promptId, type);
      },
      error: () => {
        this.savePromptNotifier.next(false);
      }
    });
  }

  publishInstrumentPromptCommand(command: RecipeAddInstrumentPromptCommand, type: PromptType) {
    this.recipePromptService.publishInstrumentPromptCommand(command).subscribe({
      next: (response: RecipeInstrumentPromptAddedResponse) => {
        this.addNewPrompt(command, response.promptId, type);
      },
      error: () => {
        this.savePromptNotifier.next(false);
      }
    });
  }

  updatePrompt(command: RecipeEditPromptCommand, promptId: string, type: PromptType) {
      const prompts = this.activityOrOrphanPrompts();
      const index = prompts.findIndex(p => p.promptType === type);
      prompts[index].promptItems.forEach(values => {
        if (values.promptId === promptId && command.editedFields) {
          Object.assign(values, command.editedFields);
        }
      })
  }

  private activityOrOrphanPrompts(): PromptModel[] {
    var prompts: PromptModel[] = [];
    if (this.currentActivity?.prompts && this.currentActivity?.prompts.length > 0) {
      prompts = this.currentActivity?.prompts
    } else if (this.recipe.orphan?.prompts && this.recipe.orphan.prompts.length > 0) {
      prompts = this.recipe.orphan?.prompts
    }
    return prompts;
  }

  addNewPrompt(data: PromptCommand, promptId: string, type: PromptType) {
    data.promptId = promptId;
    if (this.recipeService.currentActivityId) {
      const currentActivity = this.currentActivity;
      if (currentActivity?.prompts && currentActivity?.prompts.length > 0) {
        const index = currentActivity.prompts.findIndex(p => p.promptType === type);
        if (index > -1) {
          currentActivity.prompts[index].promptItems.push(data);
        }
        else {
          currentActivity.prompts?.push({
            promptType: type,
            promptItems: [data]
          });
        }
      } else if (currentActivity) {
        currentActivity.prompts = [{
          promptType: type,
          promptItems: [data]
        }]
      }
    } else {
      this.addPromptToRoot(data, type);
    }
    this.buildPromptData();
    this.savePromptNotifier.next(true);
  }

  addPromptToRoot(data: PromptCommand, type: PromptType) {
     //update recipe type
     if (this.recipe.type === RecipeType.None) {
      var changeRecipeTypeCommand = {
        recipeId: this.recipeService.recipe.recipeId,
        recipeType: RecipeType.ReferencePromptPreparation
      } as ChangeRecipeTypeCommand
      this.recipeService.changeType(changeRecipeTypeCommand).subscribe((type) =>{
        this.recipe.type = type;
      });
    }
    if (this.recipe.orphan?.prompts && this.recipe.orphan.prompts.length > 0) {
      const index = this.recipe.orphan.prompts.findIndex(p => p.promptType === type);
      if (index > -1) {
        this.recipe.orphan.prompts[index].promptItems.push(data);
      }
      else {
        this.recipe.orphan.prompts.push({
          promptType: type,
          promptItems: [data]
        });
      }
    } else {
      if (this.recipe.orphan) {
        this.recipe.orphan.prompts = [{
          promptType: type,
          promptItems: [data]
        }];
      }
    }
  }
}