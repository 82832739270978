import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ExperimentService } from '../../../services/experiment.service';
import { PromptItem } from '../../../../api/models';
import { PromptsService } from '../../prompts.service';
import { BptDropdownComponent, DropdownChangeEvent } from 'bpt-ui-library/bpt-dropdown';
import { NA } from 'bpt-ui-library/shared';
import { Subscription } from 'rxjs';
import { ActivityInputType, ExperimentWorkflowState, SatisfyPromptCommand } from '../../../../api/data-entry/models';
import { LockType } from '../../../../model/input-lock.interface';
import { LabItemWisePermissions, LabItemsFeatureManager } from '../../../labItems/shared/lab-items-feature-manager';
import { UnsubscribeAll } from '../../../../shared/rx-js-helpers';

@Component({
  selector: 'app-prompts-dropdown-renderer',
  template: `
              <div  class="eln-prompts-dropdown">
                <bpt-dropdown
                  #promptsDropdown
                  id="eln-promptsDropdown"
                  [readOnly]="disableSelection"
                  [showClear]="showClear"
                  [options]="prompts"
                  (dropdownChange)="promptChanged($event)"
                  [(ngModel)]="selectedPrompt"
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  (dropdownShow)="showDropdown()"
                  (dropdownHide)="releaseCellLock()"
                ></bpt-dropdown>
              </div>
            `,
  styleUrls: ['./prompts-dropdown-renderer.component.scss']
})
export class PromptsDropdownRendererComponent implements ICellRendererAngularComp, OnDestroy {
  prompts: any = [];
  selectedPrompt?: any;
  showClear = true;
  subscriptions: Subscription[] = [];
  disableSelection = false;
  rowId = '';
  promptsColumnName = 'promptsName'

  @ViewChild('promptsDropdown') promptsDropdown?: BptDropdownComponent;
  refresh(): boolean {
    return false;
  }

  constructor(
    public readonly experimentService: ExperimentService,
    private readonly promptsService: PromptsService) {
      this.handleSubscriptions();
    }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  public params!: any;

  agInit(params: any): void {
    this.params = params;
    this.rowId = params.data.code || params.data.itemReference || params.data.preparationNumber;
    this.setDisableSelection(this.params.allowRowAdd);
    this.loadPromptsDropdownData();
    this.loadSelectedPrompt();
  }

  loadPromptsDropdownData(): void {
    const data = this.promptsService.getCurrentActivityPrompts(this.params.promptType);
    this.prompts = data?.map((item: PromptItem) => ({ label: item.name, value: item.promptId }));
    this.prompts.push({ label: NA, value: NA })
  }

  showDropdown(): void {
    if(this.promptsDropdown) {
      const data = this.promptsService.getCurrentActivityPrompts(this.params.promptType)?.filter(p => (!p.isUsed || p.promptId === this.selectedPrompt) && !p.isRemoved);
      if(data) this.promptsDropdown.options = data.map((item: PromptItem) => ({ label: item.name, value: item.promptId }));
      this.promptsDropdown.options.push({ label: NA, value: NA });
      this.promptsService.sendInputStatus(LockType.lock, '', this.rowId, this.promptsColumnName);
    }
  }

  promptChanged(event: DropdownChangeEvent): void {
    this.promptsService.sendInputStatus(LockType.unlock, '', this.rowId, this.promptsColumnName);
    if(!event) return;
    this.showClear = this.selectedPrompt === NA;
    const labItemId = (this.params.data.code || this.params.data.itemReference || this.params.data.preparationNumber);
    if(!event.value) {
      if(this.selectedPrompt !== NA) this.promptsService.promptSelectionChanged(this.selectedPrompt, labItemId, false);
      this.selectedPrompt = NA;
      return;
    }
    if(event.value !== this.selectedPrompt) {
      if(this.selectedPrompt && this.selectedPrompt !== NA) this.promptsService.promptSelectionChanged(this.selectedPrompt, labItemId, false);
    }
    if(event.value !== NA) this.promptsService.promptSelectionChanged(event.value, labItemId, true);
  }

  private setDisableSelection(allowRowAdd: boolean) {
    if(this.params.labItemType === ActivityInputType.Consumable) {
      this.disableSelection = !allowRowAdd;
      return;
    }
    if(this.params.labItemType === ActivityInputType.Preparation) {
      const workflowState = this.experimentService.currentExperiment?.workflowState;
      this.disableSelection = workflowState === ExperimentWorkflowState.InReview ||
      workflowState === ExperimentWorkflowState.Authorized ||
      workflowState === ExperimentWorkflowState.Cancelled;
      return;
    }
    let permissions: LabItemWisePermissions = {};
    this.subscriptions.push(
      this.params.latestLabItemsPermissions?.subscribe({
        next: (latestPermissions: any) => {
          permissions = latestPermissions;
        }
      })
    );
    this.disableSelection = !((this.params.data.code || this.params.data.itemReference) &&
      permissions[this.params.labItemType][
        LabItemsFeatureManager.FeatureNamesByItemType[this.params.labItemType].removeFeatureName
      ]
    );
  }

  private loadSelectedPrompt(): void {
    const promptSelected = this.promptsService.getCurrentActivityPrompts(this.params.promptType)?.find(p =>
     p.labItemId && ((p.labItemId === this.params.data.code) || (p.labItemId === this.params.data.itemReference) || (p.labItemId === this.params.data.preparationNumber)));
    this.selectedPrompt = promptSelected?.promptId ?? NA;
    this.showClear = this.selectedPrompt === NA ? false: true;
  }

  private processPromptChangedNotification(promptDetails: SatisfyPromptCommand) {
    if(promptDetails.labItemId === this.params.data.code ||
      promptDetails.labItemId === this.params.data.itemReference ||
      promptDetails.labItemId === this.params.data.preparationNumber
    ) {
      this.selectedPrompt = promptDetails.isSatisfied ? promptDetails.promptId : NA;
      this.showClear = this.selectedPrompt !== NA;
    }
  }

  releaseCellLock() {
    this.promptsService.sendInputStatus(LockType.unlock, '', this.rowId, this.promptsColumnName);
  }

  private handleSubscriptions() {
    this.subscriptions.push(this.promptsService.promptUnSatisfied.subscribe({
      next: (labItemId: string) => {
        this.promptsService.isPromptAssociatedWithRemovedLabItem(labItemId);
      }
    }));
    this.experimentService.experimentWorkFlowState.subscribe({
      next: (experimentWorkflowState: ExperimentWorkflowState) => {
        const allowRowAdd = (experimentWorkflowState === ExperimentWorkflowState.Setup) ||
          (experimentWorkflowState === ExperimentWorkflowState.InProgress) ||
          (experimentWorkflowState === ExperimentWorkflowState.InCorrection);
        this.setDisableSelection(allowRowAdd);
      }
    });
    this.subscriptions.push(
      this.promptsService.promptSelectionUpdated.subscribe({
        next: (promptDetails: SatisfyPromptCommand) => {
          this.processPromptChangedNotification(promptDetails);
        }
      })
    );
  }
}
