import { DateTimeFormatter, Instant, LocalDate, LocalDateTime, ZoneOffset, ZonedDateTime } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import { NA } from "bpt-ui-library/shared";
import { UserService } from "../services/user.service";
import '@js-joda/timezone/dist/js-joda-timezone-1970-2030';

/**
 * Display formats for dates and times. (Using js-joda syntax.)
 *
 * An Instant has different precisions available; Date has only one format.
 *
 * These are compatible with User Requirement 2649527: Quality Attribute: Internationalization and localization
 * https://dev.azure.com/BPTCollection/Eurofins%20ELN/_workitems/edit/2649527
 *
 */
export enum DateAndInstantFormat {
  // https://js-joda.github.io/js-joda/manual/formatting.html
  date = 'dd-MMM-yyyy',
  dateTimeToMinute = 'dd-MMM-yyyy hh:mm a',
  dateTimeToSecond = 'dd-MMM-yyyy hh:mm:ss a'
}

/** Lower-level data types used in presenting or inputting LocalDateValue and InstantValue */
export type ElnDateTimeTypes = LocalDate | Instant | typeof NA | undefined;

/**
 * Converts an Instant  or its JSON representation to the given display format, applying the browser's time zone.
 */
export function formatInstant(value: string | Instant, format: DateAndInstantFormat): string {
  const instant = value instanceof Instant ? value : Instant.parse(value);
  const formatter = DateTimeFormatter.ofPattern(format).withLocale(Locale.ENGLISH);
  return instant.atZone(UserService.currentLabSiteTimeZone).format(formatter);
}

/**
 * Parse date formatted as dd-MMM-YYY HH:mm Am/PM to Instant, applying the browser's time zone.
 */
export function parseStringDateToInstant(date: string): Instant {
  const formatter = DateTimeFormatter.ofPattern(DateAndInstantFormat.dateTimeToMinute).withLocale(Locale.ENGLISH);
  const localDateTime = LocalDateTime.parse(date, formatter);
  return localDateTime.atZone(UserService.currentLabSiteTimeZone).toInstant();
}

/**
 * Parse date formatted as dd-MMM-YYY HH:mm:ss Am/PM to Instant, applying the browser's time zone.
 */
export function parseFormattedStringDateToInstant(date: string): Instant {
  const formatter = DateTimeFormatter.ofPattern(DateAndInstantFormat.dateTimeToSecond).withLocale(Locale.ENGLISH);
  const localDateTime = LocalDateTime.parse(date, formatter);
  return localDateTime.atZone(UserService.currentLabSiteTimeZone).toInstant();
}


/**
 * Converts a LocalDate or its JSON representation to the date display format.
 */
export function formatLocalDate(value: string | LocalDate): string {
  const localDate = value instanceof LocalDate ? value : LocalDate.parse(value);
  const formatter = DateTimeFormatter.ofPattern(DateAndInstantFormat.date).withLocale(Locale.ENGLISH);
  return localDate.format(formatter);
}

/**
 * Comparator function for Instants as JSON strings
 */
export function dateComparator(cellValue1: string, cellValue2: string) {
  const cellDate1 = Instant.parse(cellValue1);
  const cellDate2 = Instant.parse(cellValue2);
  if (cellDate1.compareTo(cellDate2) < 0) {
    return -1;
  } else if (cellDate1.compareTo(cellDate2) > 0) {
    return 1;
  } else {
    return 0;
  }
}

/**
 * Filter comparator to filter a JavaScript Date in grid
 */
export function filterComparator(filterLocalDateAtMidnight: Date, cellValue: string) {
  const date = LocalDate.ofInstant(Instant.parse(filterLocalDateAtMidnight.toISOString()));
  const cellDate = LocalDate.ofInstant(Instant.parse(cellValue))
  if (date.compareTo(cellDate) < 0) {
    return -1;
  } else if (date.compareTo(cellDate) > 0) {
    return 1;
  } else {
    return 0;
  }
}

/**
  * Unapplies current time zone such as from a date that was entered and got converted to a Date.
  * For example: Entering 2022-09-25 might result in the Date equivalent of 2022-09-24T23:00:00Z through the application of the current time zone
  * so the current time zone needs to be unapplied to get the user-entered date back 2022-09-25.
  */
export function convertDateToLocalDate(date: Date): LocalDate {
  // These JavaScript methods are time zone-sensitive. getMonth is 0-based, btw.
  return LocalDate.of(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

/**
 * Converts date into Instant of day start/day end in UTC
 * Pass startOfDay = false to convert date into day end time
 */
export function convertDateToMidnight(date: Date, startOfDay = true): Instant {
  const localDate = convertDateToLocalDate(date);
  let zonedDateTime = localDate.atStartOfDay(UserService.currentLabSiteTimeZone);

  if (!startOfDay) {
    const localDateTime = localDate.atStartOfDay();
    const endOfDay = localDateTime.plusDays(1).minusNanos(1);
    zonedDateTime = ZonedDateTime.of(endOfDay, UserService.currentLabSiteTimeZone);
  }

  const zonedDateTimeTarget = zonedDateTime.withZoneSameInstant(ZoneOffset.UTC);
  return zonedDateTimeTarget.toInstant();
}

export function parseToLocalDate(text: string | undefined): LocalDate | undefined {
  return text ? LocalDate.parse(text) : undefined;
}

/**
 * Validates the start and end dates of a range are not crossed.
 * If either are not given then returns undefined.
 *
 * @param {(LocalDate | string | undefined)} startDate
 * @param {(LocalDate | string | undefined)} endDate
 * @return {*}  {(boolean | undefined)}
 * @memberof DateAndTimeServiceService
 */
export function isValidDateRange(startDate: LocalDate | string | undefined, endDate: LocalDate | string | undefined): boolean | undefined {
  if (!startDate) return;
  if (!endDate) return;

  // fiddle with the parameters 😲
  if (typeof (startDate) === 'string') startDate = LocalDate.parse(startDate);
  if (typeof (endDate) === 'string') endDate = LocalDate.parse(endDate);

  return startDate.compareTo(endDate) <= 0;
}
