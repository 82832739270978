/* tslint:disable */
/* eslint-disable */
export enum NodeType {
  Invalid = 'invalid',
  ActivityGroup = 'activityGroup',
  Activity = 'activity',
  Module = 'module',
  Form = 'form',
  Table = 'table',
  TableForm = 'tableForm',
  ActivityInput = 'activityInput',
  ClientFacingNotes = 'clientFacingNotes',
  Experiment = 'experiment',
  ExperimentFlags = 'experimentFlags',
  StatusRecord = 'statusRecord',
  VariablesNode = 'variablesNode',
  LabItems = 'labItems',
  ResultSetColumns = 'resultSetColumns',
  ChromatographyData = 'chromatographyData',
  ChromatographyDataSummary = 'chromatographyDataSummary',
  Preparations = 'preparations',
  InstrumentEventImpactAssessment = 'instrumentEventImpactAssessment',
  Recipe = 'recipe',
  ActivityFiles = 'activityFiles',
  References = 'references',
  Prompts = 'prompts',
  Statements = 'statements',
  ValidationResult = 'validationResult'
}
