<div
  class="template-loader-slider-container"
  [ngClass]="sliderOptions.containerStyleClass"
  id="templateLoaderSlider"
>
  <bpt-slider
    id="eln-TemplateLoadSlider"
    #elnTemplateSlider
    [(visible)]="sliderOptions.visible"
    [closeOnEscape]="sliderOptions.closeOnEscape"
    [headerText]="sliderOptions.headerText"
    [size]="sliderOptions.size"
    [isFooterSticky]="sliderOptions.isFooterSticky"
    [displayFooter]="sliderOptions.displayFooter"
    (visibleChange)="performSliderClosingActivity($event)"
    [displayFooterWithPrimaryButton]="sliderOptions.displayFooterWithPrimaryButton"
    [displayFooterWithSecondaryButton]="sliderOptions.displayFooterWithSecondaryButton"
    [isPrimaryButtonDisabled]="!selectedTemplate"
    [primaryButtonLabel]="sliderOptions.primaryButtonLabel"
  >
    <div class="slider-content" id="templateLoaderSliderContent">
      <div class="template-search-container" id="templateLoaderSearchContainer">
        <h4 id="eln-templateLoadFormHeader">
          <div class="title">
            <span
              class="template-search-title eln-heading heading bpt-font-family semibold"
              id="templateSearchTitle"
              [innerText]="headerTitle"
            ></span>
          </div>
        </h4>
        <div>
          <div class="col-12 column-gap-8 pl-0">
            <bpt-dropdown
              i18n-label="@@option"
              label="Insert Option"
              i18n-placeholder="@@optionPlaceHolder"
              placeholder="Select the option"
              [(ngModel)]="selectedInsertOption"
              (ngModelChange)="insertOptionChanged($event)"
              [options]="insertOptions"
              [editable]="false"
              [showClear]="false"
              [showFilter]="false"
              [multiSelect]="false"
              [required]="true"
            ></bpt-dropdown>
          </div>
          <div class="col-12 column-gap-8 pl-0" *ngIf="locationRequired">
            <bpt-dropdown
              #templateOptionsDropdown
              i18n-label="@@location"
              label="Insert Location"
              i18n-placeholder="@@locationPlaceHolder"
              placeholder="Select the location of the Template"
              [(ngModel)]="selectedTemplateLocation"
              (ngModelChange)="modelChanged($event)"
              [options]="insertLocationOptions"
              [editable]="false"
              [showClear]="false"
              [showFilter]="false"
              [multiSelect]="false"
              valueField="id"
              labelField="displayLabelWithPrefix"
              [readOnly]="readOnly"
              [required]="true"
            ></bpt-dropdown>
          </div>
          <div class="col-12 column-gap-8 pl-0">
            <bpt-search
              id="eln-TemplateSearch"
              #templateSearch
              i18n-placeholder="@@TemplateNameSearchPlaceHolder"
              [placeholder]="isExperiment?placeHolder:toolTip"
              [advancedSuggestionOptions]="templateSuggestionOptions"
              [search]="getSearchResultsCount"
              [saveRecentSearch]="saveSearch"
              [errorText]="errorText"
              [recentSearchMaxCount]="recentSearchLimit"
              [advancedSearch]="true"
              [showInlineFilters]="showInlineFilters"
              [advancedSearchMethod]="filterTemplatesToAdvancedSearchCriteria"
              styleClass="templates-loader-container"
              (selectionChanged)="selectionChanged($event)"
              [searchControls]="searchControls"
              [searchBarTooltip]="toolTip"
              (filtersCleared)="clear()"
            ></bpt-search>

            <div *ngIf="loading" class="eln-layout-content">
              <bpt-progress-spinner
                id="eln-TemplateLoadProgressbar"
                [strokeWidth]="2"
                [diameter]="25"
                [useOverlay]="false"
                i18n-message="@@loadingTemplates"
                message="Loading Templates And Recipe..."
              ></bpt-progress-spinner>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap justify-content-center card-container">
          <div class="col-12 pl-0"></div>

          <div class="col-12 column-gap-8 pl-0"></div>
          <div
            class="flex flex-wrap justify-content-end card-container column-gap-3 step-one-options-container"
          >
            <p-button
              id="eln-btnCancelTemplateLoadSlidebar"
              label="Cancel"
              i18n-label="@@sliderFooterSecondaryDefaultLabel"
              styleClass="eln-p-button-text p-button-text pr-0"
              id="bpt-cancelTemplateSelection"
              (onClick)="closeSlider($event)"
            ></p-button>
            <p-button
              id="eln-btnTemplateSelectionNextStep"
              [disabled]="!enableLoadButton"
              [label]="sliderOptions.primaryButtonLabel"
              styleClass="eln-button-sm"
              (onClick)="confirmLoad()"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  </bpt-slider>
</div>
