/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { RecipeReferenceResponse } from '../models/recipe-reference-response';
import { RecipeResponse } from '../models/recipe-response';
import { recipesAllVersionsGet$Json } from '../fn/recipe/recipes-all-versions-get-json';
import { RecipesAllVersionsGet$Json$Params } from '../fn/recipe/recipes-all-versions-get-json';
import { recipesAllVersionsGet$Plain } from '../fn/recipe/recipes-all-versions-get-plain';
import { RecipesAllVersionsGet$Plain$Params } from '../fn/recipe/recipes-all-versions-get-plain';
import { recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json } from '../fn/recipe/recipes-get-other-versions-with-states-recipe-number-version-post-json';
import { RecipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json$Params } from '../fn/recipe/recipes-get-other-versions-with-states-recipe-number-version-post-json';
import { recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain } from '../fn/recipe/recipes-get-other-versions-with-states-recipe-number-version-post-plain';
import { RecipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain$Params } from '../fn/recipe/recipes-get-other-versions-with-states-recipe-number-version-post-plain';
import { recipesRecipeIdGet$Json } from '../fn/recipe/recipes-recipe-id-get-json';
import { RecipesRecipeIdGet$Json$Params } from '../fn/recipe/recipes-recipe-id-get-json';
import { recipesRecipeIdGet$Plain } from '../fn/recipe/recipes-recipe-id-get-plain';
import { RecipesRecipeIdGet$Plain$Params } from '../fn/recipe/recipes-recipe-id-get-plain';
import { recipesRecipeNumberVersionGet$Json } from '../fn/recipe/recipes-recipe-number-version-get-json';
import { RecipesRecipeNumberVersionGet$Json$Params } from '../fn/recipe/recipes-recipe-number-version-get-json';
import { recipesRecipeNumberVersionGet$Plain } from '../fn/recipe/recipes-recipe-number-version-get-plain';
import { RecipesRecipeNumberVersionGet$Plain$Params } from '../fn/recipe/recipes-recipe-number-version-get-plain';

@Injectable({ providedIn: 'root' })
export class RecipeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `recipesRecipeNumberVersionGet()` */
  static readonly RecipesRecipeNumberVersionGetPath = '/recipes/{recipeNumber}/{version}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesRecipeNumberVersionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeNumberVersionGet$Plain$Response(params: RecipesRecipeNumberVersionGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeResponse>> {
    return recipesRecipeNumberVersionGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesRecipeNumberVersionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeNumberVersionGet$Plain(params: RecipesRecipeNumberVersionGet$Plain$Params, context?: HttpContext): Observable<RecipeResponse> {
    return this.recipesRecipeNumberVersionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeResponse>): RecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesRecipeNumberVersionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeNumberVersionGet$Json$Response(params: RecipesRecipeNumberVersionGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeResponse>> {
    return recipesRecipeNumberVersionGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesRecipeNumberVersionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeNumberVersionGet$Json(params: RecipesRecipeNumberVersionGet$Json$Params, context?: HttpContext): Observable<RecipeResponse> {
    return this.recipesRecipeNumberVersionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeResponse>): RecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipesRecipeIdGet()` */
  static readonly RecipesRecipeIdGetPath = '/recipes/{recipeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesRecipeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeIdGet$Plain$Response(params: RecipesRecipeIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeResponse>> {
    return recipesRecipeIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesRecipeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeIdGet$Plain(params: RecipesRecipeIdGet$Plain$Params, context?: HttpContext): Observable<RecipeResponse> {
    return this.recipesRecipeIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeResponse>): RecipeResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesRecipeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeIdGet$Json$Response(params: RecipesRecipeIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<RecipeResponse>> {
    return recipesRecipeIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesRecipeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesRecipeIdGet$Json(params: RecipesRecipeIdGet$Json$Params, context?: HttpContext): Observable<RecipeResponse> {
    return this.recipesRecipeIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecipeResponse>): RecipeResponse => r.body)
    );
  }

  /** Path part for operation `recipesGetOtherVersionsWithStatesRecipeNumberVersionPost()` */
  static readonly RecipesGetOtherVersionsWithStatesRecipeNumberVersionPostPath = '/recipes/get-other-versions-with-states/{recipeNumber}/{version}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain$Response(params: RecipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipeReferenceResponse>>> {
    return recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain(params: RecipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain$Params, context?: HttpContext): Observable<Array<RecipeReferenceResponse>> {
    return this.recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipeReferenceResponse>>): Array<RecipeReferenceResponse> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json$Response(params: RecipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipeReferenceResponse>>> {
    return recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json(params: RecipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json$Params, context?: HttpContext): Observable<Array<RecipeReferenceResponse>> {
    return this.recipesGetOtherVersionsWithStatesRecipeNumberVersionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipeReferenceResponse>>): Array<RecipeReferenceResponse> => r.body)
    );
  }

  /** Path part for operation `recipesAllVersionsGet()` */
  static readonly RecipesAllVersionsGetPath = '/recipes/all-versions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesAllVersionsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesAllVersionsGet$Plain$Response(params?: RecipesAllVersionsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipeReferenceResponse>>> {
    return recipesAllVersionsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesAllVersionsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesAllVersionsGet$Plain(params?: RecipesAllVersionsGet$Plain$Params, context?: HttpContext): Observable<Array<RecipeReferenceResponse>> {
    return this.recipesAllVersionsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipeReferenceResponse>>): Array<RecipeReferenceResponse> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recipesAllVersionsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesAllVersionsGet$Json$Response(params?: RecipesAllVersionsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RecipeReferenceResponse>>> {
    return recipesAllVersionsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recipesAllVersionsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recipesAllVersionsGet$Json(params?: RecipesAllVersionsGet$Json$Params, context?: HttpContext): Observable<Array<RecipeReferenceResponse>> {
    return this.recipesAllVersionsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecipeReferenceResponse>>): Array<RecipeReferenceResponse> => r.body)
    );
  }

}
