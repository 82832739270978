import { Injectable } from "@angular/core";
import { PromptEventsService } from "../../api/data-entry/services";
import { PromptRemovedEventNotification, PromptRemovedResponse, PromptRestoredEventNotification, PromptRestoredResponse, PromptSatisfiedEventNotification, PromptSatisfiedResponse, RemovePromptCommand, RestorePromptCommand, SatisfyPromptCommand } from "../../api/data-entry/models";
import { ExperimentService } from "../services/experiment.service";
import { ActivityPrompt, ExperimentWorkflowState, PromptItem, PromptType } from "../../api/models";
import { Observable, Subject } from "rxjs";
import { ConfirmationService, Message, MessageService } from "primeng/api";
import { PromptsWisePermissions, PromptsFeatureManager } from "../labItems/shared/prompts-feature-manager";
import { ICellRendererParams } from "ag-grid-community";
import { ClientStateService } from "../../services/client-state.service";
import { ExperimentNotificationService } from "../../services/experiment-notification.service";
import { DataRecordService } from "../services/data-record.service";
import { CellLock, LockType } from "../../model/input-lock.interface";

@Injectable({
  providedIn: 'root'
})

export class PromptsService {
  public promptSatisfied = new Subject<SatisfyPromptCommand>();
  public promptSelectionUpdated = new Subject<SatisfyPromptCommand>();
  public promptUnSatisfied = new Subject<string>();
  private readonly promptRemovedNotification = new Subject<void>();
  public readonly promptRemoved = new Subject<PromptItem>();
  public PromptRemovedRefresh: Observable<void> =
    this.promptRemovedNotification.asObservable();

  private readonly promptRestoredNotification = new Subject<void>();
  public readonly PromptRestored = new Subject<PromptItem>();
  public PromptRestoredRefresh: Observable<void> =
    this.promptRestoredNotification.asObservable();

  public rowActionIds: {[key: string]: string} = {
    removeRowIconId: 'eln-prompts-remove-row',
    restoreRowIconId: 'eln-prompts-restore-row'
  }

  styleClassProperties: { [key: string]: string } = {
    rejectButtonStyleClass: 'eln-standard-popup-button p-button-outlined',
    acceptButtonStyleClass: 'eln-standard-popup-button',
    icon: 'pi pi-exclamation-triangle'
  };
  public featuresByClientState: string[] = [];

  constructor(
    public readonly experimentService: ExperimentService,
    public readonly confirmationService: ConfirmationService,
    public readonly messageService: MessageService,
    private readonly promptEventService: PromptEventsService,
    private readonly dataRecordService: DataRecordService,
    private readonly experimentNotificationService: ExperimentNotificationService,
    private readonly clientStateService: ClientStateService) {
      this.handleSubscriptions();
  }

  public getRemovedPrompts(type?: PromptType): Array<PromptItem> {
    return this.getPromptOf(this.experimentService.currentActivityId).filter(c => c.isRemoved && c.type === type);
  }

  public getPrompts(promptType?: PromptType): Array<PromptItem> {
    const prompts = this.getPromptOf(this.experimentService.currentActivityId);
    return prompts.filter(c => !c.isRemoved && c.type === promptType);
  }

  private getPromptOf(activityNodeId: string): Array<PromptItem> {
    return (
      (this.experimentService.currentExperiment?.activityPrompts?.find(
        (promptnode: ActivityPrompt) => promptnode.activityId === activityNodeId
      )?.prompts as Array<PromptItem>) || []
    );
  }

  public sendInputStatus(
    lockType: LockType,
    tableId: string,
    rowId: string,
    columnName: string,
    parentId?: string
  ) {
    const fieldLock = new CellLock(
      typeof this.experimentService.currentExperiment?.id === 'undefined'
        ? ''
        : this.experimentService.currentExperiment?.id,
      lockType,
      this.experimentService.currentModuleId,
      this.experimentService.currentActivityId,
      this.experimentNotificationService.getCollaborator(),
      tableId,
      rowId,
      columnName,
      parentId
    );
    this.experimentNotificationService.sendInputControlStatus([fieldLock]);
  }

  public confirmThenRemovePrompts(item?: PromptItem) {
    this.confirmationService.confirm({
      message: $localize`:@@PromptRemoveConfirmationMessage:Are you sure that you want to remove "${(item as PromptItem).name
        }" from the prompts grid ? The removed Prompt can be restored using the "View Removed Rows" option.`,
      header: $localize`:@@confirmationHeaderForPrompts:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendInputStatus(LockType.unlock, '', item?.promptId ?? '', this.rowActionIds['removeRowIconId']);
        this.sendRequestToRemovePrompts(item as PromptItem);
      },
      reject: () => {
        this.sendInputStatus(LockType.unlock, '', item?.promptId ?? '', this.rowActionIds['removeRowIconId']);
      }
    });
  }

  private sendRequestToRemovePrompts(item: PromptItem) {
    const removePromptCommand: RemovePromptCommand = {
      activityId: this.experimentService.currentActivityId,
      promptId: item.promptId as string,
      experimentId: this.experimentService.currentExperiment?.id as string
    };
    this.promptEventService
      .promptEventsRemovePromptPost$Json(
        {
          body: removePromptCommand
        }
      )
      .subscribe({
        next: (response: PromptRemovedResponse) => {
          if (response.notifications.notifications.length === 0) {
            this.processRemovedPrompts(
              response.promptRemovedEventNotification,
              false
            );
          }
        }
      });
  }

  private processRemovedPrompts(
    notification: PromptRemovedEventNotification,
    isSourceCollabEdit: boolean
  ) {
    const activityPromptsNode = this.experimentService.currentExperiment?.activityPrompts?.find(
      (promptsNode: ActivityPrompt) => promptsNode.activityId === notification.activityId
    );
    if (activityPromptsNode) {
      const prompts = activityPromptsNode.prompts.find(
        (i) => i.promptId === notification.promptId
      );

      if (prompts) {
        prompts.isRemoved = true;
        this.promptRemovedNotification.next();
        this.promptRemoved.next(prompts);
        this.removedSuccessMessage(prompts.name ?? '', isSourceCollabEdit);
      }
    }
  }

  public confirmThenRestorePrompts(item?: PromptItem) {
    this.confirmationService.confirm({
      message: $localize`:@@PromptsRestoreConfirmationMessage:Are you sure that you want to restore "${(item as PromptItem).name
        }" from the removed prompts grid ?`,
      header: $localize`:@@confirmationHeaderForPrompts:Confirmation`,
      closeOnEscape: true,
      dismissableMask: false,
      acceptVisible: true,
      acceptLabel: $localize`:@@ok:OK`,
      rejectVisible: true,
      rejectLabel: $localize`:@@cancel:Cancel`,
      ...this.styleClassProperties,
      accept: () => {
        this.sendInputStatus(LockType.unlock, '', item?.promptId ?? '', this.rowActionIds['restoreRowIconId']);
        this.sendRequestToRestorePrompts(item as PromptItem);
      },
      reject: () => {
        this.sendInputStatus(LockType.unlock, '', item?.promptId ?? '', this.rowActionIds['restoreRowIconId']);
      }
    });
  }

  private sendRequestToRestorePrompts(item: PromptItem) {
    const restorePromptCommand: RestorePromptCommand = {
      activityId: this.experimentService.currentActivityId,
      promptId: item.promptId as string,
      experimentId: this.experimentService.currentExperiment?.id as string
    };
    this.promptEventService
      .promptEventsRestorePromptPost$Json(
        {
          body: restorePromptCommand
        }
      )
      .subscribe({
        next: (response: PromptRestoredResponse) => {
          if (response.notifications.notifications.length === 0) {
            this.processRestoredPrompts(
              response.promptRestoredEventNotification,
              false
            );
          }
        }
      });
  }

  private processRestoredPrompts(
    notification: PromptRestoredEventNotification,
    isSourceCollabEdit: boolean
  ) {
    const activityPromptsNode = this.experimentService.currentExperiment?.activityPrompts?.find(
      (promptsNode: ActivityPrompt) => promptsNode.activityId === notification.activityId
    );
    if (activityPromptsNode) {
      const prompts = activityPromptsNode.prompts.find(
        (i) => i.promptId === notification.promptId
      );

      if (prompts) {
        prompts.isRemoved = false;
        this.promptRestoredNotification.next();
        this.PromptRestored.next(prompts);
        this.restoredSuccessMessage(prompts.name ?? '', isSourceCollabEdit);
      }
    }
  }

  private removedSuccessMessage(promptName: string, isSourceCollabEdit: boolean) {
    const successMessage = $localize`:@@PromptRemovedSuccessMessage:The Prompt has been removed successfully.`;
    this.createNotificationMessage(successMessage, isSourceCollabEdit ? promptName : '');
  }

  private restoredSuccessMessage(promptName: string, isSourceCollabEdit: boolean) {
    const successMessage = $localize`:@@PromptRestoredSuccessMessage:The Prompt has been restored successfully.`;
    this.createNotificationMessage(successMessage, isSourceCollabEdit ? promptName : '');
  }

  private createNotificationMessage(summary: string, detail: string, severity = 'success') {
    const messageObj: Message = {
      key: 'notification',
      severity: severity,
      summary,
      detail,
      sticky: false
    };
    this.messageService.add(messageObj);
  }

  isPromptAssociatedWithRemovedLabItem(labItemId: string | undefined) {
    const currentActivityPrompts = this.experimentService?.currentExperiment?.activityPrompts?.find(p => p.activityId === this.experimentService.currentActivityId);
    const selectedPrompt = currentActivityPrompts?.prompts.find((p: PromptItem) => p.labItemId === labItemId);
    if (selectedPrompt) {
      if (selectedPrompt.isUsed) {
        labItemId = undefined;
        this.promptSelectionChanged(selectedPrompt.promptId as string, labItemId, false);
      }
    }
  }

  promptSelectionChanged(promptId: string , labItemId: string | undefined, isSatisfied: boolean) {
    const command: SatisfyPromptCommand = {
      experimentId: this.experimentService?.currentExperiment?.id ?? '',
      activityId: this.experimentService?.currentActivity?.activityId ?? '',
      isSatisfied: isSatisfied,
      promptId: promptId,
      labItemId: labItemId
    }

    this.promptEventService.promptEventsSatisfyPromptPost$Json$Response({
      body: command
    }).subscribe({
      next: (res) => this.updateSatisfiedActivityPrompt(res.body)
    });
    this.promptSatisfied.next(command);
  }

  getCurrentActivityPrompts(promptType: PromptType) {
    const promptsData = this.experimentService?.currentExperiment?.activityPrompts?.find(p => p.activityId === this.experimentService.currentActivityId);
    return promptsData?.prompts?.filter((p: PromptItem) => p.type === promptType);
  }

  updateSatisfiedActivityPrompt(promptResp: PromptSatisfiedResponse) {
    const promptsData = this.experimentService?.currentExperiment?.activityPrompts?.find(p => p.activityId === this.experimentService.currentActivityId);
    const prompt = promptsData?.prompts?.find((p: PromptItem) => p.promptId === promptResp.promptSatisfiedEventNotification.promptId);
    if(prompt) {
      prompt.isUsed = promptResp.promptSatisfiedEventNotification.isSatisfied;
      prompt.labItemId = promptResp.promptSatisfiedEventNotification.isSatisfied ? promptResp.promptSatisfiedEventNotification.labItemId : undefined;
    }
  }

  public validateRemovePromptFeatureToEnable = (promptType: PromptType, clientState: string, data: ICellRendererParams) => {
    const key = `-${data.data.promptId}-${this.rowActionIds['removeRowIconId']}`;
    return !this.isRowActionLocked(key) &&
      !data.data.isUsed &&
      this.evaluatePermissions(clientState)[promptType]['eln-remove-prompt'] &&
      this.experimentService.currentExperiment?.workflowState !== ExperimentWorkflowState.InReview;
  }

  public validateRestorePromptFeatureToEnable = (promptType: PromptType, clientState: string, data: ICellRendererParams) => {
    const key = `-${data.data.promptId}-${this.rowActionIds['restoreRowIconId']}`;
    return !this.isRowActionLocked(key) &&
      this.evaluatePermissions(clientState)[promptType]['eln-restore-prompt'] &&
      this.experimentService.currentExperiment?.workflowState !== ExperimentWorkflowState.InReview;
  }

  private evaluatePermissions(clientState: string): PromptsWisePermissions {
    const features = this.clientStateService.getFeatureFlags(clientState);
    this.featuresByClientState = features;
    return this.evaluateUserPermissionsOnPrompts();
  }

  public evaluateUserPermissionsOnPrompts(
    workflowState?: ExperimentWorkflowState
  ): PromptsWisePermissions {
    const currentWorkFlowState =
      workflowState ||
      this.experimentService.currentExperiment?.workflowState ||
      ExperimentWorkflowState.Setup;

    const permissions: PromptsWisePermissions =
      PromptsFeatureManager.EvaluateUserPermissionsOnLabItems(
        currentWorkFlowState,
        this.featuresByClientState
      );
    return permissions;
  }

  isRowActionLocked(key: string):boolean {
    const lock = this.experimentNotificationService.inputLocks.find(lock => lock.key === key);
    if(!lock) {
      return false
    }
    return lock.lockType === LockType.lock ? true : false;
  }

  handleSubscriptions() {
    this.dataRecordService.labItemsPromptRemovedEventNotificationReceiver.subscribe({
      next: (notification: PromptRemovedEventNotification) => this.processRemovedPrompts(notification, true)
    });
    this.dataRecordService.labItemsPromptRestoredEventNotificationReceiver.subscribe({
      next: (notification: PromptRestoredEventNotification) => this.processRestoredPrompts(notification, true)
    });
    this.dataRecordService.labItemsPromptSatisfiedEventNotificationReceiver.subscribe({
      next: (notification: PromptSatisfiedEventNotification) => {
        const promptSatisfiedDetails = {
          activityId: notification.activityId,
          experimentId: notification.eventContext.experimentId,
          promptId: notification.promptId,
          isSatisfied: notification.isSatisfied,
          labItemId: notification.labItemId
        };
        this.promptSatisfied.next(promptSatisfiedDetails);
      }
    });
  }

  hasRestorePermission(promptType: PromptType): boolean {
    const permissions = this.evaluateUserPermissionsOnPrompts();
    return permissions[promptType][
      PromptsFeatureManager.FeatureNamesByItemType[promptType].restoreFeatureName
    ];
  }

  hasRemovePermission(promptType: PromptType): boolean {
    const permissions = this.evaluateUserPermissionsOnPrompts();
    return permissions[promptType][
      PromptsFeatureManager.FeatureNamesByItemType[promptType].removeFeatureName
    ];
  }

  public failedToPerformOperationMessage() {
    this.createNotificationMessage(
      $localize`:@@PromptsFailedToPerformOperationMessage:This action on the Prompt cannot be performed`,
      '',
      'error'
    );
  }
}