import { Component, OnDestroy, OnInit } from '@angular/core';
import { PromptItem, PromptType } from '../../api/models';
import { ExperimentService } from '../services/experiment.service';
import { LabItemsService } from '../labItems/lab-items.service';
import { BarcodeScannerHelper } from '../../services/barcode-scanner-helper';
import { PreparationLabItemsService } from '../../preparation/services/preparation-labitems.service';
import { PromptsGridOptions } from './prompts-grid/prompts-grid-options';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';

@Component({
  selector: 'app-prompts',
  templateUrl: './prompts.component.html',
  styleUrls: ['./prompts.component.scss']
})
export class PromptsComponent implements OnInit, OnDestroy {
  tabPanels: any = [];
  promptsNodeData?: PromptItem[];
  showMaterialTab = false;
  subscriptions: Subscription[] = [];

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly experimentService: ExperimentService,
    private readonly labItemService: LabItemsService,
    private readonly barcodeScannerHelper: BarcodeScannerHelper,
    private readonly preparationService: PreparationLabItemsService
  ) {
  }

  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
  }

  ngOnInit(): void {
    this.watchRouterPathChanges();
    this.watchForActivityTitleDeterminationThroughRoute(this.activatedRoute);
    this.handleSubscriptions();
    this.loadPromptsData(this.experimentService.currentActivityId);
    this.loadTabPanels();
  }

  private watchForActivityTitleDeterminationThroughRoute(route: ActivatedRoute) {
    route.params.subscribe((params) => {
      const activity = this.experimentService.GetActivityBasedOnParams(params);
      if (activity) {
        this.experimentService.currentActivityId = activity.activityId;
      }
    });
  }

  public watchRouterPathChanges() {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((_route: { url: string }) => {
        if (_route.url.toLowerCase().includes('/labitems')) {
          this.loadPromptsData(this.experimentService.currentActivityId);
        }
      });
  }

  private readonly promptTypesMap: {
    [promptType: string]: string;
  } = {
      materials: $localize`:@@MaterialsPrompt:Materials Prompt`,
      instruments: $localize`:@@InstrumentsPrompt:Instruments Prompt`,
      columns: $localize`:@@ColumnsPrompt:Columns Prompt`,
      consumablesAndSupplies: $localize`:@@ConsumablesPrompt:Consumables & Supplies Prompt`,
      preparations: $localize`:@@PreparationsPrompt:Preparations Prompt`
    };

  public getTabVisibility(type: PromptType): boolean {
    const getLabItems = (type: PromptType) => {
      switch (type) {
        case PromptType.Materials:
          return [
            this.labItemService.getLabItemsMaterials(),
            this.labItemService.getLabItemsRemovedMaterials()
          ];
        case PromptType.Instruments:
          return [
            this.labItemService.getLabItemsInstruments(),
            this.labItemService.getLabItemsRemovedInstruments()
          ];
        case PromptType.Columns:
          return [
            this.labItemService.getLabItemsColumns(),
            this.labItemService.getLabItemsRemovedInstrumentColumns()
          ];
        case PromptType.ConsumablesAndSupplies:
          return [
            this.labItemService.getLabItemsConsumables(),
            this.labItemService.getLabItemsRemovedConsumables()
          ];
        case PromptType.Preparations:
          return [
            this.preparationService.getLabItemPreparations(),
            this.preparationService.getLabItemsRemovedPreparations()
          ];
        default:
          return [];
      }
    };
    const dataSource = this.getDataSource(type);
    const [labItems, removedLabItems] = getLabItems(type);
    return labItems.length > 0 ||
      removedLabItems.length > 0 ||
      (dataSource && dataSource.length > 0);
  }

  getDataSource(type: PromptType) {
    return this.promptsNodeData?.filter(p => p.type === type) ?? [];
  }

  loadTabPanels() {
    this.tabPanels = [{
      type: PromptType.Materials,
      header: $localize`:@@MaterialsTabHeader:Materials`,
      columnDefinitions: PromptsGridOptions.GetMaterialPromptDefinitions(),
      dataSource: this.getDataSource(PromptType.Materials) || this.labItemService.getLabItemsMaterials(),
      gridTitle: this.promptTypesMap[PromptType.Materials],
      show: this.getTabVisibility(PromptType.Materials),
    },
    {
      type: PromptType.Instruments,
      header: $localize`:@@InstrumentsTabHeader:Instruments`,
      columnDefinitions: PromptsGridOptions.GetInstrumentPromptDefinitions(),
      dataSource: this.getDataSource(PromptType.Instruments) || this.labItemService.getLabItemsInstruments(),
      gridTitle: this.promptTypesMap[PromptType.Instruments],
      show: this.getTabVisibility(PromptType.Instruments),
    },
    {
      type: PromptType.Columns,
      header: $localize`:@@ColumnsTabHeader:Columns`,
      columnDefinitions: PromptsGridOptions.GetColumnPromptDefinitions(),
      dataSource: this.getDataSource(PromptType.Columns) || this.labItemService.getLabItemsColumns(),
      gridTitle: this.promptTypesMap[PromptType.Columns],
      show: this.getTabVisibility(PromptType.Columns),
    },
    {
      type: PromptType.ConsumablesAndSupplies,
      header: $localize`:@@ConsumablesTabHeader:Consumables & Supplies`,
      columnDefinitions: PromptsGridOptions.GetConsumableSupplyPromptDefinitions(),
      dataSource: this.getDataSource(PromptType.ConsumablesAndSupplies) || this.labItemService.getLabItemsConsumables(),
      gridTitle: this.promptTypesMap[PromptType.ConsumablesAndSupplies],
      show: this.getTabVisibility(PromptType.ConsumablesAndSupplies),
    },
    {
      type: PromptType.Preparations,
      header: $localize`:@@PreparationsTabHeader:Preparations`,
      columnDefinitions: PromptsGridOptions.GetPreparationPromptDefinitions(),
      dataSource: this.getDataSource(PromptType.Preparations) || this.preparationService.getLabItemPreparations(),
      gridTitle: this.promptTypesMap[PromptType.Preparations],
      show: this.getTabVisibility(PromptType.Preparations),
    }]
  }

  loadPromptsData(activityId: string) {
    if (this.experimentService?.currentExperiment?.activityPrompts) {
      const promptsData = this.experimentService?.currentExperiment.activityPrompts.find(p => p.activityId === activityId);
      this.promptsNodeData = promptsData?.prompts;
    }
  }

  handleSubscriptions() {
    this.subscriptions.push(this.labItemService.MaterialAdded.subscribe(() => {
       this.tabPanels.find((p: { type: PromptType; }) => p.type === PromptType.Materials).show = true;
    }));
    this.subscriptions.push(this.labItemService.InstrumentAdded.subscribe(() => {
      this.tabPanels.find((p: { type: PromptType; }) => p.type === PromptType.Instruments).show = true;
    }));
    this.subscriptions.push(this.labItemService.ColumnAdded.subscribe(() => {
      this.tabPanels.find((p: { type: PromptType; }) => p.type === PromptType.Columns).show = true;
    }));
    this.subscriptions.push(this.experimentService.labItemsConsumableAdded.subscribe(() => {
      this.tabPanels.find((p: { type: PromptType; }) => p.type === PromptType.ConsumablesAndSupplies).show = true;
    }));
    this.subscriptions.push(this.barcodeScannerHelper.labItemPreparationAddedPublisher.subscribe(() => {
      this.tabPanels.find((p: { type: PromptType; }) => p.type === PromptType.Preparations).show = true;
    }));
    this.subscriptions.push(this.experimentService.templateEventService.ExperimentRefreshed.subscribe({
      next: () => this.activitySelectionChanged(this.experimentService.currentActivityId)
    }));
  }

  onTabSelectionChanged(event: { index: number; }) {
    setTimeout(() => {
      const elem = document.getElementById('eln-lab-item-grids');
      if (elem?.children[event.index].textContent !== '')
        elem?.children[event.index].scrollIntoView();
    });
  }

  public activitySelectionChanged(activityId: string) {
    this.loadPromptsData(activityId);
    this.loadTabPanels();
  }
}
