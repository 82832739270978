import { Component, OnInit, Output, EventEmitter, OnDestroy, ElementRef, HostListener } from '@angular/core';
import { ClientValidationDetails } from 'model/client-validation-details';
import { ExperimentWorkflowState } from '../../api/data-entry/models';
import { ClientStateService } from 'services/client-state.service';
import { ExperimentService } from '../services/experiment.service';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { Activity, ActivityReferences, Experiment, Table } from 'model/experiment.interface';
import { UnsubscribeAll } from '../../shared/rx-js-helpers';
import { DataRecordService } from '../services/data-record.service';

/** Identifies the grouping below the parent activity, such as for cell locks */
export const ActivityReferencesPseudoModuleId = 'activityReferences';
export const ActivityReferencesPseudoModuleTitle = $localize`:@@references:References`;

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() readOnlyOnStateChange: EventEmitter<any> = new EventEmitter();

  private readonly subscriptions: Subscription[] = [];
  scrollable = false;
  message!: string;
  experiment?: Experiment;
  activity?: Activity;
  readonly activityReferencesPseudoModuleId = ActivityReferencesPseudoModuleId;
  validation!: ClientValidationDetails;
  isReviewerOrSupervisor = false;
  specifyNoData = $localize`:@@NoReferences:No References have been added`;
  crossReferencesGridAdded = false;
  isApplyingTemplate = false;

  get isLoadingDocumentsOrCompendia() : boolean {
    return this.experimentService.isLoadingDocumentsOrCompendia
  }

  get activityReferences(): ActivityReferences {
    if (!this.activity) throw new Error("LOGIC ERROR: Expected Activity to be defined");
    return this.activity.activityReferences;
  }

  private _documentsTable: Table | undefined;
  get documentsTable(): Table | undefined {
    if (this._documentsTable) return this._documentsTable;
    if (!this.activityReferences.documentReferencesTableId) return undefined;

    this._documentsTable = this.activityReferences.documentsTable;
    return this._documentsTable;
  }

  private _compendiaTable: Table | undefined;
  get compendiaTable(): Table | undefined {
    if (this._compendiaTable) return this._compendiaTable;
    if (!this.activityReferences.compendiaReferencesTableId) return undefined;

    this._compendiaTable = this.activityReferences.compendiaTable;
    return this._compendiaTable;
  }

  get referencesExist(): boolean {
    return (this.activity && this.activity.activityReferences.crossReferences.length > 0) ||
      !!this.documentsTable ||
      !!this.compendiaTable;
  }

  constructor(
    readonly clientStateService: ClientStateService,
    readonly route: ActivatedRoute,
    private readonly experimentService: ExperimentService,
    private readonly elementRef: ElementRef,
    private readonly dataRecordService: DataRecordService
    ) {
    super(clientStateService, route);
  }

  ngOnInit(): void {
    this.subscriptions.splice(0, 0,
      this.route.params.subscribe((params) => {
        const activity = this.experimentService.GetActivityBasedOnParams(params);
        if (activity) this.activity = activity;
        this.checkReferencesExist();
      }),
      this.experimentService.experimentWorkFlowState.subscribe((data: ExperimentWorkflowState) =>
        this.setupPage(data)
      ),
      this.dataRecordService.activityReferenceTemplateAppliedEventNotificationReceiver.subscribe(
        { next: notification => {
          if (this.activity) { // Seems possible that we could get here when the expt is not fully loaded
            this.experimentService.renderAppliedTemplate(this.activity, notification);
          }
        }}
      )
    );
  }

  @HostListener('unloaded') // want a new component whenever navigation
  ngOnDestroy(): void {
    UnsubscribeAll(this.subscriptions);
    this.elementRef.nativeElement.remove(); // want a new component whenever navigation
  }

  trackByActivityId = () => this.activity?.activityId;

  private setupPage(_workflowState: ExperimentWorkflowState) {
    this.isReviewerOrSupervisor = !this.clientStateService
      .getFeatureFlags(this.clientState)
      .find((data) => {
        const receivedData = JSON.parse(data);
        return (
          receivedData.CanEditExperimentInReviewState &&
          receivedData.CanEditExperimentInReviewState === true
        );
      })
      ? false
      : true;
  }

  private checkReferencesExist() {
    this.experiment = this.experimentService.currentExperiment;
    if (!this.activity) throw new Error('LOGIC ERROR: Expected activity to be defined');

    this.experimentService.currentActivityId = this.activity.activityId;
  }
}
