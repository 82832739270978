/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExperimentAssignedAnalystsChangedResponse } from '../models/experiment-assigned-analysts-changed-response';
import { ExperimentAssignedReviewersChangedResponse } from '../models/experiment-assigned-reviewers-changed-response';
import { ExperimentAssignedSupervisorsChangedResponse } from '../models/experiment-assigned-supervisors-changed-response';
import { ExperimentAuthorizationDueDateChangedResponse } from '../models/experiment-authorization-due-date-changed-response';
import { ExperimentAuthorizedResponse } from '../models/experiment-authorized-response';
import { ExperimentCancelledResponse } from '../models/experiment-cancelled-response';
import { ExperimentClientsChangedResponse } from '../models/experiment-clients-changed-response';
import { ExperimentCreatedResponse } from '../models/experiment-created-response';
import { experimentEventsAddRecipePost$Json } from '../fn/experiment-events/experiment-events-add-recipe-post-json';
import { ExperimentEventsAddRecipePost$Json$Params } from '../fn/experiment-events/experiment-events-add-recipe-post-json';
import { experimentEventsAddRecipePost$Plain } from '../fn/experiment-events/experiment-events-add-recipe-post-plain';
import { ExperimentEventsAddRecipePost$Plain$Params } from '../fn/experiment-events/experiment-events-add-recipe-post-plain';
import { experimentEventsAddTemplatePost$Json } from '../fn/experiment-events/experiment-events-add-template-post-json';
import { ExperimentEventsAddTemplatePost$Json$Params } from '../fn/experiment-events/experiment-events-add-template-post-json';
import { experimentEventsAddTemplatePost$Plain } from '../fn/experiment-events/experiment-events-add-template-post-plain';
import { ExperimentEventsAddTemplatePost$Plain$Params } from '../fn/experiment-events/experiment-events-add-template-post-plain';
import { experimentEventsChangeAssignedAnalystsPost$Json } from '../fn/experiment-events/experiment-events-change-assigned-analysts-post-json';
import { ExperimentEventsChangeAssignedAnalystsPost$Json$Params } from '../fn/experiment-events/experiment-events-change-assigned-analysts-post-json';
import { experimentEventsChangeAssignedAnalystsPost$Plain } from '../fn/experiment-events/experiment-events-change-assigned-analysts-post-plain';
import { ExperimentEventsChangeAssignedAnalystsPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-assigned-analysts-post-plain';
import { experimentEventsChangeAssignedReviewersPost$Json } from '../fn/experiment-events/experiment-events-change-assigned-reviewers-post-json';
import { ExperimentEventsChangeAssignedReviewersPost$Json$Params } from '../fn/experiment-events/experiment-events-change-assigned-reviewers-post-json';
import { experimentEventsChangeAssignedReviewersPost$Plain } from '../fn/experiment-events/experiment-events-change-assigned-reviewers-post-plain';
import { ExperimentEventsChangeAssignedReviewersPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-assigned-reviewers-post-plain';
import { experimentEventsChangeAssignedSupervisorsPost$Json } from '../fn/experiment-events/experiment-events-change-assigned-supervisors-post-json';
import { ExperimentEventsChangeAssignedSupervisorsPost$Json$Params } from '../fn/experiment-events/experiment-events-change-assigned-supervisors-post-json';
import { experimentEventsChangeAssignedSupervisorsPost$Plain } from '../fn/experiment-events/experiment-events-change-assigned-supervisors-post-plain';
import { ExperimentEventsChangeAssignedSupervisorsPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-assigned-supervisors-post-plain';
import { experimentEventsChangeAuthorizationDueDatePost$Json } from '../fn/experiment-events/experiment-events-change-authorization-due-date-post-json';
import { ExperimentEventsChangeAuthorizationDueDatePost$Json$Params } from '../fn/experiment-events/experiment-events-change-authorization-due-date-post-json';
import { experimentEventsChangeAuthorizationDueDatePost$Plain } from '../fn/experiment-events/experiment-events-change-authorization-due-date-post-plain';
import { ExperimentEventsChangeAuthorizationDueDatePost$Plain$Params } from '../fn/experiment-events/experiment-events-change-authorization-due-date-post-plain';
import { experimentEventsChangeClientsPost$Json } from '../fn/experiment-events/experiment-events-change-clients-post-json';
import { ExperimentEventsChangeClientsPost$Json$Params } from '../fn/experiment-events/experiment-events-change-clients-post-json';
import { experimentEventsChangeClientsPost$Plain } from '../fn/experiment-events/experiment-events-change-clients-post-plain';
import { ExperimentEventsChangeClientsPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-clients-post-plain';
import { experimentEventsChangeNodeOrderPost$Json } from '../fn/experiment-events/experiment-events-change-node-order-post-json';
import { ExperimentEventsChangeNodeOrderPost$Json$Params } from '../fn/experiment-events/experiment-events-change-node-order-post-json';
import { experimentEventsChangeNodeOrderPost$Plain } from '../fn/experiment-events/experiment-events-change-node-order-post-plain';
import { ExperimentEventsChangeNodeOrderPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-node-order-post-plain';
import { experimentEventsChangeNodeTitlePost$Json } from '../fn/experiment-events/experiment-events-change-node-title-post-json';
import { ExperimentEventsChangeNodeTitlePost$Json$Params } from '../fn/experiment-events/experiment-events-change-node-title-post-json';
import { experimentEventsChangeNodeTitlePost$Plain } from '../fn/experiment-events/experiment-events-change-node-title-post-plain';
import { ExperimentEventsChangeNodeTitlePost$Plain$Params } from '../fn/experiment-events/experiment-events-change-node-title-post-plain';
import { experimentEventsChangeProjectsPost$Json } from '../fn/experiment-events/experiment-events-change-projects-post-json';
import { ExperimentEventsChangeProjectsPost$Json$Params } from '../fn/experiment-events/experiment-events-change-projects-post-json';
import { experimentEventsChangeProjectsPost$Plain } from '../fn/experiment-events/experiment-events-change-projects-post-plain';
import { ExperimentEventsChangeProjectsPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-projects-post-plain';
import { experimentEventsChangeScheduledReviewStartDatePost$Json } from '../fn/experiment-events/experiment-events-change-scheduled-review-start-date-post-json';
import { ExperimentEventsChangeScheduledReviewStartDatePost$Json$Params } from '../fn/experiment-events/experiment-events-change-scheduled-review-start-date-post-json';
import { experimentEventsChangeScheduledReviewStartDatePost$Plain } from '../fn/experiment-events/experiment-events-change-scheduled-review-start-date-post-plain';
import { ExperimentEventsChangeScheduledReviewStartDatePost$Plain$Params } from '../fn/experiment-events/experiment-events-change-scheduled-review-start-date-post-plain';
import { experimentEventsChangeScheduledStartDatePost$Json } from '../fn/experiment-events/experiment-events-change-scheduled-start-date-post-json';
import { ExperimentEventsChangeScheduledStartDatePost$Json$Params } from '../fn/experiment-events/experiment-events-change-scheduled-start-date-post-json';
import { experimentEventsChangeScheduledStartDatePost$Plain } from '../fn/experiment-events/experiment-events-change-scheduled-start-date-post-plain';
import { ExperimentEventsChangeScheduledStartDatePost$Plain$Params } from '../fn/experiment-events/experiment-events-change-scheduled-start-date-post-plain';
import { experimentEventsChangeSubBusinessUnitsPost$Json } from '../fn/experiment-events/experiment-events-change-sub-business-units-post-json';
import { ExperimentEventsChangeSubBusinessUnitsPost$Json$Params } from '../fn/experiment-events/experiment-events-change-sub-business-units-post-json';
import { experimentEventsChangeSubBusinessUnitsPost$Plain } from '../fn/experiment-events/experiment-events-change-sub-business-units-post-plain';
import { ExperimentEventsChangeSubBusinessUnitsPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-sub-business-units-post-plain';
import { experimentEventsChangeTagsPost$Json } from '../fn/experiment-events/experiment-events-change-tags-post-json';
import { ExperimentEventsChangeTagsPost$Json$Params } from '../fn/experiment-events/experiment-events-change-tags-post-json';
import { experimentEventsChangeTagsPost$Plain } from '../fn/experiment-events/experiment-events-change-tags-post-plain';
import { ExperimentEventsChangeTagsPost$Plain$Params } from '../fn/experiment-events/experiment-events-change-tags-post-plain';
import { experimentEventsChangeTitlePost$Json } from '../fn/experiment-events/experiment-events-change-title-post-json';
import { ExperimentEventsChangeTitlePost$Json$Params } from '../fn/experiment-events/experiment-events-change-title-post-json';
import { experimentEventsChangeTitlePost$Plain } from '../fn/experiment-events/experiment-events-change-title-post-plain';
import { ExperimentEventsChangeTitlePost$Plain$Params } from '../fn/experiment-events/experiment-events-change-title-post-plain';
import { experimentEventsCreateExperimentPost$Json } from '../fn/experiment-events/experiment-events-create-experiment-post-json';
import { ExperimentEventsCreateExperimentPost$Json$Params } from '../fn/experiment-events/experiment-events-create-experiment-post-json';
import { experimentEventsCreateExperimentPost$Plain } from '../fn/experiment-events/experiment-events-create-experiment-post-plain';
import { ExperimentEventsCreateExperimentPost$Plain$Params } from '../fn/experiment-events/experiment-events-create-experiment-post-plain';
import { experimentEventsExperimentIdAuthorizePost$Json } from '../fn/experiment-events/experiment-events-experiment-id-authorize-post-json';
import { ExperimentEventsExperimentIdAuthorizePost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-authorize-post-json';
import { experimentEventsExperimentIdAuthorizePost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-authorize-post-plain';
import { ExperimentEventsExperimentIdAuthorizePost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-authorize-post-plain';
import { experimentEventsExperimentIdCancelPost$Json } from '../fn/experiment-events/experiment-events-experiment-id-cancel-post-json';
import { ExperimentEventsExperimentIdCancelPost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-cancel-post-json';
import { experimentEventsExperimentIdCancelPost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-cancel-post-plain';
import { ExperimentEventsExperimentIdCancelPost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-cancel-post-plain';
import { experimentEventsExperimentIdClosePost } from '../fn/experiment-events/experiment-events-experiment-id-close-post';
import { ExperimentEventsExperimentIdClosePost$Params } from '../fn/experiment-events/experiment-events-experiment-id-close-post';
import { experimentEventsExperimentIdRestorePost$Json } from '../fn/experiment-events/experiment-events-experiment-id-restore-post-json';
import { ExperimentEventsExperimentIdRestorePost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-restore-post-json';
import { experimentEventsExperimentIdRestorePost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-restore-post-plain';
import { ExperimentEventsExperimentIdRestorePost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-restore-post-plain';
import { experimentEventsExperimentIdSendForCorrectionPost$Json } from '../fn/experiment-events/experiment-events-experiment-id-send-for-correction-post-json';
import { ExperimentEventsExperimentIdSendForCorrectionPost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-send-for-correction-post-json';
import { experimentEventsExperimentIdSendForCorrectionPost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-send-for-correction-post-plain';
import { ExperimentEventsExperimentIdSendForCorrectionPost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-send-for-correction-post-plain';
import { experimentEventsExperimentIdSendForReviewPost$Json } from '../fn/experiment-events/experiment-events-experiment-id-send-for-review-post-json';
import { ExperimentEventsExperimentIdSendForReviewPost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-send-for-review-post-json';
import { experimentEventsExperimentIdSendForReviewPost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-send-for-review-post-plain';
import { ExperimentEventsExperimentIdSendForReviewPost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-send-for-review-post-plain';
import { experimentEventsExperimentIdSetVariablePost$Json } from '../fn/experiment-events/experiment-events-experiment-id-set-variable-post-json';
import { ExperimentEventsExperimentIdSetVariablePost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-set-variable-post-json';
import { experimentEventsExperimentIdSetVariablePost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-set-variable-post-plain';
import { ExperimentEventsExperimentIdSetVariablePost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-set-variable-post-plain';
import { experimentEventsExperimentIdStartPost$Json } from '../fn/experiment-events/experiment-events-experiment-id-start-post-json';
import { ExperimentEventsExperimentIdStartPost$Json$Params } from '../fn/experiment-events/experiment-events-experiment-id-start-post-json';
import { experimentEventsExperimentIdStartPost$Plain } from '../fn/experiment-events/experiment-events-experiment-id-start-post-plain';
import { ExperimentEventsExperimentIdStartPost$Plain$Params } from '../fn/experiment-events/experiment-events-experiment-id-start-post-plain';
import { experimentEventsMarkDataPackageGeneratedPost } from '../fn/experiment-events/experiment-events-mark-data-package-generated-post';
import { ExperimentEventsMarkDataPackageGeneratedPost$Params } from '../fn/experiment-events/experiment-events-mark-data-package-generated-post';
import { ExperimentNodeOrderChangedNotification } from '../models/experiment-node-order-changed-notification';
import { ExperimentNodeTitleChangedNotification } from '../models/experiment-node-title-changed-notification';
import { ExperimentProjectsChangedResponse } from '../models/experiment-projects-changed-response';
import { ExperimentRecipeAppliedResponse } from '../models/experiment-recipe-applied-response';
import { ExperimentRestoredResponse } from '../models/experiment-restored-response';
import { ExperimentScheduledReviewStartDateChangedResponse } from '../models/experiment-scheduled-review-start-date-changed-response';
import { ExperimentScheduledStartDateChangedResponse } from '../models/experiment-scheduled-start-date-changed-response';
import { ExperimentSentForCorrectionResponse } from '../models/experiment-sent-for-correction-response';
import { ExperimentSentForReviewResponse } from '../models/experiment-sent-for-review-response';
import { ExperimentStartedResponse } from '../models/experiment-started-response';
import { ExperimentSubBusinessUnitsChangedResponse } from '../models/experiment-sub-business-units-changed-response';
import { ExperimentTagsChangedResponse } from '../models/experiment-tags-changed-response';
import { ExperimentTemplateAppliedResponse } from '../models/experiment-template-applied-response';
import { ExperimentTitleChangedResponse } from '../models/experiment-title-changed-response';
import { SetVariableResponse } from '../models/set-variable-response';

@Injectable({ providedIn: 'root' })
export class ExperimentEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `experimentEventsCreateExperimentPost()` */
  static readonly ExperimentEventsCreateExperimentPostPath = '/experiment-events/create-experiment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsCreateExperimentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Plain$Response(params?: ExperimentEventsCreateExperimentPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentCreatedResponse>> {
    return experimentEventsCreateExperimentPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsCreateExperimentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Plain(params?: ExperimentEventsCreateExperimentPost$Plain$Params, context?: HttpContext): Observable<ExperimentCreatedResponse> {
    return this.experimentEventsCreateExperimentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentCreatedResponse>): ExperimentCreatedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsCreateExperimentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Json$Response(params?: ExperimentEventsCreateExperimentPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentCreatedResponse>> {
    return experimentEventsCreateExperimentPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsCreateExperimentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsCreateExperimentPost$Json(params?: ExperimentEventsCreateExperimentPost$Json$Params, context?: HttpContext): Observable<ExperimentCreatedResponse> {
    return this.experimentEventsCreateExperimentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentCreatedResponse>): ExperimentCreatedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdClosePost()` */
  static readonly ExperimentEventsExperimentIdClosePostPath = '/experiment-events/{experimentId}/close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdClosePost()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdClosePost$Response(params: ExperimentEventsExperimentIdClosePost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return experimentEventsExperimentIdClosePost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdClosePost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdClosePost(params: ExperimentEventsExperimentIdClosePost$Params, context?: HttpContext): Observable<void> {
    return this.experimentEventsExperimentIdClosePost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdStartPost()` */
  static readonly ExperimentEventsExperimentIdStartPostPath = '/experiment-events/{experimentId}/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdStartPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Plain$Response(params: ExperimentEventsExperimentIdStartPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentStartedResponse>> {
    return experimentEventsExperimentIdStartPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdStartPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Plain(params: ExperimentEventsExperimentIdStartPost$Plain$Params, context?: HttpContext): Observable<ExperimentStartedResponse> {
    return this.experimentEventsExperimentIdStartPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentStartedResponse>): ExperimentStartedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdStartPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Json$Response(params: ExperimentEventsExperimentIdStartPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentStartedResponse>> {
    return experimentEventsExperimentIdStartPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdStartPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdStartPost$Json(params: ExperimentEventsExperimentIdStartPost$Json$Params, context?: HttpContext): Observable<ExperimentStartedResponse> {
    return this.experimentEventsExperimentIdStartPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentStartedResponse>): ExperimentStartedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdSendForReviewPost()` */
  static readonly ExperimentEventsExperimentIdSendForReviewPostPath = '/experiment-events/{experimentId}/send-for-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForReviewPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Plain$Response(params: ExperimentEventsExperimentIdSendForReviewPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSentForReviewResponse>> {
    return experimentEventsExperimentIdSendForReviewPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForReviewPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Plain(params: ExperimentEventsExperimentIdSendForReviewPost$Plain$Params, context?: HttpContext): Observable<ExperimentSentForReviewResponse> {
    return this.experimentEventsExperimentIdSendForReviewPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentSentForReviewResponse>): ExperimentSentForReviewResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForReviewPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Json$Response(params: ExperimentEventsExperimentIdSendForReviewPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSentForReviewResponse>> {
    return experimentEventsExperimentIdSendForReviewPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForReviewPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForReviewPost$Json(params: ExperimentEventsExperimentIdSendForReviewPost$Json$Params, context?: HttpContext): Observable<ExperimentSentForReviewResponse> {
    return this.experimentEventsExperimentIdSendForReviewPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentSentForReviewResponse>): ExperimentSentForReviewResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdSetVariablePost()` */
  static readonly ExperimentEventsExperimentIdSetVariablePostPath = '/experiment-events/{experimentId}/set-variable';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSetVariablePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Plain$Response(params: ExperimentEventsExperimentIdSetVariablePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SetVariableResponse>> {
    return experimentEventsExperimentIdSetVariablePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSetVariablePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Plain(params: ExperimentEventsExperimentIdSetVariablePost$Plain$Params, context?: HttpContext): Observable<SetVariableResponse> {
    return this.experimentEventsExperimentIdSetVariablePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>): SetVariableResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSetVariablePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Json$Response(params: ExperimentEventsExperimentIdSetVariablePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SetVariableResponse>> {
    return experimentEventsExperimentIdSetVariablePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSetVariablePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsExperimentIdSetVariablePost$Json(params: ExperimentEventsExperimentIdSetVariablePost$Json$Params, context?: HttpContext): Observable<SetVariableResponse> {
    return this.experimentEventsExperimentIdSetVariablePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SetVariableResponse>): SetVariableResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdSendForCorrectionPost()` */
  static readonly ExperimentEventsExperimentIdSendForCorrectionPostPath = '/experiment-events/{experimentId}/send-for-correction';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForCorrectionPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Plain$Response(params: ExperimentEventsExperimentIdSendForCorrectionPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSentForCorrectionResponse>> {
    return experimentEventsExperimentIdSendForCorrectionPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForCorrectionPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Plain(params: ExperimentEventsExperimentIdSendForCorrectionPost$Plain$Params, context?: HttpContext): Observable<ExperimentSentForCorrectionResponse> {
    return this.experimentEventsExperimentIdSendForCorrectionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentSentForCorrectionResponse>): ExperimentSentForCorrectionResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdSendForCorrectionPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Json$Response(params: ExperimentEventsExperimentIdSendForCorrectionPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSentForCorrectionResponse>> {
    return experimentEventsExperimentIdSendForCorrectionPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdSendForCorrectionPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdSendForCorrectionPost$Json(params: ExperimentEventsExperimentIdSendForCorrectionPost$Json$Params, context?: HttpContext): Observable<ExperimentSentForCorrectionResponse> {
    return this.experimentEventsExperimentIdSendForCorrectionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentSentForCorrectionResponse>): ExperimentSentForCorrectionResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdCancelPost()` */
  static readonly ExperimentEventsExperimentIdCancelPostPath = '/experiment-events/{experimentId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdCancelPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Plain$Response(params: ExperimentEventsExperimentIdCancelPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentCancelledResponse>> {
    return experimentEventsExperimentIdCancelPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdCancelPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Plain(params: ExperimentEventsExperimentIdCancelPost$Plain$Params, context?: HttpContext): Observable<ExperimentCancelledResponse> {
    return this.experimentEventsExperimentIdCancelPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentCancelledResponse>): ExperimentCancelledResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdCancelPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Json$Response(params: ExperimentEventsExperimentIdCancelPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentCancelledResponse>> {
    return experimentEventsExperimentIdCancelPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdCancelPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdCancelPost$Json(params: ExperimentEventsExperimentIdCancelPost$Json$Params, context?: HttpContext): Observable<ExperimentCancelledResponse> {
    return this.experimentEventsExperimentIdCancelPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentCancelledResponse>): ExperimentCancelledResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdRestorePost()` */
  static readonly ExperimentEventsExperimentIdRestorePostPath = '/experiment-events/{experimentId}/restore';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdRestorePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Plain$Response(params: ExperimentEventsExperimentIdRestorePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRestoredResponse>> {
    return experimentEventsExperimentIdRestorePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdRestorePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Plain(params: ExperimentEventsExperimentIdRestorePost$Plain$Params, context?: HttpContext): Observable<ExperimentRestoredResponse> {
    return this.experimentEventsExperimentIdRestorePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRestoredResponse>): ExperimentRestoredResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdRestorePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Json$Response(params: ExperimentEventsExperimentIdRestorePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRestoredResponse>> {
    return experimentEventsExperimentIdRestorePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdRestorePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdRestorePost$Json(params: ExperimentEventsExperimentIdRestorePost$Json$Params, context?: HttpContext): Observable<ExperimentRestoredResponse> {
    return this.experimentEventsExperimentIdRestorePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRestoredResponse>): ExperimentRestoredResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsExperimentIdAuthorizePost()` */
  static readonly ExperimentEventsExperimentIdAuthorizePostPath = '/experiment-events/{experimentId}/authorize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdAuthorizePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Plain$Response(params: ExperimentEventsExperimentIdAuthorizePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAuthorizedResponse>> {
    return experimentEventsExperimentIdAuthorizePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdAuthorizePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Plain(params: ExperimentEventsExperimentIdAuthorizePost$Plain$Params, context?: HttpContext): Observable<ExperimentAuthorizedResponse> {
    return this.experimentEventsExperimentIdAuthorizePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizedResponse>): ExperimentAuthorizedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsExperimentIdAuthorizePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Json$Response(params: ExperimentEventsExperimentIdAuthorizePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAuthorizedResponse>> {
    return experimentEventsExperimentIdAuthorizePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsExperimentIdAuthorizePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  experimentEventsExperimentIdAuthorizePost$Json(params: ExperimentEventsExperimentIdAuthorizePost$Json$Params, context?: HttpContext): Observable<ExperimentAuthorizedResponse> {
    return this.experimentEventsExperimentIdAuthorizePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizedResponse>): ExperimentAuthorizedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeTitlePost()` */
  static readonly ExperimentEventsChangeTitlePostPath = '/experiment-events/change-title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Plain$Response(params?: ExperimentEventsChangeTitlePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentTitleChangedResponse>> {
    return experimentEventsChangeTitlePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Plain(params?: ExperimentEventsChangeTitlePost$Plain$Params, context?: HttpContext): Observable<ExperimentTitleChangedResponse> {
    return this.experimentEventsChangeTitlePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentTitleChangedResponse>): ExperimentTitleChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Json$Response(params?: ExperimentEventsChangeTitlePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentTitleChangedResponse>> {
    return experimentEventsChangeTitlePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTitlePost$Json(params?: ExperimentEventsChangeTitlePost$Json$Params, context?: HttpContext): Observable<ExperimentTitleChangedResponse> {
    return this.experimentEventsChangeTitlePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentTitleChangedResponse>): ExperimentTitleChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeTagsPost()` */
  static readonly ExperimentEventsChangeTagsPostPath = '/experiment-events/change-tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTagsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Plain$Response(params?: ExperimentEventsChangeTagsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentTagsChangedResponse>> {
    return experimentEventsChangeTagsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTagsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Plain(params?: ExperimentEventsChangeTagsPost$Plain$Params, context?: HttpContext): Observable<ExperimentTagsChangedResponse> {
    return this.experimentEventsChangeTagsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentTagsChangedResponse>): ExperimentTagsChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeTagsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Json$Response(params?: ExperimentEventsChangeTagsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentTagsChangedResponse>> {
    return experimentEventsChangeTagsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeTagsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeTagsPost$Json(params?: ExperimentEventsChangeTagsPost$Json$Params, context?: HttpContext): Observable<ExperimentTagsChangedResponse> {
    return this.experimentEventsChangeTagsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentTagsChangedResponse>): ExperimentTagsChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeSubBusinessUnitsPost()` */
  static readonly ExperimentEventsChangeSubBusinessUnitsPostPath = '/experiment-events/change-sub-business-units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeSubBusinessUnitsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Plain$Response(params?: ExperimentEventsChangeSubBusinessUnitsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>> {
    return experimentEventsChangeSubBusinessUnitsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeSubBusinessUnitsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Plain(params?: ExperimentEventsChangeSubBusinessUnitsPost$Plain$Params, context?: HttpContext): Observable<ExperimentSubBusinessUnitsChangedResponse> {
    return this.experimentEventsChangeSubBusinessUnitsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>): ExperimentSubBusinessUnitsChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeSubBusinessUnitsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Json$Response(params?: ExperimentEventsChangeSubBusinessUnitsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>> {
    return experimentEventsChangeSubBusinessUnitsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeSubBusinessUnitsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeSubBusinessUnitsPost$Json(params?: ExperimentEventsChangeSubBusinessUnitsPost$Json$Params, context?: HttpContext): Observable<ExperimentSubBusinessUnitsChangedResponse> {
    return this.experimentEventsChangeSubBusinessUnitsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentSubBusinessUnitsChangedResponse>): ExperimentSubBusinessUnitsChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeAssignedSupervisorsPost()` */
  static readonly ExperimentEventsChangeAssignedSupervisorsPostPath = '/experiment-events/change-assigned-supervisors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedSupervisorsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Plain$Response(params?: ExperimentEventsChangeAssignedSupervisorsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>> {
    return experimentEventsChangeAssignedSupervisorsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedSupervisorsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Plain(params?: ExperimentEventsChangeAssignedSupervisorsPost$Plain$Params, context?: HttpContext): Observable<ExperimentAssignedSupervisorsChangedResponse> {
    return this.experimentEventsChangeAssignedSupervisorsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>): ExperimentAssignedSupervisorsChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedSupervisorsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Json$Response(params?: ExperimentEventsChangeAssignedSupervisorsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>> {
    return experimentEventsChangeAssignedSupervisorsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedSupervisorsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedSupervisorsPost$Json(params?: ExperimentEventsChangeAssignedSupervisorsPost$Json$Params, context?: HttpContext): Observable<ExperimentAssignedSupervisorsChangedResponse> {
    return this.experimentEventsChangeAssignedSupervisorsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedSupervisorsChangedResponse>): ExperimentAssignedSupervisorsChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeAssignedReviewersPost()` */
  static readonly ExperimentEventsChangeAssignedReviewersPostPath = '/experiment-events/change-assigned-reviewers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedReviewersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Plain$Response(params?: ExperimentEventsChangeAssignedReviewersPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>> {
    return experimentEventsChangeAssignedReviewersPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedReviewersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Plain(params?: ExperimentEventsChangeAssignedReviewersPost$Plain$Params, context?: HttpContext): Observable<ExperimentAssignedReviewersChangedResponse> {
    return this.experimentEventsChangeAssignedReviewersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>): ExperimentAssignedReviewersChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedReviewersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Json$Response(params?: ExperimentEventsChangeAssignedReviewersPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>> {
    return experimentEventsChangeAssignedReviewersPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedReviewersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedReviewersPost$Json(params?: ExperimentEventsChangeAssignedReviewersPost$Json$Params, context?: HttpContext): Observable<ExperimentAssignedReviewersChangedResponse> {
    return this.experimentEventsChangeAssignedReviewersPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedReviewersChangedResponse>): ExperimentAssignedReviewersChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeAssignedAnalystsPost()` */
  static readonly ExperimentEventsChangeAssignedAnalystsPostPath = '/experiment-events/change-assigned-analysts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedAnalystsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Plain$Response(params?: ExperimentEventsChangeAssignedAnalystsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>> {
    return experimentEventsChangeAssignedAnalystsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedAnalystsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Plain(params?: ExperimentEventsChangeAssignedAnalystsPost$Plain$Params, context?: HttpContext): Observable<ExperimentAssignedAnalystsChangedResponse> {
    return this.experimentEventsChangeAssignedAnalystsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>): ExperimentAssignedAnalystsChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAssignedAnalystsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Json$Response(params?: ExperimentEventsChangeAssignedAnalystsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>> {
    return experimentEventsChangeAssignedAnalystsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAssignedAnalystsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAssignedAnalystsPost$Json(params?: ExperimentEventsChangeAssignedAnalystsPost$Json$Params, context?: HttpContext): Observable<ExperimentAssignedAnalystsChangedResponse> {
    return this.experimentEventsChangeAssignedAnalystsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAssignedAnalystsChangedResponse>): ExperimentAssignedAnalystsChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeScheduledStartDatePost()` */
  static readonly ExperimentEventsChangeScheduledStartDatePostPath = '/experiment-events/change-scheduled-start-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledStartDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Plain$Response(params?: ExperimentEventsChangeScheduledStartDatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>> {
    return experimentEventsChangeScheduledStartDatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledStartDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Plain(params?: ExperimentEventsChangeScheduledStartDatePost$Plain$Params, context?: HttpContext): Observable<ExperimentScheduledStartDateChangedResponse> {
    return this.experimentEventsChangeScheduledStartDatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>): ExperimentScheduledStartDateChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledStartDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Json$Response(params?: ExperimentEventsChangeScheduledStartDatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>> {
    return experimentEventsChangeScheduledStartDatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledStartDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledStartDatePost$Json(params?: ExperimentEventsChangeScheduledStartDatePost$Json$Params, context?: HttpContext): Observable<ExperimentScheduledStartDateChangedResponse> {
    return this.experimentEventsChangeScheduledStartDatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledStartDateChangedResponse>): ExperimentScheduledStartDateChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeScheduledReviewStartDatePost()` */
  static readonly ExperimentEventsChangeScheduledReviewStartDatePostPath = '/experiment-events/change-scheduled-review-start-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledReviewStartDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Plain$Response(params?: ExperimentEventsChangeScheduledReviewStartDatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>> {
    return experimentEventsChangeScheduledReviewStartDatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledReviewStartDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Plain(params?: ExperimentEventsChangeScheduledReviewStartDatePost$Plain$Params, context?: HttpContext): Observable<ExperimentScheduledReviewStartDateChangedResponse> {
    return this.experimentEventsChangeScheduledReviewStartDatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>): ExperimentScheduledReviewStartDateChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeScheduledReviewStartDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Json$Response(params?: ExperimentEventsChangeScheduledReviewStartDatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>> {
    return experimentEventsChangeScheduledReviewStartDatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeScheduledReviewStartDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeScheduledReviewStartDatePost$Json(params?: ExperimentEventsChangeScheduledReviewStartDatePost$Json$Params, context?: HttpContext): Observable<ExperimentScheduledReviewStartDateChangedResponse> {
    return this.experimentEventsChangeScheduledReviewStartDatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentScheduledReviewStartDateChangedResponse>): ExperimentScheduledReviewStartDateChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeAuthorizationDueDatePost()` */
  static readonly ExperimentEventsChangeAuthorizationDueDatePostPath = '/experiment-events/change-authorization-due-date';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAuthorizationDueDatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Plain$Response(params?: ExperimentEventsChangeAuthorizationDueDatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>> {
    return experimentEventsChangeAuthorizationDueDatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAuthorizationDueDatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Plain(params?: ExperimentEventsChangeAuthorizationDueDatePost$Plain$Params, context?: HttpContext): Observable<ExperimentAuthorizationDueDateChangedResponse> {
    return this.experimentEventsChangeAuthorizationDueDatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>): ExperimentAuthorizationDueDateChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeAuthorizationDueDatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Json$Response(params?: ExperimentEventsChangeAuthorizationDueDatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>> {
    return experimentEventsChangeAuthorizationDueDatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeAuthorizationDueDatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeAuthorizationDueDatePost$Json(params?: ExperimentEventsChangeAuthorizationDueDatePost$Json$Params, context?: HttpContext): Observable<ExperimentAuthorizationDueDateChangedResponse> {
    return this.experimentEventsChangeAuthorizationDueDatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentAuthorizationDueDateChangedResponse>): ExperimentAuthorizationDueDateChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsAddTemplatePost()` */
  static readonly ExperimentEventsAddTemplatePostPath = '/experiment-events/add-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsAddTemplatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Plain$Response(params?: ExperimentEventsAddTemplatePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentTemplateAppliedResponse>> {
    return experimentEventsAddTemplatePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsAddTemplatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Plain(params?: ExperimentEventsAddTemplatePost$Plain$Params, context?: HttpContext): Observable<ExperimentTemplateAppliedResponse> {
    return this.experimentEventsAddTemplatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentTemplateAppliedResponse>): ExperimentTemplateAppliedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsAddTemplatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Json$Response(params?: ExperimentEventsAddTemplatePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentTemplateAppliedResponse>> {
    return experimentEventsAddTemplatePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsAddTemplatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddTemplatePost$Json(params?: ExperimentEventsAddTemplatePost$Json$Params, context?: HttpContext): Observable<ExperimentTemplateAppliedResponse> {
    return this.experimentEventsAddTemplatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentTemplateAppliedResponse>): ExperimentTemplateAppliedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeNodeTitlePost()` */
  static readonly ExperimentEventsChangeNodeTitlePostPath = '/experiment-events/change-node-title';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeTitlePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Plain$Response(params?: ExperimentEventsChangeNodeTitlePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentNodeTitleChangedNotification>> {
    return experimentEventsChangeNodeTitlePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeTitlePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Plain(params?: ExperimentEventsChangeNodeTitlePost$Plain$Params, context?: HttpContext): Observable<ExperimentNodeTitleChangedNotification> {
    return this.experimentEventsChangeNodeTitlePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentNodeTitleChangedNotification>): ExperimentNodeTitleChangedNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeTitlePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Json$Response(params?: ExperimentEventsChangeNodeTitlePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentNodeTitleChangedNotification>> {
    return experimentEventsChangeNodeTitlePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeTitlePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeTitlePost$Json(params?: ExperimentEventsChangeNodeTitlePost$Json$Params, context?: HttpContext): Observable<ExperimentNodeTitleChangedNotification> {
    return this.experimentEventsChangeNodeTitlePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentNodeTitleChangedNotification>): ExperimentNodeTitleChangedNotification => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeNodeOrderPost()` */
  static readonly ExperimentEventsChangeNodeOrderPostPath = '/experiment-events/change-node-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Plain$Response(params?: ExperimentEventsChangeNodeOrderPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentNodeOrderChangedNotification>> {
    return experimentEventsChangeNodeOrderPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Plain(params?: ExperimentEventsChangeNodeOrderPost$Plain$Params, context?: HttpContext): Observable<ExperimentNodeOrderChangedNotification> {
    return this.experimentEventsChangeNodeOrderPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentNodeOrderChangedNotification>): ExperimentNodeOrderChangedNotification => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeNodeOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Json$Response(params?: ExperimentEventsChangeNodeOrderPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentNodeOrderChangedNotification>> {
    return experimentEventsChangeNodeOrderPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeNodeOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeNodeOrderPost$Json(params?: ExperimentEventsChangeNodeOrderPost$Json$Params, context?: HttpContext): Observable<ExperimentNodeOrderChangedNotification> {
    return this.experimentEventsChangeNodeOrderPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentNodeOrderChangedNotification>): ExperimentNodeOrderChangedNotification => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeClientsPost()` */
  static readonly ExperimentEventsChangeClientsPostPath = '/experiment-events/change-clients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeClientsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeClientsPost$Plain$Response(params?: ExperimentEventsChangeClientsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentClientsChangedResponse>> {
    return experimentEventsChangeClientsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeClientsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeClientsPost$Plain(params?: ExperimentEventsChangeClientsPost$Plain$Params, context?: HttpContext): Observable<ExperimentClientsChangedResponse> {
    return this.experimentEventsChangeClientsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentClientsChangedResponse>): ExperimentClientsChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeClientsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeClientsPost$Json$Response(params?: ExperimentEventsChangeClientsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentClientsChangedResponse>> {
    return experimentEventsChangeClientsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeClientsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeClientsPost$Json(params?: ExperimentEventsChangeClientsPost$Json$Params, context?: HttpContext): Observable<ExperimentClientsChangedResponse> {
    return this.experimentEventsChangeClientsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentClientsChangedResponse>): ExperimentClientsChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsChangeProjectsPost()` */
  static readonly ExperimentEventsChangeProjectsPostPath = '/experiment-events/change-projects';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeProjectsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeProjectsPost$Plain$Response(params?: ExperimentEventsChangeProjectsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentProjectsChangedResponse>> {
    return experimentEventsChangeProjectsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeProjectsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeProjectsPost$Plain(params?: ExperimentEventsChangeProjectsPost$Plain$Params, context?: HttpContext): Observable<ExperimentProjectsChangedResponse> {
    return this.experimentEventsChangeProjectsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentProjectsChangedResponse>): ExperimentProjectsChangedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsChangeProjectsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeProjectsPost$Json$Response(params?: ExperimentEventsChangeProjectsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentProjectsChangedResponse>> {
    return experimentEventsChangeProjectsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsChangeProjectsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsChangeProjectsPost$Json(params?: ExperimentEventsChangeProjectsPost$Json$Params, context?: HttpContext): Observable<ExperimentProjectsChangedResponse> {
    return this.experimentEventsChangeProjectsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentProjectsChangedResponse>): ExperimentProjectsChangedResponse => r.body)
    );
  }

  /** Path part for operation `experimentEventsMarkDataPackageGeneratedPost()` */
  static readonly ExperimentEventsMarkDataPackageGeneratedPostPath = '/experiment-events/mark-data-package-generated';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsMarkDataPackageGeneratedPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsMarkDataPackageGeneratedPost$Response(params?: ExperimentEventsMarkDataPackageGeneratedPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return experimentEventsMarkDataPackageGeneratedPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsMarkDataPackageGeneratedPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsMarkDataPackageGeneratedPost(params?: ExperimentEventsMarkDataPackageGeneratedPost$Params, context?: HttpContext): Observable<void> {
    return this.experimentEventsMarkDataPackageGeneratedPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `experimentEventsAddRecipePost()` */
  static readonly ExperimentEventsAddRecipePostPath = '/experiment-events/add-recipe';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsAddRecipePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddRecipePost$Plain$Response(params?: ExperimentEventsAddRecipePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRecipeAppliedResponse>> {
    return experimentEventsAddRecipePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsAddRecipePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddRecipePost$Plain(params?: ExperimentEventsAddRecipePost$Plain$Params, context?: HttpContext): Observable<ExperimentRecipeAppliedResponse> {
    return this.experimentEventsAddRecipePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRecipeAppliedResponse>): ExperimentRecipeAppliedResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `experimentEventsAddRecipePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddRecipePost$Json$Response(params?: ExperimentEventsAddRecipePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ExperimentRecipeAppliedResponse>> {
    return experimentEventsAddRecipePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `experimentEventsAddRecipePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  experimentEventsAddRecipePost$Json(params?: ExperimentEventsAddRecipePost$Json$Params, context?: HttpContext): Observable<ExperimentRecipeAppliedResponse> {
    return this.experimentEventsAddRecipePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExperimentRecipeAppliedResponse>): ExperimentRecipeAppliedResponse => r.body)
    );
  }

}
